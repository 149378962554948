import { Vue, Component, Prop, Emit } from "vue-property-decorator";
import { v4 as uuidv4 } from 'uuid';
import moment from "moment";
import { mapState } from 'vuex';
import lodash from "lodash";

// Components
import { Drag, Drop, DropList } from "vue-easy-dnd";
import SearchSupplier from '@/components/general/search-supplier/searchSupplier.vue';
import SearchPartner from '@/components/general/search-partner/searchPartner.vue';
import enLangData from "@/locales/en.json";

// Services
import { ProductGroupService } from "@/services/productGroupService";
import { ClientStepService } from "@/services/clientStepService";
import { SupplierService } from "@/services/supplierService";
import { OrderService } from "@/services/orderService";

// Helper
import { ProductGroupHelper } from "@/helpers/productGroupHelper";
import { DateTimeHelper } from "@/helpers/dateTimeHelper";
import { StepsHelper } from "@/helpers/stepsHelper";

// Models
import { DropdownModel } from "@/models/dropdownModel";
import { OrderLineModel } from "@/models/orderLineModel";
import { OrderLineStepModel } from "@/models/orderLineStepModel";
import { ProductGroupModel } from "@/models/productGroupModel";
import { ClientStepModel } from "@/models/clientStepModel";
import { Guid } from "@/models/guid";
import { OrderCompanyModel } from "@/models/orderCompanyModel";
import { CombinedOrderLineModel } from '@/models/combinedOrderLineModel';
import { SupplierPartnerModel } from "@/models/supplierPartnerModel";
import { OrderLineStepDisplayModel } from "@/models/orderLineStepDisplayModel";
import { OrderLineDisplayModel } from "@/models/orderLineDisplayModel";
import { StepState } from "@/models/stepState";
import { StepHistory } from "@/models/stepHistoryModal";
import { UserModel } from "@/models/userModel";
import { OrderLineStepParallelModel } from "@/models/orderLineStepParallelModel";
import { GroupStepEvidenceHelper } from "@/helpers/groupStepEvidenceHelper";
import { ActionTypes } from "@/store/actions/actions";
import { CompanyNameModel } from "@/models/companyModel";

@Component({
    components: { Drag, Drop, DropList, SearchSupplier, SearchPartner },
    computed: mapState(['companyType', 'companyNameList']),
})
export default class EditOrderLine extends Vue {

    @Prop()
    private orderLineData!: OrderLineDisplayModel;

    @Prop()
    private editOrderLineSteps!: OrderLineStepDisplayModel[];

    @Prop()
    private selectedTab!: string;

    private companyType!: number;

    private orderService: OrderService;

    private productGroupService: ProductGroupService;

    private supplierService: SupplierService;

    private ClientStepService: ClientStepService;

    private orderLineSteps: OrderLineStepModel[] = [];

    private orderLineStepsClone: OrderLineStepModel[] = [];

    private suppliers: OrderCompanyModel[] = [];

    private suppliersLoading: boolean = false;

    private selectedProductGroup: string;

    private selectedScpId: string = Guid.Empty;

    private partners: SupplierPartnerModel[] = [];

    private selectedproductGroupId: number;

    private selectedTier1Supplier: string = Guid.Empty;

    //TTD-2620, Agent orderlines changes, added condition
    private selectedTier1SupplierName: string = "";

    private selectedStep: number = 0;

    private selectedParallelStep: number = 0;

    private allProductGroups: ProductGroupModel[] = [];

    private deleteDisabled: boolean = true;

    private draggedStep: number = -1;

    private assignNameToOtherNewStep: boolean = false;

    private addingInProgress: boolean = false;

    private allSteps: ClientStepModel[] = [];

    private stepsForProductGroup: ClientStepModel[] = [];

    private allSupplyChainPartners: OrderCompanyModel[] = [];

    private showTier1: boolean = false;

    private showSupplier: boolean = false;

    private showSupplierForParallel: boolean = false;

    private isCreating: boolean = false;

    private isLoading: boolean = false;

    private order: OrderLineModel = new OrderLineModel();

    private stepIndex: number = -1;

    private indexOfChain: number = -1;

    private indexOfChainStep: number = -1;

    private customisedCount: number = 0;

    private showInfo: boolean = false;

    private supplierChanged: boolean = false;

    private stepNames: string[] = [];

    private companyStepHistory: StepHistory[] = [];

    private company: UserModel = new UserModel();

    private parentStepId: number = 0;

    private showMaxParallelChainModal: boolean = false;

    private parallelChain0DropdownSteps: DropdownModel[] = [];

    private parallelChain1DropdownSteps: DropdownModel[] = [];

    private parallelChain2DropdownSteps: DropdownModel[] = [];

    private parallelChain3DropdownSteps: DropdownModel[] = [];

    private parallelChain4DropdownSteps: DropdownModel[] = [];

    private orderConfirming: boolean = false;

    private showRemoveStepModal: boolean = false;

    private stepIdToRemove: number = -1;

    private isDragging: boolean = false;

    private isNewStepAdded: boolean = false;

    private isParallelSupplyChain: boolean = false;

    private paddingLeft: number = 0;

    private isEditDataChanged: boolean = false;

    private showAgent: boolean = false;

    private agentCompanyName: string = "";

    private isShowDeleteAgent: boolean = false;

    private isCustomisedByAgent: boolean = false;

    private isUpdated: boolean = false;

    private selectOrEditTitle: string = "Select agent";

    // TTD-2721
    private isShowAddRefModal: boolean = false;
    private toCompanyId: string = '';
    private chainType: string = '';
    private referenceNumber: string = '';

    // TTD-4208 Sprint-23
    private isMCToCompanyEmpty: boolean = false;

    private isPCToCompanyEmpty: boolean = false;

    // TTD-3961
    private companyNameList!: CompanyNameModel[];

    public constructor() {
        super();
        this.selectedProductGroup = "";
        this.selectedproductGroupId = 0;
        this.order.companyName = "";
        this.order.toCompanyId = "";
        this.order.toCompanyName = "";
        this.orderService = new OrderService();
        this.supplierService = new SupplierService();
        this.ClientStepService = new ClientStepService();
        this.productGroupService = new ProductGroupService();
        this.stepNames = Object.values(enLangData.enums.steps);
    }

    private async created(): Promise<void> {
        //TTD-2620, Agent orderlines changes, removed
        // this.companyType = 5;
        try {
            this.isLoading = true;
            this.company = this.$store.getters.user as UserModel;
            this.orderLineStepsClone = lodash.cloneDeep(this.editOrderLineSteps);
            await this.fetchSuppliers();
            await this.editOrderLineStep();
            await this.getClientSteps();
            if (this.selectedproductGroupId != null && this.selectedproductGroupId !== 0) {
                await this.getClientStepsForProductGroup(this.selectedproductGroupId);
            }
            this.setChainCount();
            document.querySelector('.step-list')?.setAttribute('id', 'step-list-custom-scroll');
        }
        finally {
            this.isLoading = false;
            this.calculateDistance(true);
        }
    }

    private async fetchSuppliers(): Promise<void> {
        if (this.$store.getters.suppliers != null && this.$store.getters.suppliers.length > 0 && !this.supplierChanged) {
            this.suppliers = this.$store.getters.suppliers;
        }
        else {
            // const suppliers = await this.supplierService.getSuppliersAsync();
            await this.$store.dispatch(ActionTypes.SET_SUPPLIER_CERTIFICATE);
            this.suppliers = this.$store.getters.suppliers;
        }
    }

    private async suppliersChangedHandler(): Promise<void> {
        try {
            this.suppliersLoading = true;
            this.supplierChanged = true;
            await this.fetchSuppliers();
        }
        finally {
            this.suppliersLoading = false;
        }
    }

    private get isDeleteDisabled(): boolean {
        return this.deleteDisabled;
    }

    private get steps(): DropdownModel[] {
        return this.orderLineSteps.filter(s => s.stepId !== 0).map(s => ({ value: `${s.stepId}`, text: s.displayName }));
    }

    private get showDelete(): boolean {
        return this.orderLineSteps.length > 1 ? true : false;
    }

    private get dropdownSteps(): DropdownModel[] {
        const result: DropdownModel[] = [];
        this.allSteps.forEach(step => {
            const index = this.orderLineSteps.findIndex(sp => sp.stepId === step.stepId);
            if (index === -1) {
                result.push(new DropdownModel(`${step.stepId}`, step.displayName));
            }
        });
        return result;
    }

    private get tier1Supplier(): DropdownModel[] {
        const tier1Supplier = this.suppliers.filter(t => t.isTier1 === true);
        return tier1Supplier.map(s => ({ value: s.id, text: s.isCompliant ? `${s.companyName} (${s.country})` : `${s.companyName} (${this.$t('pages.home.pending_invite')})` }));
    }

    private get supplyChainPartners(): DropdownModel[] {
        return this.suppliers.map(s => ({ value: s.id, text: s.isCompliant ? `${s.companyName} (${s.country})` : `${s.companyName} (${this.$t('pages.home.pending_invite')})` }));
    }

    private get productGroups(): DropdownModel[] {
        return this.allProductGroups.filter(p => p.id !== 0).map(s => ({ value: `${s.id}`, text: s.description }));
    }

    private get totalStepCount(): number {
        return this.orderLineSteps.length;
    }

    private get assignedStepCount(): number {
        return this.orderLineSteps.filter(o => o.toCompanyId !== Guid.Empty).length;
    }

    private get selectedScpName(): string {
        if (this.selectedTier1Supplier == null || this.selectedTier1Supplier === Guid.Empty) {
            return '';
        }
        //TTD-2620, Agent orderlines changes, added condition
        if (this.selectedTier1Supplier !== null && this.companyType === 5) {
            return this.selectedTier1SupplierName;
        }
        return this.tier1Supplier.filter(s => s.value === this.selectedTier1Supplier)[0].text;
    }

    // TTD-4752
    private get isStepNameEmpty(): boolean {
        let isEmpty = false;
        isEmpty = this.orderLineSteps.some((o) => o.displayName === "" || o.displayName === "None" || o.displayName === undefined || o.displayName === null);
        if (!isEmpty) {
            this.orderLineSteps.forEach((o) => {
                if (o.parallelSupplyChain0.length > 0 && !isEmpty) {
                    isEmpty = o.parallelSupplyChain0.some(
                        (o) => o.displayName === "" || o.displayName === "None" || o.displayName === undefined || o.displayName === null
                    );
                }
                if (o.parallelSupplyChain1.length > 0 && !isEmpty) {
                    isEmpty = o.parallelSupplyChain1.some(
                        (o) => o.displayName === "" || o.displayName === "None" || o.displayName === undefined || o.displayName === null
                    );
                }
                if (o.parallelSupplyChain2.length > 0 && !isEmpty) {
                    isEmpty = o.parallelSupplyChain2.some(
                        (o) => o.displayName === "" || o.displayName === "None" || o.displayName === undefined || o.displayName === null
                    );
                }
                if (o.parallelSupplyChain3.length > 0 && !isEmpty) {
                    isEmpty = o.parallelSupplyChain3.some(
                        (o) => o.displayName === "" || o.displayName === "None" || o.displayName === undefined || o.displayName === null
                    );
                }
                if (o.parallelSupplyChain4.length > 0 && !isEmpty) {
                    isEmpty = o.parallelSupplyChain4.some(
                        (o) => o.displayName === "" || o.displayName === "None" || o.displayName === undefined || o.displayName === null
                    );
                }
            });
        }
        return isEmpty;
    }

    private async getClientSteps(): Promise<void> {
        this.allSteps = StepsHelper.getStepsByProductGroup(this.selectedproductGroupId);
    }

    // Edit OrderLine Steps
    private async editOrderLineStep(): Promise<void> {
        this.order.orderId = this.orderLineData.orderId;
        this.order.orderLineId = this.orderLineData.id;
        this.order.orderNumber = this.orderLineData.orderNumber;
        this.order.styleName = this.orderLineData.styleName;
        this.order.colourway = this.orderLineData.orderLines[0].colourway == undefined || this.orderLineData.orderLines[0].colourway.trim() == '' ? '-' : this.orderLineData.orderLines[0].colourway;
        this.order.styleNumber = this.orderLineData.styleNumber;
        this.order.fromCompanyId = this.orderLineData.fromCompanyId;
        this.order.fromCompanyName = this.orderLineData.fromCompanyName;
        this.selectedTier1Supplier = this.orderLineData.toCompanyId;
        //TTD-2620, Agent orderlines changes
        this.selectedTier1SupplierName = this.orderLineData.toCompanyName;
        this.selectedproductGroupId = this.orderLineData.productGroup;
        this.order.expectedTimeOfDelivery = DateTimeHelper.convertDateDesiredFormat(this.orderLineData.expectedTimeOfDelivery, "D MMM YYYY");
        if (this.orderLineData.agentCompanyUser) {
            this.agentCompanyName = this.orderLineData.agentCompanyUser.name;
        }
    }

    private async getClientStepsForProductGroup(productGroup: number): Promise<void> {
        this.stepsForProductGroup = this.allSteps.filter(s => s.showDefault === true);
        this.orderLineSteps = [];
        if (this.editOrderLineSteps.length > 0) {
            this.showInfo = this.editOrderLineSteps.findIndex(s => s.stepState === StepState.CREATED_BY_SUPPLIER || s.stepState === StepState.UPDATED_BY_SUPPLIER) !== -1 ? true : false;
            this.editOrderLineSteps.sort((step1, step2) => {
                return step1.sequence - step2.sequence;
            });
            this.orderLineSteps = this.editOrderLineSteps.filter(s => (s.stepState !== StepState.DELETED_BY_CLIENT && s.stepState !== StepState.DELETED_BY_SUPPLIER && s.stepState !== StepState.DELETED_BY_AGENT && s.stepState !== StepState.DELETED_BY_DELEGATEPARTNER && s.stepState !== StepState.NONE));
            this.customisedCount = this.editOrderLineSteps.filter(s => (s.stepState === StepState.CREATED_BY_CLIENT || s.stepState === StepState.UPDATED_BY_CLIENT || s.stepState === StepState.NONE) && s.toCompanyId !== Guid.Empty).length;
            this.checkStepsCustomised();
        }
        else {
            this.stepsForProductGroup.forEach(step => {
                this.order.id = Guid.Empty;
                this.order.toCompanyId = Guid.Empty;
                this.order.toCompanyName = "";
                step.displayName = step.displayName.charAt(0).toUpperCase() + step.displayName.slice(1);
                this.orderLineSteps.push(new OrderLineStepModel(this.order, step));
            });
        }
    }

    // Display step name based on the country language
    private displayName(stepId: number): string {
        return this.$t(`enums.steps.${stepId}`).toString();
    }

    // Get Product Group Id from product helper component
    private async getProductGroupId(): Promise<void> {
        const productGroup = ProductGroupHelper.getIdFromProductGroupName(this.selectedProductGroup);
        this.selectedproductGroupId = productGroup.id;
    }

    private getStatusForStep(stepId: number): string {
        const supplierAssigned = this.orderLineSteps.filter(o => o.stepId === stepId)[0].toCompanyId !== Guid.Empty;
        // TTD-4208 Sprint-23
        if(this.selectedTab === 'inprogress' || this.selectedTab === 'completed'){
            this.isMCToCompanyEmpty = this.orderLineSteps.filter(o => o.toCompanyId === Guid.Empty).length > 0 ? true : false;
        }
        return supplierAssigned ? "green" : "red";
    }

    private getStatusForParallelStep(stepId: number, parentStepId: string, indexOfChain: number): string {
        const parentId = parentStepId !== null ? Number.parseInt(parentStepId) : null;
        const supplierAssigned = this.getParallelChain(indexOfChain, parentStepId).filter(c => c.stepId == stepId)[0].toCompanyId !== Guid.Empty;
        return supplierAssigned ? "green" : "red";
    }

    private getParallelChain(index: number, parentStepId: string): OrderLineStepParallelModel[] {
        const parentId = parentStepId !== null ? Number.parseInt(parentStepId) : null;
        let parallelChain: OrderLineStepParallelModel[] = [];
        if (index == 0) {
            parallelChain = this.orderLineSteps.filter(o => o.stepId === parentId)[0].parallelSupplyChain0!;
        } else if (index == 1) {
            parallelChain = this.orderLineSteps.filter(o => o.stepId === parentId)[0].parallelSupplyChain1!;
        } else if (index == 2) {
            parallelChain = this.orderLineSteps.filter(o => o.stepId === parentId)[0].parallelSupplyChain2!;
        } else if (index == 3) {
            parallelChain = this.orderLineSteps.filter(o => o.stepId === parentId)[0].parallelSupplyChain3!;
        } else {
            parallelChain = this.orderLineSteps.filter(o => o.stepId === parentId)[0].parallelSupplyChain4!;
        }
        // TTD-4208 Sprint-23
        if((this.selectedTab === 'inprogress' || this.selectedTab === 'completed') && parallelChain.length > 0){
            this.isPCToCompanyEmpty = parallelChain.filter(p => p.toCompanyId === Guid.Empty).length > 0 ? true : false;
        }
        return parallelChain;
    }

    private addChain(stepIdText: string): void {
        let count = 0;
        this.orderLineSteps.forEach(step => {
            if (step.parallelSupplyChain0 && step.parallelSupplyChain0.length > 0) {
                count = count + 1;
            }
            if (step.parallelSupplyChain1 && step.parallelSupplyChain1.length > 0) {
                count = count + 1;
            }
            if (step.parallelSupplyChain2 && step.parallelSupplyChain2.length > 0) {
                count = count + 1;
            }
            if (step.parallelSupplyChain3 && step.parallelSupplyChain3.length > 0) {
                count = count + 1;
            }
            if (step.parallelSupplyChain4 && step.parallelSupplyChain4.length > 0) {
                count = count + 1;
            }
            if (count >= 5) {
                return;
            }
        });
        if (count >= 5) {
            this.showMaxParallelChainModal = true;
            return;
        }
        const stepId = Number.parseInt(stepIdText);
        const parentChain = this.orderLineSteps.filter(s => s.stepId == stepId)[0];
        const newStep: OrderLineStepParallelModel = new OrderLineStepParallelModel(this.order, { stepId: 0, displayName: "", sequence: 1, productGroup: this.selectedproductGroupId }, stepIdText);
        if (parentChain.parallelSupplyChain0.length === 0) {
            parentChain.parallelSupplyChain0 = [newStep];
            this.setParallelChainStepDropdown(0, stepIdText);
        } else if (parentChain.parallelSupplyChain1.length === 0) {
            parentChain.parallelSupplyChain1 = [newStep];
            this.setParallelChainStepDropdown(1, stepIdText);
        } else if (parentChain.parallelSupplyChain2.length === 0) {
            parentChain.parallelSupplyChain2 = [newStep];
            this.setParallelChainStepDropdown(2, stepIdText);
        } else if (parentChain.parallelSupplyChain3.length === 0) {
            parentChain.parallelSupplyChain3 = [newStep];
            this.setParallelChainStepDropdown(3, stepIdText);
        } else if (parentChain.parallelSupplyChain4.length === 0) {
            parentChain.parallelSupplyChain4 = [newStep];
            this.setParallelChainStepDropdown(4, stepIdText);
        } else {
            this.showMaxParallelChainModal = true;
        }
        this.setChainCount();
        this.calculateDistance(false);
        this.isNewStepAdded = true;
        this.isEditDataChanged = true;
    }

    private changeStyleForParallel(stepId: number, parentStepId: number, indexOfChain: number): void {
        if (this.getParallelChain(indexOfChain, parentStepId.toString()).filter(c => c.stepId == stepId)[0].isSelected === true) {
            this.getParallelChain(indexOfChain, parentStepId.toString()).filter(c => c.stepId == stepId)[0].isSelected = false;
        } else {
            this.getParallelChain(indexOfChain, parentStepId.toString()).filter(c => c.stepId == stepId)[0].isSelected = true;
        }
        const step = this.getParallelChain(indexOfChain, parentStepId.toString()).filter(c => c.stepId == stepId)[0];
        const index = this.getParallelChain(indexOfChain, parentStepId.toString()).findIndex(s => s.stepId == stepId);
        this.getParallelChain(indexOfChain, parentStepId.toString()).splice(index, 1);
        this.getParallelChain(indexOfChain, parentStepId.toString()).splice(index, 0, step); 1;
    }

    private changeStyle(stepId: string): void {
        if (this.orderLineSteps.filter(s => s.stepId === Number.parseInt(stepId))[0].isSelected === true) {
            this.orderLineSteps.filter(s => s.stepId === Number.parseInt(stepId))[0].isSelected = false;
        } else {
            this.orderLineSteps.filter(s => s.stepId === Number.parseInt(stepId))[0].isSelected = true;
        }
        const step = this.orderLineSteps.filter(s => s.stepId === Number.parseInt(stepId))[0];
        const index = this.orderLineSteps.findIndex(s => s.stepId == Number.parseInt(stepId));
        this.orderLineSteps.splice(index, 1);
        this.orderLineSteps.splice(index, 0, step);
    }

    private setName(stepIdText: string): void {
        const stepId = Number.parseInt(stepIdText);
        const item = this.allSteps.filter(s => s.stepId === stepId)[0];
        this.orderLineSteps.filter(sp => sp.stepId === stepId)[0].displayName = item.displayName;
        this.assignNameToOtherNewStep = false;
        this.addingInProgress = false;
    }

    private setNameForParallel(stepIdText: string, indexOfChain: number, parentStepId: string): void {
        const stepId = Number.parseInt(stepIdText);
        const parentId = parentStepId !== null ? Number.parseInt(parentStepId) : 0;
        const item = this.allSteps.filter(s => s.stepId === stepId)[0];
        this.getParallelChain(indexOfChain, parentStepId).filter(c => c.stepId === stepId)[0].displayName = item.displayName;
        this.assignNameToOtherNewStep = false;
        this.addingInProgress = false;
        this.isNewStepAdded = false;
    }

    private enableDelete(event: any): void {
        this.isDragging = true;
        if(event.data !== null && event.data !== undefined){
            const step = event.data as ClientStepModel;
            this.draggedStep = step.stepId;
        }
        this.deleteDisabled = false;
    }

    private disableDelete(): void {
        this.isDragging = false;
        this.deleteDisabled = true;
        this.draggedStep = -1;
        this.setChainCount();
        this.calculateDistance(false);
    }

    private enabledForAgent(item: OrderLineStepModel): boolean {
        if (this.companyType === 5 && item.toCompanyName !== '') {
            if (item.stepState !== StepState.CREATED_BY_AGENT && item.stepState !== StepState.UPDATED_BY_AGENT) {
                return false;
            }
        }
        return true;
    }

    private enabledForAgentParallel(item: OrderLineStepParallelModel): boolean {
        if (this.companyType === 5 && item.toCompanyName !== '') {
            if (item.stepState !== StepState.CREATED_BY_AGENT && item.stepState !== StepState.UPDATED_BY_AGENT) {
                return false;
            }
        }
        return true;
    }

    private add(stepIdText: string): void {
        const stepId = Number.parseInt(stepIdText);
        const index = this.orderLineSteps.findIndex(s => s.stepId === stepId);
        this.addAtIndex(index + 1);
    }

    private addAtIndex(index: number): void {
        if (this.orderLineSteps.some(a => a.stepId === 0)) {
            this.assignNameToOtherNewStep = true;
            return;
        } else if (this.dropdownSteps.length <= 1) {
            return;
        }
        this.addingInProgress = true;
        const order = this.order;
        order.id = Guid.Empty;
        order.toCompanyId = Guid.Empty;
        order.toCompanyName = "";
        const newStep: OrderLineStepModel = new OrderLineStepModel(order, { stepId: 0, displayName: "", sequence: index, productGroup: this.selectedproductGroupId });
        //TTD-2620, Agent orderlines changes, added condition
        if (this.companyType === 5) {
            newStep.updatedByAgent = true;
            newStep.stepState = StepState.UPDATED_BY_AGENT;
        } else {
            newStep.stepState = StepState.UPDATED_BY_CLIENT;
        }
        this.orderLineSteps.splice(index, 0, newStep);
        this.isEditDataChanged = true;
    }

    private addInParallel(stepIdText: string, parentStepIdText: string, indexOfChain: number): void {
        if (!this.isNewStepAdded) {
            const stepId = Number.parseInt(stepIdText);
            const ParentStepId = Number.parseInt(parentStepIdText);
            const index = this.getParallelChain(indexOfChain, parentStepIdText).findIndex(s => s.stepId === stepId);
            const sequence = this.getParallelChain(indexOfChain, parentStepIdText).filter(i => i.stepId === stepId)[0].sequence;
            if (sequence < this.getParallelChain(indexOfChain, parentStepIdText).length) {
                this.getParallelChain(indexOfChain, parentStepIdText).filter(p => p.sequence > sequence).forEach(c => {
                    c.sequence = c.sequence + 1;
                })
            }
            if (stepIdText !== null) {
                const stepId = Number.parseInt(stepIdText);
                const item = this.allSteps.filter(s => s.stepId === stepId)[0];
                const ParentStepId = Number.parseInt(parentStepIdText);
                const newStep: OrderLineStepParallelModel = new OrderLineStepParallelModel(this.order, { stepId: 0, displayName: "", sequence: sequence + 1, productGroup: this.selectedproductGroupId }, parentStepIdText);
                if (this.companyType === 5) {
                    newStep.updatedByAgent = true;
                    newStep.stepState = StepState.UPDATED_BY_AGENT;
                } else {
                    newStep.stepState = StepState.UPDATED_BY_CLIENT;
                }
                this.getParallelChain(indexOfChain, parentStepIdText).splice(index, 0, newStep);
                this.setParallelChainStepDropdown(indexOfChain, parentStepIdText);
                this.isNewStepAdded = true;
                this.calculateDistance(false);
                this.setChainCount();
                this.isEditDataChanged = true;
            }
        }
    }

    private addInParallelAtIndex(index: number, stepIdText: string | null, parentStepIdText: string, indexOfChain: number): void {
        if (!this.isNewStepAdded) {
            if (stepIdText !== null) {
                const stepId = Number.parseInt(stepIdText);
                const item = this.allSteps.filter(s => s.stepId === stepId)[0];
                const ParentStepId = Number.parseInt(parentStepIdText);
                const newStep: OrderLineStepParallelModel = new OrderLineStepParallelModel(this.order, { stepId: 0, displayName: "", sequence: 1, productGroup: this.selectedproductGroupId }, parentStepIdText);
                this.getParallelChain(indexOfChain, parentStepIdText).forEach(p => {
                    p.sequence = p.sequence + 1;
                })
                this.getParallelChain(indexOfChain, parentStepIdText).splice(index + 1, 0, newStep);
                this.setParallelChainStepDropdown(indexOfChain, parentStepIdText);
                this.isNewStepAdded = true;
                this.calculateDistance(false);
                this.isEditDataChanged = true;
            }
        }
    }

    private calculateDistance(onRender: boolean): void {
        setTimeout(async (app: any) => {
            const leftPoint = document.getElementById('step-list-custom-scroll')!.getBoundingClientRect();
            if (document.getElementById('leftest-leftplus') && document.getElementById('leftest-leftplus')!.getBoundingClientRect().x < leftPoint.x) {
                let padding = 0;
                if (onRender) {
                    const required = Math.abs(document.getElementById('leftest-leftplus')!.getBoundingClientRect().x) + leftPoint.x;
                    padding = app.paddingLeft + required;
                } else {
                    padding = app.paddingLeft + 200;
                }
                app.paddingLeft = padding;
                const outerElement = document.getElementById('step-list-custom-scroll');
                outerElement!.style.paddingLeft = padding + 'px';
            }
            if (document.getElementById('leftest-add') && document.getElementById('leftest-add')!.getBoundingClientRect().x < leftPoint.x) {
                // const distanceToAdd =
                let padding = 0;
                if (onRender) {
                    const required = Math.abs(document.getElementById('leftest-add')!.getBoundingClientRect().x) + leftPoint.x;
                    padding = app.paddingLeft + required;
                } else {
                    padding = app.paddingLeft + 210;
                }

                app.paddingLeft = padding;
                const outerElement = document.getElementById('step-list-custom-scroll');
                outerElement!.style.paddingLeft = padding + 'px';
            }
        }, 450, this);
    }

    private setParallelChainStepDropdown(indexOfChain: number, parentStepIdText: string): void {
        let parentStepIndex = 0;
        switch (indexOfChain) {
            case 0:
                this.parallelChain0DropdownSteps = [];
                parentStepIndex = this.orderLineSteps.findIndex(s => s.stepId === Number.parseInt(parentStepIdText));
                this.allSteps.forEach(step => {
                    const index = this.orderLineSteps[parentStepIndex].parallelSupplyChain0.findIndex(sp => sp.stepId === step.stepId);
                    if (index === -1) {
                        this.parallelChain0DropdownSteps.push(new DropdownModel(`${step.stepId}`, step.displayName));
                    }
                });
                break;
            case 1:
                this.parallelChain1DropdownSteps = [];
                parentStepIndex = this.orderLineSteps.findIndex(s => s.stepId === Number.parseInt(parentStepIdText));
                this.allSteps.forEach(step => {
                    const index = this.orderLineSteps[parentStepIndex].parallelSupplyChain1?.findIndex(sp => sp.stepId === step.stepId);
                    if (index === -1) {
                        this.parallelChain1DropdownSteps.push(new DropdownModel(`${step.stepId}`, step.displayName));
                    }
                });
                break;
            case 2:
                this.parallelChain2DropdownSteps = [];
                parentStepIndex = this.orderLineSteps.findIndex(s => s.stepId === Number.parseInt(parentStepIdText));
                this.allSteps.forEach(step => {
                    const index = this.orderLineSteps[parentStepIndex].parallelSupplyChain2?.findIndex(sp => sp.stepId === step.stepId);
                    if (index === -1) {
                        this.parallelChain2DropdownSteps.push(new DropdownModel(`${step.stepId}`, step.displayName));
                    }
                });
                break;
            case 3:
                this.parallelChain3DropdownSteps = [];
                parentStepIndex = this.orderLineSteps.findIndex(s => s.stepId === Number.parseInt(parentStepIdText));
                this.allSteps.forEach(step => {
                    const index = this.orderLineSteps[parentStepIndex].parallelSupplyChain3?.findIndex(sp => sp.stepId === step.stepId);
                    if (index === -1) {
                        this.parallelChain3DropdownSteps.push(new DropdownModel(`${step.stepId}`, step.displayName));
                    }
                });
                break;
            case 4:
                this.parallelChain4DropdownSteps = [];
                parentStepIndex = this.orderLineSteps.findIndex(s => s.stepId === Number.parseInt(parentStepIdText));
                this.allSteps.forEach(step => {
                    const index = this.orderLineSteps[parentStepIndex].parallelSupplyChain4?.findIndex(sp => sp.stepId === step.stepId);
                    if (index === -1) {
                        this.parallelChain4DropdownSteps.push(new DropdownModel(`${step.stepId}`, step.displayName));
                    }
                });
                break;
        }
    }

    private setChainCount(): void {
        let count = 0;
        this.isParallelSupplyChain = false;
        let tempOrderLineSteps = lodash.cloneDeep(this.orderLineSteps);
        tempOrderLineSteps.forEach(o => {
            o.parallelChainCount = count;
            if (o.parallelSupplyChain0.length > 0) {
                count++;
                // o.parallelSupplyChain0.forEach((p0, index) => {
                //     p0.sequence = o.parallelSupplyChain0.length - index;
                // })
                this.isParallelSupplyChain = true;
            }
            if (o.parallelSupplyChain1.length > 0) {
                count++;
                // o.parallelSupplyChain1.forEach((p1, index1) => {
                //     p1.sequence = o.parallelSupplyChain1.length - index1;
                // })
                this.isParallelSupplyChain = true;
            }
            if (o.parallelSupplyChain2.length > 0) {
                count++;
                // o.parallelSupplyChain2.forEach((p2, index2) => {
                //     p2.sequence = o.parallelSupplyChain2.length - index2;
                // })
                this.isParallelSupplyChain = true;
            }
            if (o.parallelSupplyChain3.length > 0) {
                count++;
                // o.parallelSupplyChain3.forEach((p3, index3) => {
                //     p3.sequence = o.parallelSupplyChain3.length - index3;
                // })
                this.isParallelSupplyChain = true;
            }
            if (o.parallelSupplyChain4.length > 0) {
                count++;
                // o.parallelSupplyChain4.forEach((p4, index4) => {
                //     p4.sequence = o.parallelSupplyChain4.length - index4;
                // })
                this.isParallelSupplyChain = true;
            }
        });
        this.orderLineSteps = tempOrderLineSteps;
    }

    private isLastStep(stepId: string): boolean {
        const index = this.orderLineSteps.findIndex(s => s.stepId === Number.parseInt(stepId));
        if (this.orderLineSteps.length === index + 1) {
            return true;
        }
        return false;
    }

    private removeParallelChainOrStep(stepIdText: string, parentStepIdText: string, indexOfChain: number) {
        const stepId = Number.parseInt(stepIdText);
        const ParentStepId = Number.parseInt(parentStepIdText);
        const indexOfStep = this.getParallelChain(indexOfChain, parentStepIdText).findIndex(s => s.stepId === stepId);
        const allStepsInParallel = this.getParallelChain(indexOfChain, parentStepIdText);
        if (allStepsInParallel.length > 1) {
            if (indexOfChain == 0) {
                if (this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain0?.filter(p => p.stepId === stepId)[0].toCompanyName !== '' && this.companyType === 5 && (this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain0?.filter(p => p.stepId === stepId)[0].stepState !== StepState.CREATED_BY_AGENT && this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain0?.filter(p => p.stepId === stepId)[0].stepState !== StepState.UPDATED_BY_AGENT)) {
                    return;
                } else {
                    this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain0.splice(indexOfStep, 1);
                }
            } else if (indexOfChain == 1) {
                if (this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain1?.filter(p => p.stepId === stepId)[0].toCompanyName !== '' && this.companyType === 5 && (this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain1?.filter(p => p.stepId === stepId)[0].stepState !== StepState.CREATED_BY_AGENT && this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain1?.filter(p => p.stepId === stepId)[0].stepState !== StepState.UPDATED_BY_AGENT)) {
                    return;
                } else {
                    this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain1.splice(indexOfStep, 1);
                }
            } else if (indexOfChain == 2) {
                if (this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain2?.filter(p => p.stepId === stepId)[0].toCompanyName !== '' && this.companyType === 5 && (this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain2?.filter(p => p.stepId === stepId)[0].stepState !== StepState.CREATED_BY_AGENT && this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain2?.filter(p => p.stepId === stepId)[0].stepState !== StepState.UPDATED_BY_AGENT)) {
                    return;
                } else {
                    this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain2.splice(indexOfStep, 1);
                }
            } else if (indexOfChain == 3) {
                if (this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain3?.filter(p => p.stepId === stepId)[0].toCompanyName !== '' && this.companyType === 5 && (this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain3?.filter(p => p.stepId === stepId)[0].stepState !== StepState.CREATED_BY_AGENT && this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain3?.filter(p => p.stepId === stepId)[0].stepState !== StepState.UPDATED_BY_AGENT)) {
                    return;
                } else {
                    this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain3.splice(indexOfStep, 1);
                }
            } else if (indexOfChain == 4) {
                if (this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain4?.filter(p => p.stepId === stepId)[0].toCompanyName !== '' && this.companyType === 5 && (this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain4?.filter(p => p.stepId === stepId)[0].stepState !== StepState.CREATED_BY_AGENT && this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain4?.filter(p => p.stepId === stepId)[0].stepState !== StepState.UPDATED_BY_AGENT)) {
                    return;
                } else {
                    this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain4.splice(indexOfStep, 1);
                }
            }
        } else {
            let dontDelete: boolean = false;
            if (this.companyType === 5) {
                if (indexOfChain === 0 && this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain0.filter(p => p.stepId === stepId)[0].toCompanyName !== '' && (this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain0.filter(p => p.stepId === stepId)[0].stepState !== StepState.CREATED_BY_AGENT && this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain0.filter(p => p.stepId === stepId)[0].stepState !== StepState.UPDATED_BY_AGENT)) {
                    dontDelete = true;
                } else if (indexOfChain === 1 && this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain1.filter(p => p.stepId === stepId)[0].toCompanyName !== '' && (this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain1.filter(p => p.stepId === stepId)[0].stepState !== StepState.CREATED_BY_AGENT && this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain1.filter(p => p.stepId === stepId)[0].stepState !== StepState.UPDATED_BY_AGENT)) {
                    dontDelete = true;
                } else if (indexOfChain === 2 && this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain2.filter(p => p.stepId === stepId)[0].toCompanyName !== '' && (this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain2.filter(p => p.stepId === stepId)[0].stepState !== StepState.CREATED_BY_AGENT && this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain2.filter(p => p.stepId === stepId)[0].stepState !== StepState.UPDATED_BY_AGENT)) {
                    dontDelete = true;
                } else if (indexOfChain === 3 && this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain3.filter(p => p.stepId === stepId)[0].toCompanyName !== '' && (this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain3.filter(p => p.stepId === stepId)[0].stepState !== StepState.CREATED_BY_AGENT && this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain3.filter(p => p.stepId === stepId)[0].stepState !== StepState.UPDATED_BY_AGENT)) {
                    dontDelete = true;
                } else if (indexOfChain === 4 && this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain4.filter(p => p.stepId === stepId)[0].toCompanyName !== '' && (this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain4.filter(p => p.stepId === stepId)[0].stepState !== StepState.CREATED_BY_AGENT && this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain4.filter(p => p.stepId === stepId)[0].stepState !== StepState.UPDATED_BY_AGENT)) {
                    dontDelete = true;
                }
            }
            if (!dontDelete) {
                switch (indexOfChain) {
                    case 0:
                        this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain0 = [];
                        this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain0 = this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain1;
                        this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain1 = this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain2;
                        this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain2 = this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain3;
                        this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain3 = this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain4;
                        this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain4 = [];
                        this.setParallelChainStepDropdown(0, parentStepIdText);
                        break;
                    case 1:
                        this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain1 = [];
                        this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain1 = this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain2;
                        this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain2 = this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain3;
                        this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain3 = this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain4;
                        this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain4 = [];
                        this.setParallelChainStepDropdown(1, parentStepIdText);
                        break;
                    case 2:
                        this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain2 = [];
                        this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain2 = this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain3;
                        this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain3 = this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain4;
                        this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain4 = [];
                        this.setParallelChainStepDropdown(2, parentStepIdText);
                        break;
                    case 3:
                        this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain3 = [];
                        this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain3 = this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain4;
                        this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain4 = [];
                        this.setParallelChainStepDropdown(3, parentStepIdText);
                        break;
                    default:
                        this.orderLineSteps.filter(s => s.stepId == ParentStepId)[0].parallelSupplyChain4 = [];
                        this.setParallelChainStepDropdown(4, parentStepIdText);
                        break;
                }
                this.setChainCount();
            }
        }
        this.isEditDataChanged = true;
        this.checkStepsCustomised();
    }

    private setInvalid(): void {
        this.assignNameToOtherNewStep = true;
    }

    private deleteStep(event: any): void {
        if (this.orderLineSteps.length <= 1 || this.deleteDisabled) {
            return;
        }
        const step = event.data as OrderLineStepModel;
        const index = this.orderLineSteps.findIndex(s => s.stepId === step.stepId);
        if (step.toCompanyId !== Guid.Empty && step.toCompanyName !== "") {
            this.customisedCount = this.customisedCount > 0 ? this.customisedCount - 1 : 0;
        }
        this.orderLineSteps.splice(index, 1);
    }

    private swipeLeft(): void {
        const contentToScroll = this.$refs.scrollContent as Vue;
        contentToScroll.$el.scrollLeft -= 200;
    }

    private swipeRight(): void {
        const contentToScroll = this.$refs.scrollContent as Vue;
        contentToScroll.$el.scrollLeft += 200;
    }

    private remove(stepIdText: string): void {
        const stepId = Number.parseInt(stepIdText);
        const parentStep = this.orderLineSteps.filter(o => o.stepId === stepId);
        if (this.companyType === 5 && parentStep[0].toCompanyName !== '' && (parentStep[0].stepState !== StepState.CREATED_BY_AGENT && parentStep[0].stepState !== StepState.UPDATED_BY_AGENT)) {
            return;
        }
        if (parentStep[0].parallelSupplyChain0.length == 0 && parentStep[0].parallelSupplyChain1.length == 0 && parentStep[0].parallelSupplyChain2.length == 0 && parentStep[0].parallelSupplyChain3.length == 0 && parentStep[0].parallelSupplyChain4.length == 0) {
            // if (this.companyType === 5 && parentStep[0].toCompanyName !== '' && (parentStep[0].stepState !== 7 && parentStep[0].stepState !== 8)) {
            //     return;
            // }
            this.stepIdToRemove = stepId;
            this.removeChain();
        } else {
            let disabledDelete: boolean = false;
            if (this.companyType === 5) {
                if (parentStep[0].parallelSupplyChain0?.length > 0) {
                    parentStep[0].parallelSupplyChain0.forEach(p0 => {
                        if (p0.toCompanyName !== '' && (p0.stepState !== StepState.CREATED_BY_AGENT && p0.stepState !== StepState.UPDATED_BY_AGENT)) {
                            disabledDelete = true;
                            return;
                        }
                    })
                }
                if (parentStep[0].parallelSupplyChain1?.length > 0) {
                    parentStep[0].parallelSupplyChain1.forEach(p1 => {
                        if (p1.toCompanyName !== '' && (p1.stepState !== StepState.CREATED_BY_AGENT && p1.stepState !== StepState.UPDATED_BY_AGENT)) {
                            disabledDelete = true;
                            return;
                        }
                    })
                }
                if (parentStep[0].parallelSupplyChain2?.length > 0) {
                    parentStep[0].parallelSupplyChain2.forEach(p2 => {
                        if (p2.toCompanyName !== '' && (p2.stepState !== StepState.CREATED_BY_AGENT && p2.stepState !== StepState.UPDATED_BY_AGENT)) {
                            disabledDelete = true;
                            return;
                        }
                    })
                }
                if (parentStep[0].parallelSupplyChain3?.length > 0) {
                    parentStep[0].parallelSupplyChain3.forEach(p3 => {
                        if (p3.toCompanyName !== '' && (p3.stepState !== StepState.CREATED_BY_AGENT && p3.stepState !== StepState.UPDATED_BY_AGENT)) {
                            disabledDelete = true;
                            return;
                        }
                    })
                }
                if (parentStep[0].parallelSupplyChain4?.length > 0) {
                    parentStep[0].parallelSupplyChain4.forEach(p4 => {
                        if (p4.toCompanyName !== '' && (p4.stepState !== StepState.CREATED_BY_AGENT && p4.stepState !== StepState.UPDATED_BY_AGENT)) {
                            disabledDelete = true;
                            return;
                        }
                    })
                }
            }
            if (!disabledDelete) {
                this.stepIdToRemove = stepId;
                this.showRemoveStepModal = true;
            }
        }
        this.isEditDataChanged = true;
    }

    private removeChain(): void {
        const indexOfStep = this.orderLineSteps.findIndex(s => s.stepId == this.stepIdToRemove);
        this.orderLineSteps.splice(indexOfStep, 1);
        this.setChainCount();
        this.stepIdToRemove = -1;
        this.showRemoveStepModal = false;
        this.checkStepsCustomised();
    }
    private closeModal(stepIdText: string): void {
        this.stepIdToRemove = -1;
        this.showRemoveStepModal = false;
    }

    private closeSearchHandler(): void {
        this.showTier1 = false;
        this.showSupplier = false;
        this.changeStyle(this.selectedStep.toString());
    }

    private closeSearchHandlerParallel(): void {
        this.showSupplierForParallel = false;
        this.showSupplier = false;
        if (!this.showAgent) {
            this.changeStyleForParallel(this.selectedParallelStep, this.parentStepId, this.indexOfChain);
        }
        this.showAgent = false;
    }

    private showleftRightIcon(parentStepId: string, index: number): boolean {
        let show = false;
        const parentIndex = this.orderLineSteps.findIndex(s => s.stepId === Number(parentStepId));
        switch (index) {
            case 0: {
                show = this.orderLineSteps[parentIndex].parallelSupplyChain0.length > 1 ? true : false;
                break;
            }
            case 1: {
                show = this.orderLineSteps[parentIndex].parallelSupplyChain1.length > 1 ? true : false;
                break;
            }
            case 2: {
                show = this.orderLineSteps[parentIndex].parallelSupplyChain2.length > 1 ? true : false;
                break;
            }
            case 3: {
                show = this.orderLineSteps[parentIndex].parallelSupplyChain3.length > 1 ? true : false;
                break;
            }
            case 4: {
                show = this.orderLineSteps[parentIndex].parallelSupplyChain4.length > 1 ? true : false;
                break;
            }
        }
        return show;
    }

    private openSearchSelectHandler(stepId: number, toCompanyId: string): void {
        if (stepId != -1) {
            const index = this.orderLineSteps.findIndex(s => s.stepId === stepId);
            if (this.companyType === 5) {
                const stepData = this.orderLineSteps.filter(s => s.stepId === stepId);
                if (stepData[0].toCompanyName !== '' && (stepData[0].stepState !== StepState.CREATED_BY_AGENT && stepData[0].stepState !== StepState.UPDATED_BY_AGENT)) {
                    return;
                }
            }
            this.toCompanyId = toCompanyId;
            this.stepIndex = index;
            this.selectedStep = stepId;
            this.showSupplier = true;
            this.changeStyle(stepId.toString());
            if (this.orderLineSteps[index].footprintRefNo !== '') {
                this.referenceNumber = this.orderLineSteps[index].footprintRefNo;
            } else {
                this.referenceNumber = '';
            }
            return;
        }
        this.showTier1 = true;
    }

    private openSearchSelectHandlerParallel(stepId: number, parentStepId: number, indexOfChain: number, toCompanyId: string): void {
        if (stepId !== -1) {
            const index = this.getParallelChain(indexOfChain, parentStepId.toString()).findIndex(t => t.stepId === stepId);
            if (this.companyType === 5) {
                const stepData = this.getParallelChain(indexOfChain, parentStepId.toString()).filter(f => f.stepId === stepId);
                if (stepData[0].toCompanyName !== '' && (stepData[0].stepState !== StepState.CREATED_BY_AGENT && stepData[0].stepState !== StepState.UPDATED_BY_AGENT)) {
                    return;
                }
            }
            this.toCompanyId = toCompanyId;
            this.indexOfChain = indexOfChain;
            this.indexOfChainStep = index;
            this.selectedParallelStep = stepId;
            this.parentStepId = parentStepId;
            this.showSupplier = true;
            this.showSupplierForParallel = true;
            if (this.getParallelChain(this.indexOfChain, this.parentStepId.toString())[
                this.indexOfChainStep
            ].footprintRefNo !== '') {
                this.referenceNumber = this.getParallelChain(this.indexOfChain, this.parentStepId.toString())[
                    this.indexOfChainStep
                ].footprintRefNo
            }
            this.changeStyleForParallel(stepId, parentStepId, indexOfChain);
            return;
        }
        this.showTier1 = true;
    }

    private setTier1SupplierHandler(value: string): void {
        this.selectedTier1Supplier = value;
        this.showTier1 = false;
        this.showSupplier = false;
    }

    private async invitationSendHandler(): Promise<void> {
        this.suppliersChanged();
    }

    private setSupplierHandler(value: string, refNum: string): void {
        const step = this.orderLineSteps[this.stepIndex];
        if (step.toCompanyName === "") {
            this.customisedCount++;
            //TTD-2620, Agent orderlines changes, added condition
            if (this.companyType === 5) {
                step.stepState = StepState.UPDATED_BY_AGENT;
            } else {
                step.stepState = StepState.UPDATED_BY_CLIENT;
            }
        }
        if (step.toCompanyName !== "" && (step.stepState === StepState.CREATED_BY_SUPPLIER || step.stepState === StepState.UPDATED_BY_SUPPLIER)) {
            this.customisedCount++;
            //TTD-2620, Agent orderlines changes, added condition
            if (this.companyType === 5) {
                step.stepState = StepState.UPDATED_BY_AGENT;
            } else {
                step.stepState = StepState.UPDATED_BY_CLIENT;
            }
        }
        //TTD-2620, Agent orderlines changes, added condition
        if (step.toCompanyName !== "" && (step.stepState === StepState.CREATED_BY_CLIENT || step.stepState === StepState.UPDATED_BY_CLIENT)) {
            this.customisedCount++;
            if (this.companyType === 5) {
                step.stepState = StepState.UPDATED_BY_AGENT;
            }
        } else if (step.toCompanyName !== "" && this.companyType !== 5) {
            step.stepState = StepState.UPDATED_BY_CLIENT;
        }
        const selectSCP = this.suppliers.filter(s => s.id === value)[0];
        const history: StepHistory = {};
        history.stepId = step.stepId;
        history.createdByCompanyId = step.fromCompanyId === Guid.Empty ? this.company.companyId : step.fromCompanyId;
        history.createdByCompanyName = step.fromCompanyId === Guid.Empty ? this.company.companyName : '';
        history.editedByCompanyId = this.company.companyId;
        history.editedByCompanyName = this.company.companyName;
        this.companyStepHistory.push(history);
        step.fromCompanyId = this.company.companyId;
        step.toCompanyName = selectSCP.companyName;
        step.toCompanyId = selectSCP.id;
        this.showTier1 = false;
        this.showSupplier = false;
        const user = this.$store.getters.user as UserModel;
        if (this.orderLineSteps[this.stepIndex].footprintRefNo !== refNum) {
            if (this.orderLineSteps[this.stepIndex].footprintRefID === Guid.Empty) {
                this.orderLineSteps[this.stepIndex].footprintRefID = uuidv4();
            }
            this.orderLineSteps[this.stepIndex].footprintRefNo = refNum;
            this.orderLineSteps[this.stepIndex].footprintRefNoUpdatedBy = user.companyId;
        }
        this.changeStyle(this.selectedStep.toString());
        this.isEditDataChanged = true;
        this.checkStepsCustomised();
    }

    private setSupplierHandlerParallel(value: string, refNum: string): void {
        if (this.showAgent) {
            const selectAgent = this.suppliers.filter(s => s.id === value)[0];
            if (this.agentCompanyName !== selectAgent.companyName) {
                this.agentCompanyName = selectAgent.companyName;
                this.showAgent = false;
                this.orderLineData.agentCompanyID = selectAgent.id;
                this.orderLineData.agentCompanyUser.ID = selectAgent.id;
                this.orderLineData.agentCompanyUser.name = selectAgent.companyName;
                this.orderLineData.agentCompanyUser.firstName = selectAgent.firstName;
                this.orderLineData.agentCompanyUser.lastName = selectAgent.lastName;
                this.isUpdated = true;
            }
        } else {
            if (this.orderLineSteps.filter(s => s.stepId == this.parentStepId)[0].toCompanyName === "") {
                this.customisedCount++;
            }
            const selectSCP = this.suppliers.filter(s => s.id === value)[0];
            this.getParallelChain(this.indexOfChain, this.parentStepId.toString())[this.indexOfChainStep].fromCompanyId = this.company.companyId,
                this.getParallelChain(this.indexOfChain, this.parentStepId.toString())[this.indexOfChainStep].toCompanyName = selectSCP.companyName;
            this.getParallelChain(this.indexOfChain, this.parentStepId.toString())[this.indexOfChainStep].toCompanyId = selectSCP.id;
            if (this.companyType === 5) {
                this.getParallelChain(this.indexOfChain, this.parentStepId.toString())[this.indexOfChainStep].stepState = StepState.UPDATED_BY_AGENT;
            } else {
                this.getParallelChain(this.indexOfChain, this.parentStepId.toString())[this.indexOfChainStep].stepState = StepState.UPDATED_BY_CLIENT;
            }
            const user = this.$store.getters.user as UserModel;
            if (this.getParallelChain(this.indexOfChain, this.parentStepId.toString())[
                this.indexOfChainStep
            ].footprintRefNo !== refNum) {
                if (this.getParallelChain(this.indexOfChain, this.parentStepId.toString())[
                    this.indexOfChainStep
                ].footprintRefID === Guid.Empty) {
                    this.getParallelChain(this.indexOfChain, this.parentStepId.toString())[
                        this.indexOfChainStep
                    ].footprintRefID = uuidv4();
                }
                this.getParallelChain(this.indexOfChain, this.parentStepId.toString())[
                    this.indexOfChainStep
                ].footprintRefNo = refNum;
                this.getParallelChain(this.indexOfChain, this.parentStepId.toString())[
                    this.indexOfChainStep
                ].footprintRefNoUpdatedBy = user.companyId;
            }
            this.showTier1 = false;
            this.showSupplier = false;
            this.showSupplierForParallel = false;
            this.changeStyleForParallel(this.selectedParallelStep, this.parentStepId, this.indexOfChain);
            this.checkStepsCustomised();
        }
    }

    private create() {
        const confirmationText = this.$t('pages.order_lines.confirm_update_supply_chain').toString();
        this.$confirm(confirmationText, undefined, undefined, { allowOutsideClick: false }).then(() => {
            this.createSupplyChainAsync();
        }).catch(onrejected => { });
    }

    private async createSupplyChainAsync(): Promise<void> {
        try {
            this.orderConfirming = true;
            let confirmOrderLineStep: OrderLineStepDisplayModel[] = [];
            let isOrderLineStepsUpdated: boolean = false;
            if (JSON.stringify(this.orderLineSteps) !== JSON.stringify(this.orderLineStepsClone)) {
                isOrderLineStepsUpdated = true;
            }
            this.orderLineSteps.forEach((step, index) => {
                if (step.fromCompanyId === Guid.Empty || step.fromCompanyId === "") {
                    step.fromCompanyId = Guid.Empty;
                }
                if (step.id === (Guid.Empty || undefined)) {
                    step.id = uuidv4();
                }
                step.clientID = step.clientID === Guid.Empty ? this.orderLineData.fromCompanyId : step.clientID;
                step.cpSupplierID = step.cpSupplierID === Guid.Empty ? this.orderLineData.toCompanyId : step.cpSupplierID;
                const stepHistory: StepHistory = {
                    displayName: this.stepNames[step.stepId],
                    stepId: step.stepId,
                    sequence: this.editOrderLineSteps.findIndex(s => s.stepId === step.stepId) !== -1 ? this.editOrderLineSteps.filter(s => s.stepId === step.stepId)[0].sequence : this.allSteps.filter(s => s.stepId === step.stepId)[0].sequence,
                    description: "",
                    createdByCompanyId: this.companyStepHistory.length > 0 ? this.companyStepHistory.filter(h => h.stepId === step.stepId).length > 0 ? this.companyStepHistory.filter(h => h.stepId === step.stepId)[0].createdByCompanyId : this.company.companyId : this.company.companyId,
                    createdByCompanyName: this.companyStepHistory.length > 0 ? this.companyStepHistory.filter(h => h.stepId === step.stepId).length > 0 ? this.companyStepHistory.filter(h => h.stepId === step.stepId)[0].createdByCompanyName : this.company.companyName : this.company.companyName,
                    editedByCompanyId: this.companyStepHistory.length > 0 ? this.companyStepHistory.filter(h => h.stepId === step.stepId).length > 0 ? this.companyStepHistory.filter(h => h.stepId === step.stepId)[0].editedByCompanyId : this.company.companyId : this.company.companyId,
                    editedByCompanyName: this.companyStepHistory.length > 0 ? this.companyStepHistory.filter(h => h.stepId === step.stepId).length > 0 ? this.companyStepHistory.filter(h => h.stepId === step.stepId)[0].editedByCompanyName : this.company.companyName : this.company.companyName,
                    updateAt: new Date().toISOString()
                };
                step.sequence = index + 1;
                step.displayName = this.stepNames[step.stepId];
                step.isLastStep = step.sequence === this.orderLineSteps.length ? 'true' : 'false';
                const clientToCompanyId = this.editOrderLineSteps.findIndex(s => s.stepId === step.stepId) !== -1 ? this.editOrderLineSteps.filter(s => s.stepId === step.stepId)[0].toCompanyId : undefined;
                if (clientToCompanyId !== undefined && clientToCompanyId !== step.toCompanyId) {
                    step.stepState = step.stepState === StepState.UPDATED_BY_AGENT ? step.stepState : StepState.UPDATED_BY_CLIENT;
                }
                if (step.parallelSupplyChain0.length > 0) {
                    step.parallelSupplyChain0.forEach(s => {
                        s.id = (s.id === undefined || s.id === Guid.Empty) ? uuidv4() : s.id;
                        s.fromCompanyId = s.fromCompanyId === Guid.Empty ? this.company.companyId : s.fromCompanyId;
                        s.stepState = s.stepState === 0 ? StepState.CREATED_BY_CLIENT : s.stepState;
                        s.displayName = this.stepNames[s.stepId];
                    });
                }
                if (step.parallelSupplyChain1.length > 0) {
                    step.parallelSupplyChain1.forEach(s => {
                        s.id = (s.id === undefined || s.id === Guid.Empty) ? uuidv4() : s.id;
                        s.fromCompanyId = s.fromCompanyId === Guid.Empty ? this.company.companyId : s.fromCompanyId;
                        s.stepState = s.stepState === 0 ? StepState.CREATED_BY_CLIENT : s.stepState;
                        s.displayName = this.stepNames[s.stepId];
                    });
                }
                if (step.parallelSupplyChain2.length > 0) {
                    step.parallelSupplyChain2.forEach(s => {
                        s.id = (s.id === undefined || s.id === Guid.Empty) ? uuidv4() : s.id;
                        s.fromCompanyId = s.fromCompanyId === Guid.Empty ? this.company.companyId : s.fromCompanyId;
                        s.stepState = s.stepState === 0 ? StepState.CREATED_BY_CLIENT : s.stepState;
                        s.displayName = this.stepNames[s.stepId];
                    });
                }
                if (step.parallelSupplyChain3.length > 0) {
                    step.parallelSupplyChain3.forEach(s => {
                        s.id = (s.id === undefined || s.id === Guid.Empty) ? uuidv4() : s.id;
                        s.fromCompanyId = s.fromCompanyId === Guid.Empty ? this.company.companyId : s.fromCompanyId;
                        s.stepState = s.stepState === 0 ? StepState.CREATED_BY_CLIENT : s.stepState;
                        s.displayName = this.stepNames[s.stepId];
                    });
                }
                if (step.parallelSupplyChain4.length > 0) {
                    step.parallelSupplyChain4.forEach(s => {
                        s.id = (s.id === undefined || s.id === Guid.Empty) ? uuidv4() : s.id;
                        s.fromCompanyId = s.fromCompanyId === Guid.Empty ? this.company.companyId : s.fromCompanyId;
                        s.stepState = s.stepState === 0 ? StepState.CREATED_BY_CLIENT : s.stepState;
                        s.displayName = this.stepNames[s.stepId];
                    });
                }
                confirmOrderLineStep.push(step);
                confirmOrderLineStep[confirmOrderLineStep.length - 1].stepHistory.push(new StepHistory(stepHistory));
            });
            this.editOrderLineSteps.forEach(line => {
                if (confirmOrderLineStep.find(s => s.id === line.id) === undefined) {
                    line.stepState = StepState.DELETED_BY_CLIENT;
                    line.displayName = this.stepNames[line.stepId];
                    if (line.parallelSupplyChain0.length > 0) {
                        line.parallelSupplyChain0.forEach(p => {
                            p.stepState = StepState.DELETED_BY_CLIENT;
                            p.displayName = this.stepNames[p.stepId];
                        });
                    }
                    if (line.parallelSupplyChain1.length > 0) {
                        line.parallelSupplyChain1.forEach(p => {
                            p.stepState = StepState.DELETED_BY_CLIENT;
                            p.displayName = this.stepNames[p.stepId];
                        });
                    }
                    if (line.parallelSupplyChain2.length > 0) {
                        line.parallelSupplyChain2.forEach(p => {
                            p.stepState = StepState.DELETED_BY_CLIENT;
                            p.displayName = this.stepNames[p.stepId];
                        });
                    }
                    if (line.parallelSupplyChain3.length > 0) {
                        line.parallelSupplyChain3.forEach(p => {
                            p.stepState = StepState.DELETED_BY_CLIENT;
                            p.displayName = this.stepNames[p.stepId];
                        });
                    }
                    if (line.parallelSupplyChain4.length > 0) {
                        line.parallelSupplyChain4.forEach(p => {
                            p.stepState = StepState.DELETED_BY_CLIENT;
                            p.displayName = this.stepNames[p.stepId];
                        });
                    }
                    confirmOrderLineStep.push(line);
                }
            });
            confirmOrderLineStep = GroupStepEvidenceHelper.updateApproveStepEvidece(confirmOrderLineStep);
            let result: boolean = false;
            if (this.companyType === 5) {
                let agentUpdateDetails: OrderLineStepDisplayModel[] = lodash.cloneDeep(confirmOrderLineStep);
                agentUpdateDetails = agentUpdateDetails.filter(c => c.id !== Guid.Empty);
                result = await this.orderService.editSupplyChainStepsAsync(agentUpdateDetails);
            } else {
                // TTD-4203 (git branch: TTD-4202)
                if(isOrderLineStepsUpdated){
                    result = await this.orderService.editSupplyChainStepsAsync(confirmOrderLineStep);
                }
            }
            if (result && this.companyType === 5) {
                this.updateSteps();
            }
            if (this.agentCompanyName === '') {
                this.orderLineData.agentCompanyID = Guid.Empty;
                this.orderLineData.agentCompanyUser.ID = Guid.Empty;
                this.orderLineData.agentCompanyUser.name = "";
                this.orderLineData.agentCompanyUser.firstName = "";
                this.orderLineData.agentCompanyUser.lastName = "";
            }
            this.updateAgentDetails(this.isUpdated, isOrderLineStepsUpdated);
            this.close(true, true);
        } finally {
            this.orderConfirming = false;
        }
    }

    checkTextLenght(): boolean {
        const colourway = this.order.colourway;
        if (colourway) {
            return colourway.length > 16;
        }
        return false;
    }

    private showAgentList(type: string) {
        this.showAgent = true;
        if (type === 'edit') {
            this.selectOrEditTitle = 'Edit agent';
        } else {
            this.selectOrEditTitle = 'Select agent';
        }
    }

    private removeAgent() {
        this.isShowDeleteAgent = false;
        this.agentCompanyName = '';
        this.isUpdated = true;
    }

    private checkStepsCustomised() {
        if (this.orderLineSteps.findIndex(s => s.stepState === StepState.CREATED_BY_AGENT || s.stepState === StepState.UPDATED_BY_AGENT ||
            s.parallelSupplyChain0.findIndex(pc0 => pc0.stepState === StepState.CREATED_BY_AGENT || pc0.stepState === StepState.UPDATED_BY_AGENT) !== -1 ||
            s.parallelSupplyChain1.findIndex(pc1 => pc1.stepState === StepState.CREATED_BY_AGENT || pc1.stepState === StepState.UPDATED_BY_AGENT) !== -1 ||
            s.parallelSupplyChain2.findIndex(pc2 => pc2.stepState === StepState.CREATED_BY_AGENT || pc2.stepState === StepState.UPDATED_BY_AGENT) !== -1 ||
            s.parallelSupplyChain3.findIndex(pc3 => pc3.stepState === StepState.CREATED_BY_AGENT || pc3.stepState === StepState.UPDATED_BY_AGENT) !== -1 ||
            s.parallelSupplyChain4.findIndex(pc4 => pc4.stepState === StepState.CREATED_BY_AGENT || pc4.stepState === StepState.UPDATED_BY_AGENT) !== -1) !== -1) {
            this.isCustomisedByAgent = true;
        } else {
            this.isCustomisedByAgent = false;
        }
    }

    private showAddRefModalForMc(stepId: number) {
        this.isShowAddRefModal = true;
        this.chainType = 'mainChain';
        const index = this.orderLineSteps.findIndex((s) => s.stepId === stepId);
        this.stepIndex = index;
        this.referenceNumber = '';
    }

    private showAddRefModalForPc(stepId: number, parentStepId: number, indexOfChain: number) {
        this.isShowAddRefModal = true;
        this.chainType = 'parallelChain';
        if (stepId !== -1) {
            const index = this.getParallelChain(
                indexOfChain,
                parentStepId.toString()
            ).findIndex((t) => t.stepId === stepId);
            this.indexOfChain = indexOfChain;
            this.indexOfChainStep = index;
            this.parentStepId = parentStepId;
        }
        this.referenceNumber = ''
    }

    private updateReferenceNumber() {
        const user = this.$store.getters.user as UserModel;
        if (this.chainType === 'mainChain') {
            this.orderLineSteps[this.stepIndex].footprintRefNo = this.referenceNumber;
            if (this.referenceNumber !== '') {
                this.orderLineSteps[this.stepIndex].footprintRefID = uuidv4();
                this.orderLineSteps[this.stepIndex].footprintRefNoUpdatedBy = user.companyId;
            }
        } else {
            this.getParallelChain(this.indexOfChain, this.parentStepId.toString())[
                this.indexOfChainStep
            ].footprintRefNo = this.referenceNumber;
            if (this.referenceNumber !== '') {
                this.getParallelChain(this.indexOfChain, this.parentStepId.toString())[
                    this.indexOfChainStep
                ].footprintRefID = uuidv4();
                this.getParallelChain(this.indexOfChain, this.parentStepId.toString())[
                    this.indexOfChainStep
                ].footprintRefNoUpdatedBy = user.companyId;
            }
            const parentId = this.parentStepId !== null ? Number.parseInt(this.parentStepId.toString()) : null;
            const index = this.orderLineSteps.findIndex((o) => o.stepId === parentId);
            const ele = this.orderLineSteps.filter((o) => o.stepId === parentId);
            this.orderLineSteps.splice(index, 1);
            this.orderLineSteps.splice(index, 0, ele[0]);
        }
        this.isShowAddRefModal = false;
    }

    private filterInput(event: Event) {
        const input = event.target as HTMLInputElement;
        const filteredValue = input.value.replace(/[^a-zA-Z0-9.,+/&-]/g, '');
        input.value = filteredValue;
        this.referenceNumber = filteredValue;
    }

    @Emit()
    private updateSteps(): void {
    }

    @Emit()
    private close(edited: boolean, isdataChanged: boolean): void {
    }

    @Emit()
    private ordersConfirmed(order: CombinedOrderLineModel): void {
    }

    @Emit()
    private suppliersChanged(): void {
    }

    @Emit()
    private updateAgentDetails(isAgentUpdated: boolean, isOrderLineStepsUpdated: boolean): void {
    }
}
