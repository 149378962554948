import { Vue, Component, Prop, Emit, Watch } from "vue-property-decorator";
import { ValidationObserver } from "vee-validate";
import store from '@/store';
import { mapGetters } from 'vuex';
import { ImageStoreConfguration } from "@/config";
import lodash from "lodash";
import { debounce } from "ts-debounce";
import i18n from "@/i18n";
import _ from "lodash";

// Components
import Multiselect from '@/components/input/multiselect.vue';
import AddNextSteps from '@/views/profile/components/addNextSteps.vue';
import PreviewNextSteps from '@/views/profile/components/previewNextSteps.vue';
import AddProductFootprintDesc from '@/views/profile/components/addProductFootprintDesc.vue';
import DropDownEditable from "@/components/general/drop-down-editable/dropDownEditable.vue";

// Helpers
import { CompanyFormDropdownHelper } from "@/helpers/companyFormDropdownHelper";
import { NotificationHelper } from '@/helpers/notificationHelper';
import { CryptionHelper } from '@/helpers/cryptionHelper';
import { CountryHelper } from "@/helpers/countryHelper";
import { FileHelper } from "@/helpers/fileHelper";

// Services
import { InvitationService } from "@/services/invitationService";
import { UserService } from "@/services/userService";
import { ClientService } from "@/services/clientService";
import { MiddlewareService } from '@/services/middlewareService';
import { CountryStateCityService } from '@/services/countryStateCityService';

// Models
import { AddressModel } from "@/models/addressModel";
import { CompanyModel, companyResponseModel } from "@/models/companyModel";
import { ContactModel } from "@/models/contactModel";
import { DropdownModel } from "@/models/dropdownModel";
import { UserModel } from "@/models/userModel";
import { TopBrandModel } from "@/models/topBrandModel";
import { FileModel } from "@/models/fileModel";
import { CompanyAdminRequestModel } from "@/models/createCompanyUsersModel";
import { SigninClientModel } from "@/models/signinClientModel";

@Component({
    components: { Multiselect, AddNextSteps, AddProductFootprintDesc, PreviewNextSteps, DropDownEditable },
    computed: {
        ...mapGetters(['userRole', 'companyType', 'encryptPwd']),
    }
})
export default class CompanyForm extends Vue {

    private clientService: ClientService;

    private cscService: CountryStateCityService;

    private invitationService: InvitationService;

    private userService: UserService;

    private middlewareService: MiddlewareService;

    private cryptionHelper: CryptionHelper;

    private admin: ContactModel = new ContactModel();

    private contact: ContactModel = new ContactModel();

    private company: CompanyModel = new CompanyModel();

    private companyClone: CompanyModel = new CompanyModel();

    private allCategories: DropdownModel[] = [];

    private allProductGroups: DropdownModel[] = [];

    private companyTypes: DropdownModel[] = [];

    private companySubtypes: DropdownModel[] = [];

    private countries: DropdownModel[] = [];

    private officeAddress: AddressModel = new AddressModel();

    private isLoading: boolean = false;

    private isSaving: boolean = false;

    private updateMode: boolean = false;

    private isValid: boolean = true;

    private brandIndex: number = -1;

    private productFootprintDesc: string = "";

    private isShowAddProductFootprintDesc: boolean = false;

    private brandDetails: TopBrandModel = new TopBrandModel("", "", false, null, "", null, "", "", "", [], "");

    private isShowPreviewNextSteps: boolean = false;

    private isShowAddNextSteps: boolean = false;

    private topBrands: TopBrandModel[] = [];

    private imageBaseUrl: string = ImageStoreConfguration.baseUrl;

    private companyName: string = "";

    private invitationId: string | null = "";

    private changedSettingsCounter: number = 0;

    private longitude: number = 0;

    private latitude: number = 0;

    private isAdmin: boolean = true;

    private userRole!: string;

    private companyType!: number;

    private profileSave: string = "FIRST";

    private encryptPwd!: string;

    private countriesDropdown: DropdownModel[] = [];

    private statesDropdown: DropdownModel[] = [];
    private citiesDropdown: DropdownModel[] = [];

    private city: string = "";
    private cityError: boolean = false;

    private state: string = "";
    private stateError: boolean = false;

    private country: string = "";
    private countryCode: string = "";
    private countryError: boolean = false;
    private isAddressValid: boolean = true;

    private checkFieldsDebounced: any;
    private firstNameError: boolean = false;
    private lastNameError: boolean = false;
    private fNameSpecCharErr: boolean = false;

    public constructor() {
        super();
        this.clientService = new ClientService();
        this.invitationService = new InvitationService();
        this.userService = new UserService();
        this.cryptionHelper = new CryptionHelper();
        this.middlewareService = new MiddlewareService();
        this.cscService = new CountryStateCityService();
    }

    private get types(): DropdownModel[] {
        return this.companyTypes;
    }

    private get subtypes(): DropdownModel[] {
        return this.companySubtypes;
    }

    private get productGroups(): DropdownModel[] {
        return this.allProductGroups;
    }

    private get categories(): DropdownModel[] {
        return this.allCategories;
    }

    private async created(): Promise<void> {
        try {
            this.countriesDropdown = [];
            this.countriesDropdown = _.orderBy(CountryHelper.getCountryList(), ['text'], ['asc']);

            this.checkFieldsDebounced = debounce(this.validateField, 250, { maxWait: 250, isImmediate: true });
            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
            this.isLoading = true;
            this.companyTypes = CompanyFormDropdownHelper.getCompanyTypes();
            this.companySubtypes = CompanyFormDropdownHelper.getCompanySubtypes();
            this.allCategories = CompanyFormDropdownHelper.getCategories();
            this.allProductGroups = CompanyFormDropdownHelper.getProductGroups();
            this.countries = CountryHelper.getCountryList();

            this.invitationId = localStorage.getItem("invitationId");
            if (this.invitationId) {
              if (store.getters.company != null) {
                this.company = store.getters.company;
              } else {
                this.company = await this.invitationService.getClientByInvitationIdAsync(this.invitationId);
              }
            } else {
              this.updateMode = true;
              if (this.$store.getters.company != null) {
                  this.company = this.$store.getters.company;
              }
              else {
                  this.company = await this.clientService.getClientAsync();
              }
            }
            this.admin = this.company.admin;
            this.contact = this.company.contact;
            this.officeAddress = this.company.officeAddress != null ? this.company.officeAddress! : new AddressModel();
            this.topBrands = this.company.topBrands;
            this.officeAddress.isOfficeAddress = true;
            this.companyName = this.company.companyName;
            this.isUserAdmin();
            if (this.topBrands == null || this.topBrands.length === 0) {
                this.topBrands = [new TopBrandModel("", "", false, null, "", null, "", "", "", [], "")];
            } else {
                this.topBrands.forEach(b => b.disabled = true);
            }
            this.getCountryInFull();
        } finally {
            this.companyClone = lodash.cloneDeep(this.company);
            this.isLoading = false;
        }
    }

    private getCountryInFull(): void {
        if (this.officeAddress.country !== null || this.officeAddress.country !== '') {
            this.countries.forEach((c) => {
              if (c.value == this.officeAddress.country) {
                this.officeAddress.countryInFull = c.text;
              }
            });
          } else {
            this.officeAddress.countryInFull = '';
          }
    }

    private async validateField(event: any, fieldname: string): Promise<void> {
        const cityDropdown = this.$refs.cityDropdown as any;
        const stateDropdown = this.$refs.stateDropdown as any;
        let matches;
        var specials=/[@()[\];:<>, ]/;
        switch (fieldname) {
          
          case "firstName":
            if (event.length < 2) {
                this.firstNameError = true;
            } else {
                this.firstNameError = false;
            }
            // TTD-4477, for adding validations on firstname
            if (specials.test(event) && !(event.length < 2)) {
                this.fNameSpecCharErr = true;
            } else {
                this.fNameSpecCharErr = false;
            }
          break;

          case "lastName":
            if (event.length < 2) {
                this.lastNameError = true;
              } else {
                this.lastNameError = false;
              }
          break;
          
          case "country":
            this.country = "";
            this.officeAddress.country = "";
            this.officeAddress.countryInFull = "";
            this.countryError = (event.fieldKey.length < 2) ? true : false;

            this.statesDropdown = [{ value: '', text: i18n.t('pages.invitation.loading').toString() }];
            stateDropdown.clearValues();
            this.state = "";
            this.officeAddress.state = "";

            this.citiesDropdown = [];
            cityDropdown.clearValues();
            this.city = "";
            this.officeAddress.city = "";

            if (!this.countryError) {
              const countriesList = await this.cscService.getAllCountries();
              const country = countriesList.find((x: { iso2: any; }) => x.iso2 == event.fieldKey);
              if (country !== undefined) {
                this.country = event.fieldKey;
                this.officeAddress.country = event.fieldKey;
                const states = await this.cscService.getStatesOfCountry(this.country);
                this.statesDropdown = [];
                if (states !== undefined) {
                  states.forEach((state: { iso2: string; name: string; }) => {
                    this.statesDropdown.push({ value: state.iso2, text: state.name });
                  });
                  this.statesDropdown = _.orderBy(this.statesDropdown, ['text'], ['asc']);
                }
                if (!states || states.length == 0) {
                  this.statesDropdown = [{ value: '', text: i18n.t('errors.no_records_found').toString() }];
                }
              } else {
                this.statesDropdown = [];
              }
            } else {
              this.statesDropdown = [];
            }
            break;

          case "state":
            this.citiesDropdown = [{ value: '', text: i18n.t('pages.invitation.loading').toString() }];
            cityDropdown.clearValues();
            this.city = "";

            this.state = "";
            this.officeAddress.city = "";
            this.officeAddress.state = "";
            this.stateError = (event.fieldValue.length < 2 || event.fieldValue == i18n.t('errors.no_records_found').toString() || event.fieldValue == i18n.t('pages.invitation.loading').toString()) ? true : false;

            if (!this.stateError) {
              this.state = event.fieldValue;
              this.officeAddress.state = event.fieldValue;
              if (event.fieldKey && event.fieldKey.length > 0) {
                const cities = await this.cscService.getCitiesOfState(this.country, event.fieldKey);
                this.citiesDropdown = [];
                if (cities !== undefined) {
                  cities.forEach((city: { id: any; name: any; }) => {
                    this.citiesDropdown.push({ value: city.id, text: city.name });
                  });
                  this.citiesDropdown = _.orderBy(this.citiesDropdown, ['text'], ['asc']);
                }
              } else {
                this.citiesDropdown = [];
              }
            } else {
              this.citiesDropdown = [];
            }
            break;

          case "city":
            this.city = "";
            this.officeAddress.city = "";
            this.cityError = (event.fieldValue.length < 2 || event.fieldValue == i18n.t('errors.no_records_found').toString() || event.fieldValue == i18n.t('pages.invitation.loading').toString()) ? true : false;
            if (!this.cityError) {
              this.city = event.fieldValue;
              this.officeAddress.city = event.fieldValue;
            }
            break;

          default:
            break;
        }
        if (!this.addressValid) {
            this.isAddressValid = false;
        } else {
            this.isAddressValid = true;
        }
        this.compareCompanyClone();
      }

    // TTD-4477, for adding validations on firstname
    private get relatedValidations(): boolean {
        return !this.isAddressValid || this.firstNameError || this.fNameSpecCharErr || this.lastNameError;
    }

    private isUserAdmin(): void {
        if(this.userRole === 'Editor' || this.userRole === 'Reader'){
            this.isAdmin = false;
        }
        else{
            this.isAdmin = true;
        }
    }

    private setAdminInformation(): void {
      const user = this.$store.getters.user as UserModel;
      this.admin.firstName = user.firstName;
      this.admin.lastName = user.lastName;
      this.admin.email = user.email;
    }

    private categorySelected(categories: number[]): void {
        this.company.categories = categories;
        this.compareCompanyClone();
    }

    private productGroupSelected(productGroups: number[]): void {
        this.company.productGroups = productGroups;
        this.compareCompanyClone();
    }

    private async addBrand(): Promise<void> {
        this.topBrands.push(new TopBrandModel("", "", false, null, "", null, "", "", "", [], ""));
        await this.processBrandsAsync();
        this.compareCompanyClone();
    }

    private async removeBrand(index: number): Promise<void> {
        this.topBrands.splice(index, 1);
        await this.processBrandsAsync();
        this.compareCompanyClone();
    }

    private removeLogo(brand: TopBrandModel): void {
        brand.brandLogoUrl = "";
        this.compareCompanyClone();
    }

    private get addressValid(): boolean {
        if (this.officeAddress.line1 === "" || this.officeAddress.postal === ""
                || this.officeAddress.city === "" || this.officeAddress.country === "" || this.officeAddress.state === "") {
            if(this.officeAddress.country === ""){
                this.countryError = true;
            } else {
                this.countryError = false;
            }
            if (this.officeAddress.city === "") {
                this.cityError = true;
            } else {
                this.cityError = false;
            }
            if (this.officeAddress.state === "") {
                this.stateError = true;
            } else {
                this.stateError = false;
            }
            return false;
        } else {
            this.cityError = false;
            this.stateError = false;
            this.countryError = false;
            return true;
        }
    }

    private async save(): Promise<void> {
        try {
            const observer = this.$refs.observer as InstanceType<typeof ValidationObserver>;
            let valid = await observer.validate();
            if (this.company.companyType === 0) {
                valid = false;
            }
            if (!this.addressValid) {
                this.isAddressValid = false;
                return;
            }
            if (!valid) {
                this.isValid = false;
                return;
            }
            this.contact.phone = this.contact.phone.trim();
            if(this.contact.phone.length < 10){
                return;
            }

            this.contact.firstName = this.contact.firstName.trim();
            this.contact.lastName = this.contact.lastName.trim();
            this.contact.email = this.contact.email.trim();

            this.admin.firstName = this.admin.firstName.trim();
            this.admin.lastName = this.admin.lastName.trim();
            this.admin.email = this.admin.email.trim();
            this.admin.phone = this.admin.phone.trim();
            this.officeAddress.line1 = this.officeAddress.line1.trim();
            this.officeAddress.line2 = this.officeAddress.line2.trim();
            this.officeAddress.postal = this.officeAddress.postal.trim();
            this.officeAddress.city = this.officeAddress.city.trim();
            this.officeAddress.state = this.officeAddress.state.trim();
            this.officeAddress.country = this.officeAddress.country.trim();

            this.company.companyName = this.company.companyName.trim();

            this.isSaving = true;
            this.company.admin = this.admin;
            this.company.contact = this.contact;
            this.company.officeAddress = this.officeAddress;
            if(this.companyType !== 5){
              await this.processBrandsAsync();
            }
            else{
                this.company.topBrands = [];
            }

            if (this.company.officeAddress.line1 === "" || this.company.officeAddress.postal === ""
                || this.company.officeAddress.city === "" || this.company.officeAddress.country === "" || this.company.officeAddress.state === "") {
                this.company.officeAddress = undefined;
            }

            const invitationId = localStorage.getItem("invitationId");

            // check if company name has been changed
            if (this.company.companyName !== this.companyName) {
                const user = JSON.parse(
                    localStorage.getItem("user") || "{}"
                );
                user.companyName = this.company.companyName;
                user.companyType = this.company.companyType;
                localStorage.setItem("user", JSON.stringify(user));
                this.company.companyNameUpdated = true;
            } else {
                this.company.companyNameUpdated = false;
            }
            if(this.profileSave === "FAIL"){
                const user = new CompanyAdminRequestModel();
                user.Firstname = this.company.admin.firstName.trim();
                user.Lastname = this.company.admin.lastName.trim();
                user.Email = this.company.admin.email;
                user.Password = await this.cryptionHelper.decryptText(this.encryptPwd);

                const result = await this.middlewareService.createCompanyAdmin(user);
                if (result.result === "success") {
                    const client = new SigninClientModel();
                    client.Email = user.Email.trim();
                    client.Password = user.Password.trim();

                    const response = await this.middlewareService.signinClient(client);
                    if(response.response_type === 'success'){
                        sessionStorage.setItem("access_token", response.access_token);
                    }
                }
            }
            let response = new companyResponseModel();
            if (invitationId) {
                response = await this.invitationService.updateClientAsync(invitationId, this.company);
            }
            else {
                response = await this.clientService.updateClientAsync(this.company);
            }
            if (response.result === "success" && response.responseObject.companyName!=='') {
                store.commit("setCanNavigate", true);
                store.commit('setMyAccount', response.responseObject);
                store.commit("setEncryptedPwd", '');
                this.profileSave = "SUCCESS";
                const user = JSON.parse(
                    localStorage.getItem("user") || "{}"
                );
                user.companyType = response.responseObject.companyType;
                localStorage.setItem("user", JSON.stringify(user));
                await this.userService.loadUserInfoAsync(false);
                this.$router.push({ name: 'Home' });
            }
            else if(response.result === "fail" && response.message == "Error while saving account. Please try again with invitaion link") {
                NotificationHelper.createErrorNotification("Your account update was not saved due to technical issues. Please click ‘Save’ again to successfully save your account details.");
                this.profileSave = "FAIL";
            }else if(response.result === "fail" && response.message == "Property 'MaxHeight' exceeds threshold '600'"){
                NotificationHelper.createErrorNotification(i18n.t("errors.image_requirement_violated", ["MaxHeight", "600"]).toString());
                this.profileSave = "FAIL";
            }
        }
        finally {
            this.isSaving = false;
        }
    }

    private async processBrandsAsync(): Promise<void> {
        for (let i = 0; i < this.topBrands.length; i++) {
            const b = this.topBrands[i];
            if (b.logoFile != null) {
                const documentAsBase64 = await FileHelper.getBase64(b.logoFile) as string;
                this.topBrands[i].brandLogo = new FileModel(b.logoFile.name, b.logoFile.type, documentAsBase64);
            }
            this.topBrands[i].brandLogoUrl = this.topBrands[i].brandLogoUrl.trim();
            this.topBrands[i].brandName = this.topBrands[i].brandName.trim();
            this.topBrands[i].brandURL = this.topBrands[i].brandURL.trim();
            this.topBrands[i].nextStepIntroText = this.topBrands[i].nextStepIntroText.trim();
            this.topBrands[i].nextStepSubtitle = this.topBrands[i].nextStepSubtitle.trim();
            this.topBrands[i].productFootprintDesc = this.topBrands[i].productFootprintDesc.trim();
            this.topBrands[i].stepNote = this.topBrands[i].stepNote.trim();
        }

        this.company.topBrands = this.topBrands;
    }

    private validateCompanyType(): void {
        if (this.company.companyType === 0) {
            this.isValid = false;
        } else {
            this.isValid = true;
        }
        this.compareCompanyClone();
    }

    private get getTopBrands(): TopBrandModel[] {
        return this.topBrands;
    }

    private hasProductFootprintDesc(brand: TopBrandModel): boolean {
        return (brand.productFootprintDesc !== null ? (brand.productFootprintDesc.trim().length > 0 ? true : false) : false);
    }

    private showAddProductFootprintDesc(index: number, productFootprintDesc: string): void {
        this.isShowAddProductFootprintDesc = true;
        this.productFootprintDesc = productFootprintDesc.trim();
        this.brandIndex = index;
    }

    private closeAddProductFootprintDesc(index: number, productFootprintDesc: string): void {
        if (index > -1 && index < this.topBrands.length) {
            const brand = this.topBrands[index];
            brand.productFootprintDesc = productFootprintDesc.trim();
            this.topBrands.splice(index, 1, brand);
        }
        this.brandIndex = -1;
        this.productFootprintDesc = "";
        this.isShowAddProductFootprintDesc = false;
        this.compareCompanyClone();
    }

    private deleteProductFootprintDesc(index: number): void {
        if (index > -1 && index < this.topBrands.length) {
            const brand = this.topBrands[index];
            brand.productFootprintDesc = "";
            this.topBrands.splice(index, 1, brand);
        }
        this.compareCompanyClone();
    }

    private hasBrandSteps(brand: TopBrandModel): boolean {
        return (brand.nextStepSubtitle !== null ? (brand.nextStepSubtitle.trim().length > 0 ? true : false) : false);
    }

    private showAddNextSteps(index: number, brandDetails: TopBrandModel): void {
        this.brandIndex = index;
        brandDetails.brandLogoUrl = brandDetails.brandLogoUrl.trim();
        brandDetails.brandName = brandDetails.brandName.trim();
        brandDetails.brandURL = brandDetails.brandURL.trim();
        brandDetails.nextStepIntroText = brandDetails.nextStepIntroText.trim();
        brandDetails.nextStepSubtitle = brandDetails.nextStepSubtitle.trim();
        brandDetails.productFootprintDesc = brandDetails.productFootprintDesc.trim();
        brandDetails.stepNote = brandDetails.stepNote.trim();
        this.brandDetails = brandDetails;
        this.isShowAddNextSteps = true;
    }

    private cancelNextSteps(): void {
			this.isShowAddNextSteps = false;
			this.compareCompanyClone();
    }

    private closeAddNextSteps(index: number, brand: TopBrandModel): void {
        this.isShowAddNextSteps = false;
        if (index > -1 && index < this.topBrands.length) {
            if (brand.nextStepSubtitle.trim().length > 0 && brand.nextStepIntroText.trim().length > 0) {
                this.topBrands.splice(index, 1, brand);
            }
        }
        this.compareCompanyClone();
    }

    private showPreviewNextSteps(brandDetails: TopBrandModel): void {
        this.isShowPreviewNextSteps = true;
        brandDetails.brandLogoUrl = brandDetails.brandLogoUrl.trim();
        brandDetails.brandName = brandDetails.brandName.trim();
        brandDetails.brandURL = brandDetails.brandURL.trim();
        brandDetails.nextStepIntroText = brandDetails.nextStepIntroText.trim();
        brandDetails.nextStepSubtitle = brandDetails.nextStepSubtitle.trim();
        brandDetails.productFootprintDesc = brandDetails.productFootprintDesc.trim();
        brandDetails.stepNote = brandDetails.stepNote.trim();
        this.brandDetails = brandDetails;
    }

    private closePreviewNextSteps(): void {
        this.isShowPreviewNextSteps = false;
        this.brandDetails = new TopBrandModel("", "", false, null, "", null, "", "", "", [], "");
    }

    private deleteAddNextSteps(index: number): void {
        if (index > -1 && index < this.topBrands.length) {
            const brand = this.topBrands[index];
            brand.nextStepSubtitle = "";
            brand.nextStepIntroText = "";
            brand.steps = [];
            brand.stepNote = "";
            this.topBrands.splice(index, 1, brand);
        }
        this.compareCompanyClone();
    }

    private cancelChanges(): void {
        this.isLoading = true;
        if (this.officeAddress.city!=="null") {
            this.cityError = false;
        }
        if (this.officeAddress.state!=="null") {
            this.stateError = false;
        }
        if (this.officeAddress.country!=="null") {
            this.countryError = false;
        }
        if (this.officeAddress.city!=="null" && this.officeAddress.state!=="null" && this.officeAddress.country!=="null") {
            this.isAddressValid = true;
        }
        this.company = this.companyClone;
        this.contact = this.company.contact;
        this.officeAddress = this.company.officeAddress != null ? this.company.officeAddress! : new AddressModel();
        this.topBrands = this.company.topBrands;
        this.companyClone = lodash.cloneDeep(this.company);
        this.compareCompanyClone();
        setTimeout((app: any) => {
            app.isLoading = false;
        }, 100, this);
    }

    private compareCompanyClone(): void {
      if(this.invitationId === '' || this.invitationId === null || this.invitationId === undefined){
          let changes: number = 0;
          const company: [string, any][] = Object.entries(this.company).sort();
          const companyClone: [string, any][] = Object.entries(this.companyClone).sort();

          const getObjectDiff = (obj1: any, obj2: any) => {
              const obj1Props = Object.keys(obj1);
              const obj2Props = Object.keys(obj2);
              const keysWithDiffValue = obj1Props.reduce((keysWithDiffValueAccumulator, key) => {
                  // eslint-disable-next-line no-prototype-builtins
                  const propExistsOnObj2 = obj2.hasOwnProperty(key);
                  const hasNestedValue = obj1[key] instanceof Object && obj2[key] instanceof Object;
                  const keyValuePairBetweenBothObjectsIsEqual = obj1[key] === obj2[key];

                  if (!propExistsOnObj2) {
                      if (obj1[key] !== null && obj1[key] !== undefined && obj1[key] !== '' && obj1[key] instanceof Object) {
                          if (obj1[key].logoFile !== undefined && obj1[key].logoFile !== null && obj1[key].logoFile !== '') {
                              keysWithDiffValueAccumulator.push(`${key}.1`);
                          }
                          if (obj1[key].brandName !== undefined && obj1[key].brandName !== null && obj1[key].brandName !== '') {
                              keysWithDiffValueAccumulator.push(`${key}.2`);
                          }
                          if (obj1[key].brandURL !== undefined && obj1[key].brandURL !== null && obj1[key].brandURL !== '') {
                              keysWithDiffValueAccumulator.push(`${key}.3`);
                          }

                          if (obj1[key].nextStepSubtitle !== undefined && obj1[key].nextStepSubtitle !== null && obj1[key].nextStepSubtitle !== '') {
                              keysWithDiffValueAccumulator.push(`${key}.4`);
                          }
                          if (obj1[key].nextStepIntroText !== undefined && obj1[key].nextStepIntroText !== null && obj1[key].nextStepIntroText !== '') {
                              keysWithDiffValueAccumulator.push(`${key}.5`);
                          }
                          if (obj1[key].productFootprintDesc !== undefined && obj1[key].productFootprintDesc !== null && obj1[key].productFootprintDesc !== '') {
                              keysWithDiffValueAccumulator.push(`${key}.4`);
                          }
                          if (obj1[key].stepNote !== undefined && obj1[key].stepNote !== null && obj1[key].stepNote !== '') {
                              keysWithDiffValueAccumulator.push(`${key}.5`);
                          }

                          if (obj1[key].steps !== null && obj1[key].steps !== undefined && obj1[key].steps !== '') {
                              for (let i = 0; i < obj1[key].steps.length; i++) {
                                  const obj1steps = obj1[key].steps[i];
                                  if (obj1steps.stepLabel !== undefined && obj1steps.stepLabel !== null && obj1steps.stepLabel !== '') {
                                      keysWithDiffValueAccumulator.push(`${key}.6.1`);
                                  }
                                  if (obj1steps.stepDesc !== undefined && obj1steps.stepDesc !== null && obj1steps.stepDesc !== '') {
                                      keysWithDiffValueAccumulator.push(`${key}.6.2`);
                                  }
                                  if (obj1steps.stepLinkText !== undefined && obj1steps.stepLinkText !== null && obj1steps.stepLinkText !== '') {
                                      keysWithDiffValueAccumulator.push(`${key}.6.3`);
                                  }
                                  if (obj1steps.stepLinkKeyword !== undefined && obj1steps.stepLinkKeyword !== null && obj1steps.stepLinkKeyword !== '') {
                                      keysWithDiffValueAccumulator.push(`${key}.6.4`);
                                  }
                              }
                          }

                      } else {
                          keysWithDiffValueAccumulator.push(key);
                      }
                  } else if (hasNestedValue) {
                      const keyIndex = keysWithDiffValueAccumulator.indexOf(key);
                      if (keyIndex >= 0) {
                        keysWithDiffValueAccumulator.splice(keyIndex, 1);
                      }

                      if (key == "steps" && obj1[key].length > obj2[key].length) {
                        const startFromIndex = obj2[key].length;
                        for (let i = startFromIndex; i < obj1[key].length; i++) {
                          const obj1steps = obj1[key][i];
                          if (obj1steps.stepLabel !== undefined && obj1steps.stepLabel !== null && obj1steps.stepLabel !== '') {
                            keysWithDiffValueAccumulator.push(`${key}.add.step.1`);
                          }
                          if (obj1steps.stepDesc !== undefined && obj1steps.stepDesc !== null && obj1steps.stepDesc !== '') {
                            keysWithDiffValueAccumulator.push(`${key}.add.step.2`);
                          }
                          if (obj1steps.stepLinkText !== undefined && obj1steps.stepLinkText !== null && obj1steps.stepLinkText !== '') {
                            keysWithDiffValueAccumulator.push(`${key}.add.step.3`);
                          }
                          if (obj1steps.stepLinkKeyword !== undefined && obj1steps.stepLinkKeyword !== null && obj1steps.stepLinkKeyword !== '') {
                            keysWithDiffValueAccumulator.push(`${key}.add.step.4`);
                          }
                        }
                      }

                      const nestedDiffs = getObjectDiff(obj1[key], obj2[key]);
                      for (const diff of nestedDiffs) {
                        keysWithDiffValueAccumulator.push(`${key}.${diff}`);
                        if (obj1[key][0] && obj1[key][0] !== undefined && (obj1[key][0] == 'categories' || obj1[key][0] == 'productGroups')) {
                          break;
                        }
                      }

                  } else if (keyValuePairBetweenBothObjectsIsEqual) {
                    const equalValueKeyIndex = keysWithDiffValueAccumulator.indexOf(key);
                    keysWithDiffValueAccumulator.splice(equalValueKeyIndex, 1);
                  }

                  return keysWithDiffValueAccumulator;
              }, obj2Props);

              return keysWithDiffValue;
          };
          const differences = getObjectDiff(company, companyClone);
          changes = differences.length;
          differences.forEach(element => {
              if (element.includes('countryInFull')) {
                  changes = changes-1;
              }
          });
          this.company.topBrands.forEach((brand,index) => {
            if(brand.logoFile !== undefined){
                changes = changes + 1;
            }
          });
          this.changedSettingsCounter = changes;
          this.changedSettings(changes);
      }
    }

    private allowIntegerOnly(): void {
        const regex = /^\d*$/;
        const inputValueString = String(this.company.turnover);
        if (!regex.test(inputValueString)) {
            this.company.turnover =  Number(inputValueString.replace(/[^\d]/g, ''));
        }
    }

    @Emit()
    private changedSettings(value: number): void { }

}