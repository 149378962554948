import i18n from "@/i18n";
import { ClientStepModel } from "@/models/clientStepModel";
import { ProductGroupModel } from "@/models/productGroupModel";

export class StepsHelper {
    // Method return steps based on product group Id
    public static getStepsByProductGroup(productGroupId: number): ClientStepModel[] {
        let steps: ClientStepModel[] = [];
        switch (productGroupId) {
            // FlatKnitGarment
            case 1:
                steps = [
                    {
                        displayName: i18n.t("enums.steps.1").toString(),
                        stepId: 1,
                        sequence: 1,
                        productGroup: 1,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.2").toString(),
                        stepId: 2,
                        sequence: 2,
                        productGroup: 1,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.3").toString(),
                        stepId: 3,
                        sequence: 3,
                        productGroup: 1,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.4").toString(),
                        stepId: 4,
                        sequence: 5,
                        productGroup: 1,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    // removed as per the requirement in TTD-1831
                    // {
                    //     displayName: i18n.t("enums.steps.5").toString(),
                    //     stepId: 5,
                    //     sequence: 0,
                    //     productGroup: 1,
                    //     showDefault: false,
                    // },
                    {
                        displayName: i18n.t("enums.steps.6").toString(),
                        stepId: 6,
                        sequence: 4,
                        productGroup: 1,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.7").toString(),
                        stepId: 7,
                        sequence: 6,
                        productGroup: 1,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.8").toString(),
                        stepId: 8,
                        sequence: 7,
                        productGroup: 1,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.9").toString(),
                        stepId: 9,
                        sequence: 8,
                        productGroup: 1,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.10").toString(),
                        stepId: 10,
                        sequence: 9,
                        productGroup: 1,
                        showDefault: true,
                        parallelChain: [18, 17],
                        showDefaultParallelChain: true
                    },
                    {
                        displayName: i18n.t("enums.steps.11").toString(),
                        stepId: 11,
                        sequence: 10,
                        productGroup: 1,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.12").toString(),
                        stepId: 12,
                        sequence: 11,
                        productGroup: 1,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.13").toString(),
                        stepId: 13,
                        sequence: 12,
                        productGroup: 1,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.14").toString(),
                        stepId: 14,
                        sequence: 13,
                        productGroup: 1,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.15").toString(),
                        stepId: 15,
                        sequence: 14,
                        productGroup: 1,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.16").toString(),
                        stepId: 16,
                        sequence: 15,
                        productGroup: 1,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.17").toString(),
                        stepId: 17,
                        sequence: 16,
                        productGroup: 1,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.18").toString(),
                        stepId: 18,
                        sequence: 17,
                        productGroup: 1,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.19").toString(),
                        stepId: 19,
                        sequence: 18,
                        productGroup: 1,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.20").toString(),
                        stepId: 20,
                        sequence: 19,
                        productGroup: 1,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.21").toString(),
                        stepId: 21,
                        sequence: 20,
                        productGroup: 1,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.22").toString(),
                        stepId: 22,
                        sequence: 21,
                        productGroup: 1,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.23").toString(),
                        stepId: 23,
                        sequence: 22,
                        productGroup: 1,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.24").toString(),
                        stepId: 24,
                        sequence: 23,
                        productGroup: 1,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.25").toString(),
                        stepId: 25,
                        sequence: 24,
                        productGroup: 1,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.26").toString(),
                        stepId: 26,
                        sequence: 25,
                        productGroup: 1,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.27").toString(),
                        stepId: 27,
                        sequence: 26,
                        productGroup: 1,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.28").toString(),
                        stepId: 28,
                        sequence: 27,
                        productGroup: 1,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.29").toString(),
                        stepId: 29,
                        sequence: 28,
                        productGroup: 1,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.30").toString(),
                        stepId: 30,
                        sequence: 29,
                        productGroup: 1,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.31").toString(),
                        stepId: 31,
                        sequence: 30,
                        productGroup: 1,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.32").toString(),
                        stepId: 32,
                        sequence: 31,
                        productGroup: 1,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    }
                ];
                break;

            // CircleKnitGarment
            case 2:
                steps = [
                    {
                        displayName: i18n.t("enums.steps.1").toString(),
                        stepId: 1,
                        sequence: 1,
                        productGroup: 2,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.2").toString(),
                        stepId: 2,
                        sequence: 2,
                        productGroup: 2,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.3").toString(),
                        stepId: 3,
                        sequence: 3,
                        productGroup: 2,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.4").toString(),
                        stepId: 4,
                        sequence: 4,
                        productGroup: 2,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    // removed as per the requirement in TTD-1831
                    // {
                    //     displayName: i18n.t("enums.steps.5").toString(),
                    //     stepId: 5,
                    //     sequence: 0,
                    //     productGroup: 2,
                    //     showDefault: false,
                    // },
                    {
                        displayName: i18n.t("enums.steps.6").toString(),
                        stepId: 6,
                        sequence: 5,
                        productGroup: 2,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.7").toString(),
                        stepId: 7,
                        sequence: 6,
                        productGroup: 2,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.8").toString(),
                        stepId: 8,
                        sequence: 7,
                        productGroup: 2,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.9").toString(),
                        stepId: 9,
                        sequence: 8,
                        productGroup: 2,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.10").toString(),
                        stepId: 10,
                        sequence: 9,
                        productGroup: 2,
                        showDefault: true,
                        parallelChain: [18, 17],
                        showDefaultParallelChain: true
                    },
                    {
                        displayName: i18n.t("enums.steps.11").toString(),
                        stepId: 11,
                        sequence: 10,
                        productGroup: 2,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.12").toString(),
                        stepId: 12,
                        sequence: 11,
                        productGroup: 2,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.13").toString(),
                        stepId: 13,
                        sequence: 12,
                        productGroup: 2,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.14").toString(),
                        stepId: 14,
                        sequence: 13,
                        productGroup: 2,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.15").toString(),
                        stepId: 15,
                        sequence: 14,
                        productGroup: 2,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.16").toString(),
                        stepId: 16,
                        sequence: 15,
                        productGroup: 2,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.17").toString(),
                        stepId: 17,
                        sequence: 16,
                        productGroup: 2,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.18").toString(),
                        stepId: 18,
                        sequence: 17,
                        productGroup: 2,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.19").toString(),
                        stepId: 19,
                        sequence: 18,
                        productGroup: 2,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.20").toString(),
                        stepId: 20,
                        sequence: 19,
                        productGroup: 2,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.21").toString(),
                        stepId: 21,
                        sequence: 20,
                        productGroup: 2,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.22").toString(),
                        stepId: 22,
                        sequence: 21,
                        productGroup: 2,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.23").toString(),
                        stepId: 23,
                        sequence: 22,
                        productGroup: 2,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.24").toString(),
                        stepId: 24,
                        sequence: 23,
                        productGroup: 2,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.25").toString(),
                        stepId: 25,
                        sequence: 24,
                        productGroup: 2,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.26").toString(),
                        stepId: 26,
                        sequence: 25,
                        productGroup: 2,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.27").toString(),
                        stepId: 27,
                        sequence: 26,
                        productGroup: 2,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.28").toString(),
                        stepId: 28,
                        sequence: 27,
                        productGroup: 2,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.29").toString(),
                        stepId: 29,
                        sequence: 28,
                        productGroup: 2,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.30").toString(),
                        stepId: 30,
                        sequence: 29,
                        productGroup: 2,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.31").toString(),
                        stepId: 31,
                        sequence: 30,
                        productGroup: 2,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.32").toString(),
                        stepId: 32,
                        sequence: 31,
                        productGroup: 2,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    }
                ];
                break;

            // WovenGarment
            case 3:
                steps = [
                    {
                        displayName: i18n.t("enums.steps.1").toString(),
                        stepId: 1,
                        sequence: 1,
                        productGroup: 3,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.2").toString(),
                        stepId: 2,
                        sequence: 2,
                        productGroup: 3,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.3").toString(),
                        stepId: 3,
                        sequence: 3,
                        productGroup: 3,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    // removed as per the requirement in TTD-1831
                    // {
                    //     displayName: i18n.t("enums.steps.4").toString(),
                    //     stepId: 4,
                    //     sequence: 0,
                    //     productGroup: 3,
                    //     showDefault: false,
                    // },
                    {
                        displayName: i18n.t("enums.steps.5").toString(),
                        stepId: 5,
                        sequence: 4,
                        productGroup: 3,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.6").toString(),
                        stepId: 6,
                        sequence: 5,
                        productGroup: 3,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.7").toString(),
                        stepId: 7,
                        sequence: 6,
                        productGroup: 3,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.8").toString(),
                        stepId: 8,
                        sequence: 7,
                        productGroup: 3,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.9").toString(),
                        stepId: 9,
                        sequence: 8,
                        productGroup: 3,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.10").toString(),
                        stepId: 10,
                        sequence: 9,
                        productGroup: 3,
                        showDefault: true,
                        parallelChain: [18, 17],
                        showDefaultParallelChain: true
                    },
                    {
                        displayName: i18n.t("enums.steps.11").toString(),
                        stepId: 11,
                        sequence: 10,
                        productGroup: 3,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.12").toString(),
                        stepId: 12,
                        sequence: 11,
                        productGroup: 3,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.13").toString(),
                        stepId: 13,
                        sequence: 12,
                        productGroup: 3,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.14").toString(),
                        stepId: 14,
                        sequence: 13,
                        productGroup: 3,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.15").toString(),
                        stepId: 15,
                        sequence: 14,
                        productGroup: 3,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.16").toString(),
                        stepId: 16,
                        sequence: 15,
                        productGroup: 3,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.17").toString(),
                        stepId: 17,
                        sequence: 16,
                        productGroup: 3,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.18").toString(),
                        stepId: 18,
                        sequence: 17,
                        productGroup: 3,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.19").toString(),
                        stepId: 19,
                        sequence: 18,
                        productGroup: 3,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.20").toString(),
                        stepId: 20,
                        sequence: 19,
                        productGroup: 3,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.21").toString(),
                        stepId: 21,
                        sequence: 20,
                        productGroup: 3,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.22").toString(),
                        stepId: 22,
                        sequence: 21,
                        productGroup: 3,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.23").toString(),
                        stepId: 23,
                        sequence: 22,
                        productGroup: 3,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.24").toString(),
                        stepId: 24,
                        sequence: 23,
                        productGroup: 3,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.25").toString(),
                        stepId: 25,
                        sequence: 24,
                        productGroup: 3,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.26").toString(),
                        stepId: 26,
                        sequence: 25,
                        productGroup: 3,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.27").toString(),
                        stepId: 27,
                        sequence: 26,
                        productGroup: 3,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.28").toString(),
                        stepId: 28,
                        sequence: 27,
                        productGroup: 3,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.29").toString(),
                        stepId: 29,
                        sequence: 28,
                        productGroup: 3,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.30").toString(),
                        stepId: 30,
                        sequence: 29,
                        productGroup: 3,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.31").toString(),
                        stepId: 31,
                        sequence: 30,
                        productGroup: 3,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.32").toString(),
                        stepId: 32,
                        sequence: 31,
                        productGroup: 3,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    }
                ];
                break;

            // Denim
            case 4:
                steps = [
                    {
                        displayName: i18n.t("enums.steps.1").toString(),
                        stepId: 1,
                        sequence: 1,
                        productGroup: 4,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.2").toString(),
                        stepId: 2,
                        sequence: 2,
                        productGroup: 4,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.3").toString(),
                        stepId: 3,
                        sequence: 3,
                        productGroup: 4,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    // removed as per the requirement in TTD-1831
                    // {
                    //     displayName: i18n.t("enums.steps.4").toString(),
                    //     stepId: 4,
                    //     sequence: 0,
                    //     productGroup: 4,
                    //     showDefault: false,
                    // },
                    {
                        displayName: i18n.t("enums.steps.5").toString(),
                        stepId: 5,
                        sequence: 4,
                        productGroup: 4,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.6").toString(),
                        stepId: 6,
                        sequence: 5,
                        productGroup: 4,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.7").toString(),
                        stepId: 7,
                        sequence: 6,
                        productGroup: 4,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.8").toString(),
                        stepId: 8,
                        sequence: 7,
                        productGroup: 4,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.9").toString(),
                        stepId: 9,
                        sequence: 8,
                        productGroup: 4,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.10").toString(),
                        stepId: 10,
                        sequence: 9,
                        productGroup: 4,
                        showDefault: true,
                        parallelChain: [18, 17],
                        showDefaultParallelChain: true
                    },
                    {
                        displayName: i18n.t("enums.steps.11").toString(),
                        stepId: 11,
                        sequence: 10,
                        productGroup: 4,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.12").toString(),
                        stepId: 12,
                        sequence: 11,
                        productGroup: 4,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.13").toString(),
                        stepId: 13,
                        sequence: 12,
                        productGroup: 4,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.14").toString(),
                        stepId: 14,
                        sequence: 13,
                        productGroup: 4,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.15").toString(),
                        stepId: 15,
                        sequence: 14,
                        productGroup: 4,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.16").toString(),
                        stepId: 16,
                        sequence: 15,
                        productGroup: 4,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.17").toString(),
                        stepId: 17,
                        sequence: 16,
                        productGroup: 4,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.18").toString(),
                        stepId: 18,
                        sequence: 17,
                        productGroup: 4,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.19").toString(),
                        stepId: 19,
                        sequence: 18,
                        productGroup: 4,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.20").toString(),
                        stepId: 20,
                        sequence: 19,
                        productGroup: 4,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.21").toString(),
                        stepId: 21,
                        sequence: 20,
                        productGroup: 4,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.22").toString(),
                        stepId: 22,
                        sequence: 21,
                        productGroup: 4,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.23").toString(),
                        stepId: 23,
                        sequence: 22,
                        productGroup: 4,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.24").toString(),
                        stepId: 24,
                        sequence: 23,
                        productGroup: 4,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.25").toString(),
                        stepId: 25,
                        sequence: 24,
                        productGroup: 4,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.26").toString(),
                        stepId: 26,
                        sequence: 25,
                        productGroup: 4,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.27").toString(),
                        stepId: 27,
                        sequence: 26,
                        productGroup: 4,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.28").toString(),
                        stepId: 28,
                        sequence: 27,
                        productGroup: 4,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.29").toString(),
                        stepId: 29,
                        sequence: 28,
                        productGroup: 4,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.30").toString(),
                        stepId: 30,
                        sequence: 29,
                        productGroup: 4,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.31").toString(),
                        stepId: 31,
                        sequence: 30,
                        productGroup: 4,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.32").toString(),
                        stepId: 32,
                        sequence: 31,
                        productGroup: 4,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    }
                ];
                break;

            // Outerwear
            case 5:
                steps = [
                    {
                        displayName: i18n.t("enums.steps.1").toString(),
                        stepId: 1,
                        sequence: 1,
                        productGroup: 5,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.2").toString(),
                        stepId: 2,
                        sequence: 2,
                        productGroup: 5,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.3").toString(),
                        stepId: 3,
                        sequence: 3,
                        productGroup: 5,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.4").toString(),
                        stepId: 4,
                        sequence: 4,
                        productGroup: 5,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.5").toString(),
                        stepId: 5,
                        sequence: 5,
                        productGroup: 5,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.6").toString(),
                        stepId: 6,
                        sequence: 6,
                        productGroup: 5,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.7").toString(),
                        stepId: 7,
                        sequence: 7,
                        productGroup: 5,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.8").toString(),
                        stepId: 8,
                        sequence: 8,
                        productGroup: 5,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.9").toString(),
                        stepId: 9,
                        sequence: 9,
                        productGroup: 5,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.10").toString(),
                        stepId: 10,
                        sequence: 10,
                        productGroup: 5,
                        showDefault: true,
                        parallelChain: [18, 17],
                        showDefaultParallelChain: true
                    },
                    {
                        displayName: i18n.t("enums.steps.11").toString(),
                        stepId: 11,
                        sequence: 11,
                        productGroup: 5,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.12").toString(),
                        stepId: 12,
                        sequence: 12,
                        productGroup: 5,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.13").toString(),
                        stepId: 13,
                        sequence: 13,
                        productGroup: 5,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.14").toString(),
                        stepId: 14,
                        sequence: 14,
                        productGroup: 5,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.15").toString(),
                        stepId: 15,
                        sequence: 15,
                        productGroup: 5,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.16").toString(),
                        stepId: 16,
                        sequence: 16,
                        productGroup: 5,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.17").toString(),
                        stepId: 17,
                        sequence: 17,
                        productGroup: 5,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.18").toString(),
                        stepId: 18,
                        sequence: 18,
                        productGroup: 5,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.19").toString(),
                        stepId: 19,
                        sequence: 19,
                        productGroup: 5,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.20").toString(),
                        stepId: 20,
                        sequence: 20,
                        productGroup: 5,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.21").toString(),
                        stepId: 21,
                        sequence: 21,
                        productGroup: 5,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.22").toString(),
                        stepId: 22,
                        sequence: 22,
                        productGroup: 5,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.23").toString(),
                        stepId: 23,
                        sequence: 23,
                        productGroup: 5,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.24").toString(),
                        stepId: 24,
                        sequence: 24,
                        productGroup: 5,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.25").toString(),
                        stepId: 25,
                        sequence: 25,
                        productGroup: 5,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.26").toString(),
                        stepId: 26,
                        sequence: 26,
                        productGroup: 5,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.27").toString(),
                        stepId: 27,
                        sequence: 27,
                        productGroup: 5,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.28").toString(),
                        stepId: 28,
                        sequence: 28,
                        productGroup: 5,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.29").toString(),
                        stepId: 29,
                        sequence: 29,
                        productGroup: 5,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.30").toString(),
                        stepId: 30,
                        sequence: 30,
                        productGroup: 5,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.31").toString(),
                        stepId: 31,
                        sequence: 31,
                        productGroup: 5,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.32").toString(),
                        stepId: 32,
                        sequence: 32,
                        productGroup: 5,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    }
                ];
                break;

            // Lingerie
            case 6:
                steps = [
                    {
                        displayName: i18n.t("enums.steps.1").toString(),
                        stepId: 1,
                        sequence: 12,
                        productGroup: 6,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.2").toString(),
                        stepId: 2,
                        sequence: 13,
                        productGroup: 6,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.3").toString(),
                        stepId: 3,
                        sequence: 2,
                        productGroup: 6,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.4").toString(),
                        stepId: 4,
                        sequence: 3,
                        productGroup: 6,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.5").toString(),
                        stepId: 5,
                        sequence: 14,
                        productGroup: 6,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.6").toString(),
                        stepId: 6,
                        sequence: 4,
                        productGroup: 6,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.7").toString(),
                        stepId: 7,
                        sequence: 5,
                        productGroup: 6,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.8").toString(),
                        stepId: 8,
                        sequence: 6,
                        productGroup: 6,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.9").toString(),
                        stepId: 9,
                        sequence: 7,
                        productGroup: 6,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.10").toString(),
                        stepId: 10,
                        sequence: 8,
                        productGroup: 6,
                        showDefault: true,
                        parallelChain: [18, 17],
                        showDefaultParallelChain: true
                    },
                    {
                        displayName: i18n.t("enums.steps.11").toString(),
                        stepId: 11,
                        sequence: 9,
                        productGroup: 6,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.12").toString(),
                        stepId: 12,
                        sequence: 10,
                        productGroup: 6,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.13").toString(),
                        stepId: 13,
                        sequence: 11,
                        productGroup: 6,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.14").toString(),
                        stepId: 14,
                        sequence: 15,
                        productGroup: 6,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.15").toString(),
                        stepId: 15,
                        sequence: 1,
                        productGroup: 6,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.16").toString(),
                        stepId: 16,
                        sequence: 16,
                        productGroup: 6,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.17").toString(),
                        stepId: 17,
                        sequence: 17,
                        productGroup: 6,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.18").toString(),
                        stepId: 18,
                        sequence: 18,
                        productGroup: 6,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.19").toString(),
                        stepId: 19,
                        sequence: 19,
                        productGroup: 6,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.20").toString(),
                        stepId: 20,
                        sequence: 20,
                        productGroup: 6,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.21").toString(),
                        stepId: 21,
                        sequence: 21,
                        productGroup: 6,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.22").toString(),
                        stepId: 22,
                        sequence: 22,
                        productGroup: 6,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.23").toString(),
                        stepId: 23,
                        sequence: 23,
                        productGroup: 6,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.24").toString(),
                        stepId: 24,
                        sequence: 24,
                        productGroup: 6,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.25").toString(),
                        stepId: 25,
                        sequence: 25,
                        productGroup: 6,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.26").toString(),
                        stepId: 26,
                        sequence: 26,
                        productGroup: 6,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.27").toString(),
                        stepId: 27,
                        sequence: 27,
                        productGroup: 6,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.28").toString(),
                        stepId: 28,
                        sequence: 28,
                        productGroup: 6,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.29").toString(),
                        stepId: 29,
                        sequence: 29,
                        productGroup: 6,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.30").toString(),
                        stepId: 30,
                        sequence: 30,
                        productGroup: 6,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.31").toString(),
                        stepId: 31,
                        sequence: 31,
                        productGroup: 6,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.32").toString(),
                        stepId: 32,
                        sequence: 32,
                        productGroup: 6,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    }
                ];
                break;

            // Bags
            case 7:
                steps = [
                    {
                        displayName: i18n.t("enums.steps.1").toString(),
                        stepId: 1,
                        sequence: 13,
                        productGroup: 7,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.2").toString(),
                        stepId: 2,
                        sequence: 14,
                        productGroup: 7,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.3").toString(),
                        stepId: 3,
                        sequence: 2,
                        productGroup: 7,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.4").toString(),
                        stepId: 4,
                        sequence: 15,
                        productGroup: 7,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.5").toString(),
                        stepId: 5,
                        sequence: 3,
                        productGroup: 7,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.6").toString(),
                        stepId: 6,
                        sequence: 4,
                        productGroup: 7,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.7").toString(),
                        stepId: 7,
                        sequence: 16,
                        productGroup: 7,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.8").toString(),
                        stepId: 8,
                        sequence: 5,
                        productGroup: 7,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.9").toString(),
                        stepId: 9,
                        sequence: 6,
                        productGroup: 7,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    // removed as per the requirement in TTD-1831
                    // {
                    //     displayName: i18n.t("enums.steps.10").toString(),
                    //     stepId: 10,
                    //     sequence: 7,
                    //     productGroup: 7,
                    //     showDefault: true,
                    // },
                    {
                        displayName: i18n.t("enums.steps.11").toString(),
                        stepId: 11,
                        sequence: 10,
                        productGroup: 7,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.12").toString(),
                        stepId: 12,
                        sequence: 11,
                        productGroup: 7,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.13").toString(),
                        stepId: 13,
                        sequence: 12,
                        productGroup: 7,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.14").toString(),
                        stepId: 14,
                        sequence: 17,
                        productGroup: 7,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.15").toString(),
                        stepId: 15,
                        sequence: 1,
                        productGroup: 7,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.16").toString(),
                        stepId: 16,
                        sequence: 18,
                        productGroup: 7,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.17").toString(),
                        stepId: 17,
                        sequence: 7,
                        productGroup: 7,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.18").toString(),
                        stepId: 18,
                        sequence: 8,
                        productGroup: 7,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.19").toString(),
                        stepId: 19,
                        sequence: 19,
                        productGroup: 7,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.20").toString(),
                        stepId: 20,
                        sequence: 20,
                        productGroup: 7,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.21").toString(),
                        stepId: 21,
                        sequence: 21,
                        productGroup: 7,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.22").toString(),
                        stepId: 22,
                        sequence: 22,
                        productGroup: 7,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.23").toString(),
                        stepId: 23,
                        sequence: 23,
                        productGroup: 7,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.24").toString(),
                        stepId: 24,
                        sequence: 24,
                        productGroup: 7,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.25").toString(),
                        stepId: 25,
                        sequence: 25,
                        productGroup: 7,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.26").toString(),
                        stepId: 26,
                        sequence: 26,
                        productGroup: 7,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.27").toString(),
                        stepId: 27,
                        sequence: 27,
                        productGroup: 7,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.28").toString(),
                        stepId: 28,
                        sequence: 28,
                        productGroup: 7,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.29").toString(),
                        stepId: 29,
                        sequence: 29,
                        productGroup: 7,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.30").toString(),
                        stepId: 30,
                        sequence: 30,
                        productGroup: 7,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.31").toString(),
                        stepId: 31,
                        sequence: 31,
                        productGroup: 7,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.32").toString(),
                        stepId: 32,
                        sequence: 9,
                        productGroup: 7,
                        showDefault: true,
                        parallelChain: [18, 17],
                        showDefaultParallelChain: true
                    }
                ];
                break;

            // Shoes
            case 8:
                steps = [
                    {
                        displayName: i18n.t("enums.steps.1").toString(),
                        stepId: 1,
                        sequence: 10,
                        productGroup: 8,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.2").toString(),
                        stepId: 2,
                        sequence: 11,
                        productGroup: 8,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.3").toString(),
                        stepId: 3,
                        sequence: 12,
                        productGroup: 8,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.4").toString(),
                        stepId: 4,
                        sequence: 13,
                        productGroup: 8,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.5").toString(),
                        stepId: 5,
                        sequence: 14,
                        productGroup: 8,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.6").toString(),
                        stepId: 6,
                        sequence: 15,
                        productGroup: 8,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.7").toString(),
                        stepId: 7,
                        sequence: 16,
                        productGroup: 8,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.8").toString(),
                        stepId: 8,
                        sequence: 4,
                        productGroup: 8,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.9").toString(),
                        stepId: 9,
                        sequence: 5,
                        productGroup: 8,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    // removed as per the requirement in TTD-1831
                    // {
                    //     displayName: i18n.t("enums.steps.10").toString(),
                    //     stepId: 10,
                    //     sequence: 7,
                    //     productGroup: 8,
                    //     showDefault: true,
                    // },
                    {
                        displayName: i18n.t("enums.steps.11").toString(),
                        stepId: 11,
                        sequence: 7,
                        productGroup: 8,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.12").toString(),
                        stepId: 12,
                        sequence: 8,
                        productGroup: 8,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.13").toString(),
                        stepId: 13,
                        sequence: 9,
                        productGroup: 8,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.14").toString(),
                        stepId: 14,
                        sequence: 17,
                        productGroup: 8,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.15").toString(),
                        stepId: 15,
                        sequence: 1,
                        productGroup: 8,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.16").toString(),
                        stepId: 16,
                        sequence: 18,
                        productGroup: 8,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.17").toString(),
                        stepId: 17,
                        sequence: 2,
                        productGroup: 8,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.18").toString(),
                        stepId: 18,
                        sequence: 3,
                        productGroup: 8,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.19").toString(),
                        stepId: 19,
                        sequence: 19,
                        productGroup: 8,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.20").toString(),
                        stepId: 20,
                        sequence: 20,
                        productGroup: 8,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.21").toString(),
                        stepId: 21,
                        sequence: 21,
                        productGroup: 8,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.22").toString(),
                        stepId: 22,
                        sequence: 22,
                        productGroup: 8,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.23").toString(),
                        stepId: 23,
                        sequence: 23,
                        productGroup: 8,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.24").toString(),
                        stepId: 24,
                        sequence: 24,
                        productGroup: 8,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.25").toString(),
                        stepId: 25,
                        sequence: 25,
                        productGroup: 8,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.26").toString(),
                        stepId: 26,
                        sequence: 26,
                        productGroup: 8,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.27").toString(),
                        stepId: 27,
                        sequence: 27,
                        productGroup: 8,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.28").toString(),
                        stepId: 28,
                        sequence: 28,
                        productGroup: 8,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.29").toString(),
                        stepId: 29,
                        sequence: 29,
                        productGroup: 8,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.30").toString(),
                        stepId: 30,
                        sequence: 30,
                        productGroup: 8,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.31").toString(),
                        stepId: 31,
                        sequence: 31,
                        productGroup: 8,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.32").toString(),
                        stepId: 32,
                        sequence: 6,
                        productGroup: 8,
                        showDefault: true,
                        parallelChain: [18, 17],
                        showDefaultParallelChain: true
                    }
                ];
                break;

            // Socks
            case 9:
                steps = [
                    {
                        displayName: i18n.t("enums.steps.1").toString(),
                        stepId: 1,
                        sequence: 8,
                        productGroup: 9,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.2").toString(),
                        stepId: 2,
                        sequence: 9,
                        productGroup: 9,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.3").toString(),
                        stepId: 3,
                        sequence: 2,
                        productGroup: 9,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.4").toString(),
                        stepId: 4,
                        sequence: 4,
                        productGroup: 9,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    // removed as per the requirement in TTD-1831
                    // {
                    //     displayName: i18n.t("enums.steps.5").toString(),
                    //     stepId: 5,
                    //     sequence: 3,
                    //     productGroup: 9,
                    //     showDefault: true,
                    // },
                    {
                        displayName: i18n.t("enums.steps.6").toString(),
                        stepId: 6,
                        sequence: 3,
                        productGroup: 9,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.7").toString(),
                        stepId: 7,
                        sequence: 10,
                        productGroup: 9,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.8").toString(),
                        stepId: 8,
                        sequence: 11,
                        productGroup: 9,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.9").toString(),
                        stepId: 9,
                        sequence: 12,
                        productGroup: 9,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    // removed as per the requirement in TTD-1831
                    // {
                    //     displayName: i18n.t("enums.steps.10").toString(),
                    //     stepId: 10,
                    //     sequence: 7,
                    //     productGroup: 9,
                    //     showDefault: true,
                    // },
                    {
                        displayName: i18n.t("enums.steps.11").toString(),
                        stepId: 11,
                        sequence: 5,
                        productGroup: 9,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.12").toString(),
                        stepId: 12,
                        sequence: 6,
                        productGroup: 9,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.13").toString(),
                        stepId: 13,
                        sequence: 7,
                        productGroup: 9,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.14").toString(),
                        stepId: 14,
                        sequence: 13,
                        productGroup: 9,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.15").toString(),
                        stepId: 15,
                        sequence: 1,
                        productGroup: 9,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.16").toString(),
                        stepId: 16,
                        sequence: 14,
                        productGroup: 9,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.17").toString(),
                        stepId: 17,
                        sequence: 15,
                        productGroup: 9,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.18").toString(),
                        stepId: 18,
                        sequence: 16,
                        productGroup: 9,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.19").toString(),
                        stepId: 19,
                        sequence: 17,
                        productGroup: 9,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.20").toString(),
                        stepId: 20,
                        sequence: 18,
                        productGroup: 9,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.21").toString(),
                        stepId: 21,
                        sequence: 19,
                        productGroup: 9,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.22").toString(),
                        stepId: 22,
                        sequence: 20,
                        productGroup: 9,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.23").toString(),
                        stepId: 23,
                        sequence: 21,
                        productGroup: 9,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.24").toString(),
                        stepId: 24,
                        sequence: 22,
                        productGroup: 9,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.25").toString(),
                        stepId: 25,
                        sequence: 23,
                        productGroup: 9,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.26").toString(),
                        stepId: 26,
                        sequence: 24,
                        productGroup: 9,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.27").toString(),
                        stepId: 27,
                        sequence: 25,
                        productGroup: 9,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.28").toString(),
                        stepId: 28,
                        sequence: 26,
                        productGroup: 9,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.29").toString(),
                        stepId: 29,
                        sequence: 27,
                        productGroup: 9,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.30").toString(),
                        stepId: 30,
                        sequence: 28,
                        productGroup: 9,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.31").toString(),
                        stepId: 31,
                        sequence: 29,
                        productGroup: 9,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.32").toString(),
                        stepId: 32,
                        sequence: 30,
                        productGroup: 9,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    }
                ];
                break;

            // Accessories
            case 10:
                steps = [
                    {
                        displayName: i18n.t("enums.steps.1").toString(),
                        stepId: 1,
                        sequence: 6,
                        productGroup: 10,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.2").toString(),
                        stepId: 2,
                        sequence: 7,
                        productGroup: 10,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.3").toString(),
                        stepId: 3,
                        sequence: 8,
                        productGroup: 10,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.4").toString(),
                        stepId: 4,
                        sequence: 9,
                        productGroup: 10,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.5").toString(),
                        stepId: 5,
                        sequence: 10,
                        productGroup: 10,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.6").toString(),
                        stepId: 6,
                        sequence: 11,
                        productGroup: 10,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.7").toString(),
                        stepId: 7,
                        sequence: 12,
                        productGroup: 10,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.8").toString(),
                        stepId: 8,
                        sequence: 13,
                        productGroup: 10,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.9").toString(),
                        stepId: 9,
                        sequence: 14,
                        productGroup: 10,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.10").toString(),
                        stepId: 10,
                        sequence: 15,
                        productGroup: 10,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.11").toString(),
                        stepId: 11,
                        sequence: 3,
                        productGroup: 10,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.12").toString(),
                        stepId: 12,
                        sequence: 4,
                        productGroup: 10,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.13").toString(),
                        stepId: 13,
                        sequence: 5,
                        productGroup: 10,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.14").toString(),
                        stepId: 14,
                        sequence: 16,
                        productGroup: 10,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.15").toString(),
                        stepId: 15,
                        sequence: 1,
                        productGroup: 10,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.16").toString(),
                        stepId: 16,
                        sequence: 17,
                        productGroup: 10,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.17").toString(),
                        stepId: 17,
                        sequence: 18,
                        productGroup: 10,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.18").toString(),
                        stepId: 18,
                        sequence: 19,
                        productGroup: 10,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.19").toString(),
                        stepId: 19,
                        sequence: 20,
                        productGroup: 10,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.20").toString(),
                        stepId: 20,
                        sequence: 21,
                        productGroup: 10,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.21").toString(),
                        stepId: 21,
                        sequence: 22,
                        productGroup: 10,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.22").toString(),
                        stepId: 22,
                        sequence: 23,
                        productGroup: 10,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.23").toString(),
                        stepId: 23,
                        sequence: 24,
                        productGroup: 10,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.24").toString(),
                        stepId: 24,
                        sequence: 25,
                        productGroup: 10,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.25").toString(),
                        stepId: 25,
                        sequence: 26,
                        productGroup: 10,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.26").toString(),
                        stepId: 26,
                        sequence: 27,
                        productGroup: 10,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.27").toString(),
                        stepId: 27,
                        sequence: 28,
                        productGroup: 10,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.28").toString(),
                        stepId: 28,
                        sequence: 29,
                        productGroup: 10,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.29").toString(),
                        stepId: 29,
                        sequence: 30,
                        productGroup: 10,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.30").toString(),
                        stepId: 30,
                        sequence: 31,
                        productGroup: 10,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.31").toString(),
                        stepId: 31,
                        sequence: 32,
                        productGroup: 10,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.32").toString(),
                        stepId: 32,
                        sequence: 2,
                        productGroup: 10,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    }
                ];
                break;

            // Leather
            case 11:
                steps = [
                    // removed as per the requirement in TTD-1831
                    // {
                    //     displayName: i18n.t("enums.steps.1").toString(),
                    //     stepId: 1,
                    //     sequence: 10,
                    //     productGroup: 12,
                    //     showDefault: false,
                    // },
                    // {
                    //     displayName: i18n.t("enums.steps.2").toString(),
                    //     stepId: 2,
                    //     sequence: 11,
                    //     productGroup: 12,
                    //     showDefault: false,
                    // },
                    // {
                    //     displayName: i18n.t("enums.steps.3").toString(),
                    //     stepId: 3,
                    //     sequence: 12,
                    //     productGroup: 12,
                    //     showDefault: true,
                    // },
                    // {
                    //     displayName: i18n.t("enums.steps.4").toString(),
                    //     stepId: 4,
                    //     sequence: 13,
                    //     productGroup: 12,
                    //     showDefault: true,
                    // },
                    // {
                    //     displayName: i18n.t("enums.steps.5").toString(),
                    //     stepId: 5,
                    //     sequence: 14,
                    //     productGroup: 12,
                    //     showDefault: false,
                    // },
                    {
                        displayName: i18n.t("enums.steps.6").toString(),
                        stepId: 6,
                        sequence: 3,
                        productGroup: 12,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.7").toString(),
                        stepId: 7,
                        sequence: 10,
                        productGroup: 12,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.8").toString(),
                        stepId: 8,
                        sequence: 11,
                        productGroup: 12,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.9").toString(),
                        stepId: 9,
                        sequence: 12,
                        productGroup: 12,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.10").toString(),
                        stepId: 10,
                        sequence: 13,
                        productGroup: 12,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.11").toString(),
                        stepId: 11,
                        sequence: 7,
                        productGroup: 12,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.12").toString(),
                        stepId: 12,
                        sequence: 8,
                        productGroup: 12,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.13").toString(),
                        stepId: 13,
                        sequence: 9,
                        productGroup: 12,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.14").toString(),
                        stepId: 14,
                        sequence: 14,
                        productGroup: 12,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.15").toString(),
                        stepId: 15,
                        sequence: 1,
                        productGroup: 12,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.16").toString(),
                        stepId: 16,
                        sequence: 15,
                        productGroup: 12,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.17").toString(),
                        stepId: 17,
                        sequence: 5,
                        productGroup: 12,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.18").toString(),
                        stepId: 18,
                        sequence: 6,
                        productGroup: 12,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.19").toString(),
                        stepId: 19,
                        sequence: 16,
                        productGroup: 12,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.20").toString(),
                        stepId: 20,
                        sequence: 17,
                        productGroup: 12,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.21").toString(),
                        stepId: 21,
                        sequence: 18,
                        productGroup: 12,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.22").toString(),
                        stepId: 22,
                        sequence: 19,
                        productGroup: 12,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.23").toString(),
                        stepId: 23,
                        sequence: 20,
                        productGroup: 12,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.24").toString(),
                        stepId: 24,
                        sequence: 21,
                        productGroup: 12,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.25").toString(),
                        stepId: 25,
                        sequence: 22,
                        productGroup: 12,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.26").toString(),
                        stepId: 26,
                        sequence: 23,
                        productGroup: 12,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.27").toString(),
                        stepId: 27,
                        sequence: 24,
                        productGroup: 12,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.28").toString(),
                        stepId: 28,
                        sequence: 25,
                        productGroup: 12,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.29").toString(),
                        stepId: 29,
                        sequence: 26,
                        productGroup: 12,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.30").toString(),
                        stepId: 30,
                        sequence: 27,
                        productGroup: 12,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.31").toString(),
                        stepId: 31,
                        sequence: 2,
                        productGroup: 12,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.32").toString(),
                        stepId: 32,
                        sequence: 4,
                        productGroup: 12,
                        showDefault: true,
                        parallelChain: [18, 17],
                        showDefaultParallelChain: true
                    }
                ];
                break;

            // Swimwear
            case 12:
                steps = [
                    {
                        displayName: i18n.t("enums.steps.1").toString(),
                        stepId: 1,
                        sequence: 12,
                        productGroup: 12,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.2").toString(),
                        stepId: 2,
                        sequence: 13,
                        productGroup: 12,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.3").toString(),
                        stepId: 3,
                        sequence: 2,
                        productGroup: 12,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.4").toString(),
                        stepId: 4,
                        sequence: 3,
                        productGroup: 12,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.5").toString(),
                        stepId: 5,
                        sequence: 14,
                        productGroup: 12,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.6").toString(),
                        stepId: 6,
                        sequence: 4,
                        productGroup: 12,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.7").toString(),
                        stepId: 7,
                        sequence: 5,
                        productGroup: 12,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.8").toString(),
                        stepId: 8,
                        sequence: 6,
                        productGroup: 12,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.9").toString(),
                        stepId: 9,
                        sequence: 7,
                        productGroup: 12,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.10").toString(),
                        stepId: 10,
                        sequence: 8,
                        productGroup: 12,
                        showDefault: true,
                        parallelChain: [18, 17],
                        showDefaultParallelChain: true
                    },
                    {
                        displayName: i18n.t("enums.steps.11").toString(),
                        stepId: 11,
                        sequence: 9,
                        productGroup: 12,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.12").toString(),
                        stepId: 12,
                        sequence: 10,
                        productGroup: 12,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.13").toString(),
                        stepId: 13,
                        sequence: 11,
                        productGroup: 12,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.14").toString(),
                        stepId: 14,
                        sequence: 15,
                        productGroup: 12,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.15").toString(),
                        stepId: 15,
                        sequence: 1,
                        productGroup: 12,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.16").toString(),
                        stepId: 16,
                        sequence: 16,
                        productGroup: 12,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.17").toString(),
                        stepId: 17,
                        sequence: 17,
                        productGroup: 12,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.18").toString(),
                        stepId: 18,
                        sequence: 18,
                        productGroup: 12,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.19").toString(),
                        stepId: 19,
                        sequence: 19,
                        productGroup: 12,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.20").toString(),
                        stepId: 20,
                        sequence: 20,
                        productGroup: 12,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.21").toString(),
                        stepId: 21,
                        sequence: 21,
                        productGroup: 12,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.22").toString(),
                        stepId: 22,
                        sequence: 22,
                        productGroup: 12,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.23").toString(),
                        stepId: 23,
                        sequence: 23,
                        productGroup: 12,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.24").toString(),
                        stepId: 24,
                        sequence: 24,
                        productGroup: 12,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.25").toString(),
                        stepId: 25,
                        sequence: 25,
                        productGroup: 12,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.26").toString(),
                        stepId: 26,
                        sequence: 26,
                        productGroup: 12,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.27").toString(),
                        stepId: 27,
                        sequence: 27,
                        productGroup: 12,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.28").toString(),
                        stepId: 28,
                        sequence: 28,
                        productGroup: 12,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.29").toString(),
                        stepId: 29,
                        sequence: 29,
                        productGroup: 12,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.30").toString(),
                        stepId: 30,
                        sequence: 30,
                        productGroup: 12,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.31").toString(),
                        stepId: 31,
                        sequence: 31,
                        productGroup: 12,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.32").toString(),
                        stepId: 32,
                        sequence: 32,
                        productGroup: 12,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    }
                ];
                break;

            // Upholstery
            case 13:
                steps = [
                    {
                        displayName: i18n.t("enums.steps.1").toString(),
                        stepId: 1,
                        sequence: 13,
                        productGroup: 13,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.2").toString(),
                        stepId: 2,
                        sequence: 14,
                        productGroup: 13,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.3").toString(),
                        stepId: 3,
                        sequence: 2,
                        productGroup: 13,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.4").toString(),
                        stepId: 4,
                        sequence: 15,
                        productGroup: 13,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.5").toString(),
                        stepId: 5,
                        sequence: 3,
                        productGroup: 13,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.6").toString(),
                        stepId: 6,
                        sequence: 4,
                        productGroup: 13,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.7").toString(),
                        stepId: 7,
                        sequence: 5,
                        productGroup: 13,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.8").toString(),
                        stepId: 8,
                        sequence: 6,
                        productGroup: 13,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.9").toString(),
                        stepId: 9,
                        sequence: 16,
                        productGroup: 13,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.10").toString(),
                        stepId: 10,
                        sequence: 17,
                        productGroup: 13,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.11").toString(),
                        stepId: 11,
                        sequence: 10,
                        productGroup: 13,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.12").toString(),
                        stepId: 12,
                        sequence: 11,
                        productGroup: 13,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.13").toString(),
                        stepId: 13,
                        sequence: 12,
                        productGroup: 13,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.14").toString(),
                        stepId: 14,
                        sequence: 18,
                        productGroup: 13,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.15").toString(),
                        stepId: 15,
                        sequence: 1,
                        productGroup: 13,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.16").toString(),
                        stepId: 16,
                        sequence: 19,
                        productGroup: 13,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.17").toString(),
                        stepId: 17,
                        sequence: 20,
                        productGroup: 13,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.18").toString(),
                        stepId: 18,
                        sequence: 21,
                        productGroup: 13,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.19").toString(),
                        stepId: 19,
                        sequence: 22,
                        productGroup: 13,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.20").toString(),
                        stepId: 20,
                        sequence: 23,
                        productGroup: 13,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.21").toString(),
                        stepId: 21,
                        sequence: 24,
                        productGroup: 13,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.22").toString(),
                        stepId: 22,
                        sequence: 25,
                        productGroup: 13,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.23").toString(),
                        stepId: 23,
                        sequence: 26,
                        productGroup: 13,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.24").toString(),
                        stepId: 24,
                        sequence: 7,
                        productGroup: 13,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.25").toString(),
                        stepId: 25,
                        sequence: 8,
                        productGroup: 13,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.26").toString(),
                        stepId: 26,
                        sequence: 9,
                        productGroup: 13,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.27").toString(),
                        stepId: 27,
                        sequence: 27,
                        productGroup: 13,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.28").toString(),
                        stepId: 28,
                        sequence: 28,
                        productGroup: 13,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.29").toString(),
                        stepId: 29,
                        sequence: 29,
                        productGroup: 13,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.30").toString(),
                        stepId: 30,
                        sequence: 30,
                        productGroup: 13,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.31").toString(),
                        stepId: 31,
                        sequence: 31,
                        productGroup: 13,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.32").toString(),
                        stepId: 32,
                        sequence: 32,
                        productGroup: 13,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    }
                ];
                break;

            // Trims
            case 14:
                steps = [
                    {
                        displayName: i18n.t("enums.steps.1").toString(),
                        stepId: 1,
                        sequence: 6,
                        productGroup: 14,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.2").toString(),
                        stepId: 2,
                        sequence: 7,
                        productGroup: 14,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.3").toString(),
                        stepId: 3,
                        sequence: 8,
                        productGroup: 14,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.4").toString(),
                        stepId: 4,
                        sequence: 9,
                        productGroup: 14,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.5").toString(),
                        stepId: 5,
                        sequence: 10,
                        productGroup: 14,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.6").toString(),
                        stepId: 6,
                        sequence: 3,
                        productGroup: 14,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.7").toString(),
                        stepId: 7,
                        sequence: 11,
                        productGroup: 14,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.8").toString(),
                        stepId: 8,
                        sequence: 12,
                        productGroup: 14,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.9").toString(),
                        stepId: 9,
                        sequence: 13,
                        productGroup: 14,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.10").toString(),
                        stepId: 10,
                        sequence: 14,
                        productGroup: 14,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.11").toString(),
                        stepId: 11,
                        sequence: 15,
                        productGroup: 14,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.12").toString(),
                        stepId: 12,
                        sequence: 16,
                        productGroup: 14,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.13").toString(),
                        stepId: 13,
                        sequence: 17,
                        productGroup: 14,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.14").toString(),
                        stepId: 14,
                        sequence: 18,
                        productGroup: 14,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.15").toString(),
                        stepId: 15,
                        sequence: 1,
                        productGroup: 14,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.16").toString(),
                        stepId: 16,
                        sequence: 19,
                        productGroup: 14,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.17").toString(),
                        stepId: 20,
                        sequence: 18,
                        productGroup: 14,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.18").toString(),
                        stepId: 18,
                        sequence: 5,
                        productGroup: 14,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.19").toString(),
                        stepId: 19,
                        sequence: 21,
                        productGroup: 14,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.20").toString(),
                        stepId: 20,
                        sequence: 22,
                        productGroup: 14,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.21").toString(),
                        stepId: 21,
                        sequence: 23,
                        productGroup: 14,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.22").toString(),
                        stepId: 22,
                        sequence: 24,
                        productGroup: 14,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.23").toString(),
                        stepId: 23,
                        sequence: 25,
                        productGroup: 14,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.24").toString(),
                        stepId: 24,
                        sequence: 26,
                        productGroup: 14,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.25").toString(),
                        stepId: 25,
                        sequence: 27,
                        productGroup: 14,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.26").toString(),
                        stepId: 26,
                        sequence: 4,
                        productGroup: 14,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.27").toString(),
                        stepId: 27,
                        sequence: 28,
                        productGroup: 14,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.28").toString(),
                        stepId: 28,
                        sequence: 29,
                        productGroup: 14,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.29").toString(),
                        stepId: 29,
                        sequence: 30,
                        productGroup: 14,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.30").toString(),
                        stepId: 30,
                        sequence: 31,
                        productGroup: 14,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.31").toString(),
                        stepId: 31,
                        sequence: 32,
                        productGroup: 14,
                        showDefault: false,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    },
                    {
                        displayName: i18n.t("enums.steps.32").toString(),
                        stepId: 32,
                        sequence: 2,
                        productGroup: 14,
                        showDefault: true,
                        parallelChain: [],
                        showDefaultParallelChain: false
                    }
                ];
                break;
        }
        return steps;
    }

    public static getSteps(): ProductGroupModel[] {
        return [
            { id: 1, description: i18n.t("enums.steps.1").toString() },
            { id: 2, description: i18n.t("enums.steps.2").toString() },
            { id: 3, description: i18n.t("enums.steps.3").toString() },
            { id: 4, description: i18n.t("enums.steps.4").toString() },
            { id: 5, description: i18n.t("enums.steps.5").toString() },
            { id: 6, description: i18n.t("enums.steps.6").toString() },
            { id: 7, description: i18n.t("enums.steps.7").toString() },
            { id: 8, description: i18n.t("enums.steps.8").toString() },
            { id: 9, description: i18n.t("enums.steps.9").toString() },
            { id: 10, description: i18n.t("enums.steps.10").toString() },
            { id: 11, description: i18n.t("enums.steps.11").toString() },
            { id: 12, description: i18n.t("enums.steps.12").toString() },
            { id: 13, description: i18n.t("enums.steps.13").toString() },
            { id: 14, description: i18n.t("enums.steps.14").toString() },
            { id: 15, description: i18n.t("enums.steps.15").toString() },
            { id: 16, description: i18n.t("enums.steps.16").toString() },
            { id: 17, description: i18n.t("enums.steps.17").toString() },
            { id: 18, description: i18n.t("enums.steps.18").toString() },
            { id: 19, description: i18n.t("enums.steps.19").toString() },
            { id: 20, description: i18n.t("enums.steps.20").toString() },
            { id: 21, description: i18n.t("enums.steps.21").toString() },
            { id: 22, description: i18n.t("enums.steps.22").toString() },
            { id: 23, description: i18n.t("enums.steps.23").toString() },
            { id: 24, description: i18n.t("enums.steps.24").toString() },
            { id: 25, description: i18n.t("enums.steps.25").toString() },
            { id: 26, description: i18n.t("enums.steps.26").toString() },
            { id: 27, description: i18n.t("enums.steps.27").toString() },
            { id: 28, description: i18n.t("enums.steps.28").toString() },
            { id: 29, description: i18n.t("enums.steps.29").toString() },
            { id: 30, description: i18n.t("enums.steps.30").toString() },
            { id: 31, description: i18n.t("enums.steps.31").toString() },
            { id: 32, description: i18n.t("enums.steps.32").toString() }
        ];
    }

    // Sprint 20, TTD-3932
    public static getFootprintSteps(): ProductGroupModel[] {
        return [
            { id: 3, description: i18n.t("enums.steps.3").toString() },
            { id: 4, description: i18n.t("enums.steps.4").toString() },
            { id: 5, description: i18n.t("enums.steps.5").toString() },
            { id: 6, description: i18n.t("enums.steps.6").toString() },
            { id: 7, description: i18n.t("enums.steps.7").toString() },
            { id: 8, description: i18n.t("enums.steps.8").toString() },
            { id: 10, description: i18n.t("enums.steps.10").toString() },
            { id: 22, description: i18n.t("enums.steps.22").toString() },
            { id: 23, description: i18n.t("enums.steps.23").toString() },
            { id: 25, description: i18n.t("enums.steps.25").toString() },
            { id: 31, description: i18n.t("enums.steps.31").toString() },
            { id: 32, description: i18n.t("enums.steps.32").toString() }
        ];
    }
}