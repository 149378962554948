import { Vue, Component, Prop, Emit } from "vue-property-decorator";
import Multiselect from 'vue-multiselect';
import i18n from "@/i18n";
import _ from "lodash";
import { mapState } from "vuex";
import ClickOutside from 'vue-click-outside';

import DropDownEditable from "@/components/general/drop-down-editable/dropDownEditable.vue";
import DropDownInput from "@/components/general/drop-down-input/dropDownInput.vue";

// Services
import { InvitationService } from '@/services/invitationService';
import { SupplierService } from "@/services/supplierService";
import { GeolocationService } from '@/services/geolocationService';
import { CountryStateCityService } from '@/services/countryStateCityService';

// Helpers
import { NotificationHelper } from "@/helpers/notificationHelper";
import { CountryHelper } from "@/helpers/countryHelper";

// Models
import { DropdownModel } from '@/models/dropdownModel';
import { InvitationModel } from '@/models/invitationModel';
import { InvitedCompaniesModel, CompaniesModel } from '@/models/invitedCompaniesModel';
import { InvitedSupplierModel, InvitedSupplierResponseModel } from "@/models/invitedSupplierModel";
import { OrderCompanyModel } from "@/models/orderCompanyModel";
import { debounce } from "ts-debounce";
import { UserModel } from "@/models/userModel";
import { AddressModel } from "@/models/addressModel";

@Component({
    components: { Multiselect, DropDownEditable, DropDownInput },
    computed: mapState(['user', 'companyType']),
    directives: { ClickOutside }
})
export default class InviteNew extends Vue {

    @Prop()
    private suppliers!: OrderCompanyModel[];

    @Prop()
    private agent!: boolean;

    @Prop({default: false})
    private isCp!: boolean;

    @Prop({default: false})
    private stepPartner!: boolean;

    private countriesDropdown: DropdownModel[] = [];
    private statesDropdown: DropdownModel[] = [];
    private citiesDropdown: DropdownModel[] = [];

    private inviteSendToContactEmail: string = '';

    private invitationService: InvitationService;

    private supplierService: SupplierService;

    private invitationModel: InvitationModel = new InvitationModel();

    private invitedCompanies: CompaniesModel[] = [];

    private isInviting: boolean = false;

    private cscService: CountryStateCityService;
    private geolocationService: GeolocationService;

    private mapboxAccessToken: string = "";

    private mapboxGeolocationCoordinates: any;

    private selectedPartnerType: number = -1;

    //  error handling and validation

    private contactEmailError: boolean = false;
    private firstNameError: boolean = false;
    private lastNameError: boolean = false;
    private companyNameError: boolean = false;
    private companyRegistrationNumberError: boolean = false;
    private existingRegNoError: boolean = false;
    private existingEmailError: boolean = false;
    private cityError: boolean = false;
    private stateError: boolean = false;
    private countryError: boolean = false;
    private showConfirmModal: boolean = false;
    private partnerTypeError: boolean = false;
    private isLinking: boolean = false;
    private showCompanyPallete: boolean = false;
    private showRegPallete: boolean = false;
    private checkFieldsDebounced: any;
    private user!: UserModel;
    private companyType!: number;
    private heading: string = "";
    private centerText: string = "";
    private bottomText: string = "";
    private selectedCountry: string = "";
    private selectedState: string = "";
    private selectedCity: string = "";
    private filteredData: CompaniesModel[] = [];
    private result: InvitedSupplierResponseModel = new InvitedSupplierResponseModel();
    private isRowselectedName: boolean = false;
    private isRowselectedReg: boolean = false;
    private agentSelected: boolean = false;
    private debounceTimeout: number = -1;
    private showAgentWarning: boolean = false;
    private showCpWarning: boolean = false;
    private cpDpNpSelected: boolean = false;
    private fNameSpecialCharCheck: boolean = false;

    public constructor() {
        super();
        this.invitationService = new InvitationService();
        this.supplierService = new SupplierService();
        this.cscService = new CountryStateCityService();
        this.geolocationService = new GeolocationService();
    }

    private async created(): Promise<void> {
        this.countriesDropdown = [];
        this.countriesDropdown = _.orderBy(CountryHelper.getCountryList(),['text'],['asc']);
        this.getMapboxTokenAsync();
        this.checkFieldsDebounced = debounce(this.validateField, 250, { maxWait: 250, isImmediate: true });
    }

    // Sprint 23 - TTD-3647, updated
    private async searchBy(event: any, field: string): Promise<void> {
        this.filteredData = [];
        let response: InvitedCompaniesModel = new InvitedCompaniesModel();
        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(async()=>{
            if (field === 'name' && this.invitationModel.companyName.length > 1) {
                response = await this.invitationService.getCompanyFromNameAsync(this.invitationModel.companyName.toLowerCase());
            }
            if (field === 'registration' && this.invitationModel.companyRegistrationNumber.length > 1) {
                response = await this.invitationService.getCompanyFromRegAsync(this.invitationModel.companyRegistrationNumber.toLowerCase());
            }
            this.filteredData.splice(0, this.filteredData.length);
            if (((field === 'name' && this.invitationModel.companyName.length > 1) || (field === 'registration' && this.invitationModel.companyRegistrationNumber.length > 1)) && response.companies.length > 0) {
                if(this.user.companyId !== null && this.user.companyId !== undefined && !this.user.companyName.startsWith("TT -") && !this.user.companyName.startsWith("TT-") && !this.user.companyName.startsWith("tt-") && !this.user.companyName.startsWith("tt -")){
                    response.companies = response.companies.filter(c => !c.companyID.startsWith("TT -") && !c.companyID.startsWith("TT-") && !c.companyID.startsWith("tt-") && !c.companyID.startsWith("tt -") && !c.name.startsWith("TT -") && !c.name.startsWith("TT-") && !c.name.startsWith("tt-") && !c.name.startsWith("tt -"));
                }
                response.companies.forEach((company) => {
                    if (field === 'name' && company.isBlocked === false) {
                        this.filteredData.push(company);
                    }
                    if (field === 'registration' && company.isBlocked === false) {
                        this.filteredData.push(company);
                    }
                });
            }
            this.filteredData = this.filteredData.filter(f => f.officeAddresses.length > 0);
            this.filteredData = this.filteredData.sort((user1,user2) => {
                return user2.name.toLowerCase() > user1.name.toLowerCase()
                  ? -1
                  : user2.name.toLowerCase() < user1.name.toLowerCase()
                  ? 1
                  : 0;
              });
            if (field === 'name' && this.filteredData.length > 0) {
                this.showCompanyPallete = true;
            } else {
                this.showCompanyPallete = false;
            }
            if (field === 'registration' && this.filteredData.length > 0) {
                this.showRegPallete = true;
            } else {
                this.showRegPallete = false;
            }
        }, 250, this);
    }

    // Sprint 23 - TTD-3647, updated
    private selectedRow(data: CompaniesModel): void {
        const user: UserModel = this.$store.getters.user;
        if (this.agent && data.companyType !== 5) {
            this.showAgentWarning = true;
            return;
        } else if( (this.isCp || this.stepPartner) && data.companyType === 5) {
            this.showCpWarning = true;
            return;
        }
        let countryInFull = "";
        if (data.officeAddresses.length > 0 && data.officeAddresses[0].country !== '') {
            countryInFull = this.countriesDropdown.filter(cd => cd.value === data.officeAddresses[0].country)[0].text
        }
        if (data.companyType === 5) {
            this.invitationModel.companyType = 5;
            this.agentSelected = true;
            this.isAgentSelected;
            this.cpDpNpSelected = false;
        } else {
            this.cpDpNpSelected = true;
            this.agentSelected = false;
            this.isAgentSelected;
        }
        this.invitationModel.companyName = data.name;
        this.invitationModel.companyRegistrationNumber = data.companyID; // TTD-4736
        if (data.officeAddresses.length > 0) {
            this.invitationModel.country = data.officeAddresses[0].country ? data.officeAddresses[0].country : '';
            this.invitationModel.state = data.officeAddresses[0].state ? data.officeAddresses[0].state : '';
            this.invitationModel.city = data.officeAddresses[0].city ? data.officeAddresses[0].city : '';
        } else {
            this.invitationModel.country = '';
            this.invitationModel.state = '';
            this.invitationModel.city = '';
        }

        this.selectedCountry = countryInFull;
        this.selectedState = this.invitationModel.state;
        this.selectedCity = this.invitationModel.city;
        if (this.showCompanyPallete) {
            this.isRowselectedName = true;
        } else {
            this.isRowselectedReg = true;
        }
        this.showRegPallete = false;
        this.showCompanyPallete = false;
    }

    private emptyOnFocus(): void {
        if (this.cpDpNpSelected || this.agentSelected) {
            this.invitationModel.companyName = "";
            this.invitationModel.companyRegistrationNumber = "";
            this.invitationModel.country = "";
            this.invitationModel.state = "";
            this.invitationModel.city = "";
            this.selectedCountry = "";
            this.selectedState = "";
            this.selectedCity = "";
            this.isRowselectedName = false;
            this.isRowselectedReg = false;
            this.cpDpNpSelected = false;
            this.agentSelected = false;
            this.isAgentSelected;
        }
    }

    private closeWarningModal(): void {
        this.showAgentWarning = false;
        this.showCpWarning = false;
    }

    // private get getRegPallete(): boolean {
    //     return this.showRegPallete;
    // }

    // Sprint 23 - TTD-3647, updated
    private closeRegPallete(): void {
        this.showRegPallete = false;
        // this.invitationModel.companyRegistrationNumber = '';
    }

    // Sprint 23 - TTD-3647, updated
    private closeCompanyPallete(): void {
        this.showCompanyPallete = false;
        // this.invitationModel.companyName = '';
    }

    private get isCpSelected(): boolean {
        if (this.isCp && !this.isAgentSelected) {
            return true;
        }
        return false;
    }

    private get isAgentSelected(): boolean {
        if (this.agent) {
            return true;
        }
        return false;
    }

    private get sendInvitationDisabled(): boolean {
        let isError = (!this.invitationModel.isValid ||
            this.inviteSendToContactEmail === this.invitationModel.toContactEmail ||
            (this.selectedPartnerType === -1 && this.companyType !== 5));

        if (!isError) {
            isError = (this.contactEmailError ||
            this.firstNameError || this.fNameSpecialCharCheck || this.lastNameError ||
            this.companyRegistrationNumberError ||
            this.companyNameError ||
            this.countryError ||
            this.stateError || this.invitationModel.state === "" ||
            this.cityError || this.invitationModel.city === "" ||
            (this.partnerTypeError && this.companyType !== 5)) ? true : false;
        }
        return isError;
    }

    private get partnerType(): DropdownModel[] {
        let partnerList = [];
        if(this.companyType === 5){
            partnerList = [
                { value: 2, text: i18n.t('pages.invitation.nominated_partner').toString() },
            ];
        }
        else{
            partnerList = [
                { value: 1, text: i18n.t('pages.invitation.tier1_partner').toString(), disabled: this.agentSelected },
                { value: 2, text: i18n.t('pages.invitation.nominated_partner').toString(), disabled: this.agentSelected },
                { value: 5, text: i18n.t('pages.invitation.agent_partner').toString(), disabled: this.cpDpNpSelected || this.stepPartner }
            ];
        }
        partnerList.sort((item1, item2) => {
            if(item1.text < item2.text) { return -1; }
            if(item1.text > item2.text) { return 1; }
            return 0;
        });
        return partnerList;
    }

    private async sendInvitationClick(): Promise<void> {
        if (this.sendInvitationDisabled) {
            return;
        }
        // store value to disable multiple invites
        this.inviteSendToContactEmail = this.invitationModel.toContactEmail;
        this.sendInvitationAsync();
    }

    private async sendInvitationAsync(): Promise<void> {
        try {
            let compType: string|number = -1;
            if (this.companyType === 5) {
                compType = 2;
            } else if (this.selectedPartnerType !== 1 && this.selectedPartnerType !== 5 && this.companyType !== 5) {
                compType = 0;
            } else {
                compType = this.selectedPartnerType;
            }
            // store value to disable multiple invites
            this.isInviting = true;
            this.invitationModel.companyName = this.invitationModel.companyName.trim();
            this.invitationModel.companyRegistrationNumber = this.invitationModel.companyRegistrationNumber.trim();
            this.invitationModel.toContactEmail = this.invitationModel.toContactEmail.trim();
            this.invitationModel.toContactName = this.invitationModel.MainContactFirstName.trim() + " " + this.invitationModel.MainContactLastName.trim();
            this.invitationModel.MainContactFirstName = this.invitationModel.MainContactFirstName.trim();
            this.invitationModel.MainContactLastName = this.invitationModel.MainContactLastName.trim();
            this.inviteSendToContactEmail = this.invitationModel.toContactEmail;
            this.invitationModel.latitude = 0;
            this.invitationModel.longitude = 0;
            this.invitationModel.isTier1 = this.selectedPartnerType === 1 ? true : false;
            this.invitationModel.isAgent = this.selectedPartnerType === 5 ? true : false;
            this.invitationModel.companyType = compType;
            this.invitationModel.invitedPersonEmailID = this.invitationModel.toContactEmail;
            const invitationResult = await this.invitationService.sendInvitationAsync(this.invitationModel);
            this.handleInvitationResult(invitationResult);

            // reset to enable invite again.
            this.inviteSendToContactEmail = "";
        }
        finally {
            this.isInviting = false;
        }
    }

    private handleInvitationResult(invitationResult: InvitedSupplierResponseModel): void {
        this.result = invitationResult;
        const isCompanyAgent = invitationResult.invitedSupplierResponse !== null ? invitationResult.invitedSupplierResponse.companyType === 5 ? true : false : this.invitationModel.isAgent;
        const supplierIsAlreadyInList = invitationResult.invitedSupplierResponse !==null ? this.suppliers.findIndex(x => x.id.toLowerCase() === invitationResult.invitedSupplierResponse.invitedCompanyId) >= 0: false;
        if (invitationResult.invitedSupplierResponse !==null && supplierIsAlreadyInList) {
            const supplierOrAgent = invitationResult.invitedSupplierResponse.companyType == 5?"agent":"supply chain partner";
            const alertExistingInListText = this.$t('pages.invitation.existing_supplier_in_current_list',
                [this.invitationModel.companyRegistrationNumber, invitationResult.invitedSupplierResponse.companyName, supplierOrAgent]).toString();

            this.$alert(alertExistingInListText, "", undefined, { confirmButtonText: this.$t('global.modal.ok') as string, html: alertExistingInListText });
        } else if (this.invitationModel.isAgent !== isCompanyAgent) {
            const companyType = invitationResult.invitedSupplierResponse.companyType === 5 ? 'Agent':'Supply chain partner';
            this.result.message = 'BRN_And_Email_Already_Known_With_Diffent_Company';
            this.heading = "The user email is already known.";
            this.centerText = "This email address and company registered in the system as ‘"+companyType+"’";
            this.bottomText = "Please check the company type";
            this.showConfirmModal = true;
        } else {
            switch (invitationResult.message) {
                case "BRN_And_Email_Already_Known_With_Same_Company": {
                    this.heading = "The user email and company registration number are already known for below company.";
                    this.centerText = "Company registration number ‘"+this.invitationModel.companyRegistrationNumber+"’ Company name ‘"+invitationResult.invitedSupplierResponse.companyName+"’ Contact name ‘"+invitationResult.invitedSupplierResponse.mainContact+"’.";
                    this.bottomText = "Do you want to invite the user of this company?";
                    this.showConfirmModal = true;
                    break;
                }
                case "Do_You_Want_To_Add_Second_User": {
                    this.heading = "The company registration number is already known.";
                    this.centerText = "Company registration number ‘"+this.invitationModel.companyRegistrationNumber+"’ Company name ‘"+invitationResult.invitedSupplierResponse.companyName+"’.";
                    this.bottomText = "Do you want to invite ‘"+this.invitationModel.toContactEmail+"’ as new user for ‘"+invitationResult.invitedSupplierResponse.companyName+"’?";
                    this.showConfirmModal = true;
                    break;
                }
                case "Email_Already_Known_With_Diffrent_BRN": {
                    this.heading = "The user email is already known.";
                    this.centerText = "This email address is known in the system for a company with a different company registration number.";
                    this.bottomText = "Please check the company registration number or contact ‘"+this.invitationModel.toContactEmail+"’.";
                    this.showConfirmModal = true;
                    break;
                }
                case "BRN_And_Email_Already_Known_With_Diffent_Company": {
                    this.heading = "The user email is already known.";
                    this.centerText = "This email address is known in the system for a company with a different company registration number.";
                    this.bottomText = "Please check the company registration number or contact ‘"+this.invitationModel.toContactEmail+"’.";
                    this.showConfirmModal = true;
                    break;
                }
                case "BRN_And_Email_Already_Known_With_Same_Company_For_Second_User": {
                    this.heading = "The user email and company registration number are already known for the below company.";
                    this.centerText = "Company registration number ‘"+this.invitationModel.companyRegistrationNumber+"’ Company name ‘"+invitationResult.invitedSupplierResponse.companyName+"’ Contact name ‘"+invitationResult.invitedSupplierResponse.mainContact+"’";
                    this.bottomText = "Do you want to invite the user of this company?";
                    this.showConfirmModal = true;
                    break;
                }
                case "New_Supplier_Added_Successfully": {
                    NotificationHelper.createSucceededNotification(i18n.t('pages.invitation.invite_notification', [this.invitationModel.toContactEmail]).toString());
                    this.closeInviteModal(true);
                    break;
                }
                case "No_Scenario_Match": {
                    NotificationHelper.createErrorNotification('We cannot process the invite with provided Email and Business Registration Number. Please contact tex.tracer via the icon in the bottom right corner or send an e-mail to support@tex-tracer.com.');
                    break;
                }
                default:
                    break;
            }
        }
    }

    private closeModal(): void{
        this.showConfirmModal = false;
        if (this.result.message==='Email_Already_Known_With_Diffrent_BRN') {
            this.existingEmailError = true;
        } else if(this.result.message==='Do_You_Want_To_Add_Second_User') {
            this.existingRegNoError = true;
        } else {
            this.existingRegNoError = true;
            this.existingEmailError = true;
        }

    }

    private async linkSupplierAsync(): Promise<void> {
        if (this.result.message==="Do_You_Want_To_Add_Second_User") {
            this.addSecondUser();
            return;
        }
        this.isLinking = true;

        try {
            if (this.result.message==="BRN_And_Email_Already_Known_With_Same_Company_For_Second_User") {
                this.result.invitedSupplierResponse.invitedPersonEmailID = this.invitationModel.toContactEmail;
            }
            const response = await this.supplierService.linkSupplierAsync(this.result.invitedSupplierResponse);
            if(response.result === "Duplicate_Record_In_InvitedSupplierLedger"){
                await this.supplierService.getSuppliersAsync();
                this.showConfirmModal = false;
                const alertText = "Another user of your company has already linked the invited supplier.";
                this.$alert(alertText, "", undefined, { confirmButtonText: this.$t('global.modal.cancel') as string, html: alertText });
            }
            else if(response.result === "success"){
                NotificationHelper.createSucceededNotification(i18n.t('pages.invitation.link_notification', [this.result.invitedSupplierResponse.companyName]).toString());
            }
            else{
                NotificationHelper.createErrorNotification(`Failed to add ${this.result.invitedSupplierResponse.companyName} partner to your supply chain partners list.`);
            }
            if (response.result !== "Duplicate_Record_In_InvitedSupplierLedger") {
                this.closeInviteModal(true);
            }
        }
        finally {
            this.isLinking = false;
        }
    }

    private getLocation(location: AddressModel): string {
        let loc = location.city+', '+location.country;
        return loc;
    }

    private async addSecondUser(): Promise<void> {
        this.isLinking = true;
        try {
            const user: UserModel = this.$store.getters.user;
            const inviteUser: InvitationModel = {
                toContactEmail: this.invitationModel.toContactEmail,
                toContactName: this.invitationModel.toContactName,
                companyName: this.result.invitedSupplierResponse.companyName,
                companyRegistrationNumber: this.invitationModel.companyRegistrationNumber,
                isAgent: this.result.invitedSupplierResponse.isAgent,
                invitedPersonEmailID: this.invitationModel.invitedPersonEmailID,
                isClient: this.invitationModel.isClient,
                isTier1: this.invitationModel.isTier1,
                isValid: this.invitationModel.isValid,
                language: this.invitationModel.language,
                country: this.result.invitedSupplierResponse.country,
                state: this.invitationModel.state,
                city: this.result.invitedSupplierResponse.city,
                latitude: this.invitationModel.latitude,
                longitude: this.invitationModel.longitude,
                MainContactFirstName: this.invitationModel.MainContactFirstName,
                MainContactLastName: this.invitationModel.MainContactLastName,
                companyType: this.result.invitedSupplierResponse.companyType
            };
            const response = await this.invitationService.addSecondUserAsync(inviteUser, user.companyId);
            if(response.message === "RequestAddSecondUser_Success"){
                this.showConfirmModal = false;
                this.closeInviteModal(true);
                NotificationHelper.createSucceededNotification('Second user added successfully.');
            } else if(response.message === "Company_Already_Exists_In_InvitedSupplier") {
                const supplierOrAgent = this.result.invitedSupplierResponse.companyType == 5?"agent":"supply chain partner";
                const alertExistingInListText = this.$t('pages.invitation.existing_supplier_in_current_list',
                    [this.invitationModel.companyRegistrationNumber, this.result.invitedSupplierResponse.companyName, supplierOrAgent]).toString();
                this.$alert(alertExistingInListText, "", undefined, { confirmButtonText: this.$t('global.modal.ok') as string, html: alertExistingInListText });
                this.showConfirmModal = false;
                this.existingRegNoError = true;
            } else {
                NotificationHelper.createErrorNotification('Failed to add second user.');
            }
        }
        finally {
            this.isLinking = false;
        }
    }

    private async getMapboxTokenAsync(): Promise<void> {
        this.mapboxAccessToken = (await this.geolocationService.getMapboxToken()).token;
        return;
    }

    private async validateField(event:any,fieldname:string): Promise<void> {
        const cityDropdown = this.$refs.cityDropdown as any;
        const stateDropdown = this.$refs.stateDropdown as any;

        switch (fieldname) {
            case "contactEmail": {
                const emailValid = event.target.value.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
                this.contactEmailError = (emailValid == null) ? true : false;
                this.existingEmailError === true ? this.existingEmailError = this.contactEmailError : '';
                break;
                }
            case "firstName":
                var specials=/[@()[\];:<>, ]/;
                if (event.target.value.length < 2) {
                    this.firstNameError = true;
                } else {
                    this.firstNameError = false;
                }
                // TTD-4477, for adding validations on firstname
                if (specials.test(event.target.value) && !(event.target.value.length < 2)) {
                    this.fNameSpecialCharCheck = true;
                } else {
                    this.fNameSpecialCharCheck = false;
                }
                break;
            case "lastName":
                if (event.target.value.length < 2) {
                    this.lastNameError = true;
                } else {
                    this.lastNameError = false;
                }
                break;
            case "companyRegistrationNumber":
                this.companyRegistrationNumberError = (event.target.value.length < 2) ? true : false;
                this.existingRegNoError === true ? this.existingRegNoError = this.companyRegistrationNumberError : '';
                break;
            case "companyName":
                this.companyNameError = (event.target.value.length < 2) ? true : false;
                break;

            case "country":
              this.invitationModel.country = "";
              this.countryError = (event.fieldValue == i18n.t('errors.no_records_found').toString() || event.fieldValue == i18n.t('pages.invitation.loading').toString()) ? true : false;

              this.statesDropdown = [{ value: '', text: i18n.t('pages.invitation.loading').toString() }];
              stateDropdown.clearValues();
              this.invitationModel.state = "";

              this.citiesDropdown = [];
              cityDropdown.clearValues();
              this.invitationModel.city = "";

              if (!this.countryError) {
                const countriesList = await this.cscService.getAllCountries();
                const country = countriesList.find((x: { iso2: any; }) => x.iso2 == event.fieldKey);

                if (country !== undefined) {
                  this.invitationModel.country = event.fieldKey;
                  const states = await this.cscService.getStatesOfCountry(this.invitationModel.country);
                  this.statesDropdown = [];
                  if (states !== undefined) {
                    states.forEach((state: { iso2: string; name: string; }) => {
                      this.statesDropdown.push({ value: state.iso2, text: state.name });
                    });
                    this.statesDropdown = _.orderBy(this.statesDropdown,['text'],['asc']);
                  }
                  if (!states || states.length == 0) {
                    this.statesDropdown = [{ value: '', text: i18n.t('errors.no_records_found').toString() }];
                  }
                } else {
                  this.statesDropdown = [];
                }
              } else {
                this.statesDropdown = [];
              }
              break;

            case "state":
                if (!event.fieldKey || event.fieldKey.length == 0){
                  this.stateError = (event.fieldValue == i18n.t('errors.no_records_found').toString() || event.fieldValue == i18n.t('pages.invitation.loading').toString()) ? true : false;
                    return;
                }

                this.citiesDropdown = [{ value: '', text: i18n.t('pages.invitation.loading').toString() }];
                cityDropdown.clearValues();
                this.invitationModel.city = "";

                this.invitationModel.state = "";
                this.stateError = (event.fieldValue.length < 2 ) ? true : false;

                if (!this.stateError) {
                    this.invitationModel.state = event.fieldValue;
                    if (event.fieldKey && event.fieldKey.length>0) {
                        const cities = await this.cscService.getCitiesOfState(this.invitationModel.country, event.fieldKey);
                        this.citiesDropdown = [];
                        if (cities !== undefined) {
                            cities.forEach((city: { id: any; name: any; }) => {
                                this.citiesDropdown.push({ value: city.id, text: city.name });
                            });
                            this.citiesDropdown = _.orderBy(this.citiesDropdown,['text'],['asc']);
                        }
                        const gotCoordinates = await this.geolocationService.getCoordinates(this.mapboxAccessToken, this.invitationModel.state + ", " + this.invitationModel.country);
                        if (gotCoordinates.features.length > 0) {
                            this.invitationModel.longitude = parseFloat(gotCoordinates.features[0].center[0]);
                            this.invitationModel.latitude = parseFloat(gotCoordinates.features[0].center[1]);
                        }
                    } else {
                      this.citiesDropdown = [];
                    }
                } else {
                  this.citiesDropdown = [];
                }
                break;

            case "city":
                this.invitationModel.city = "";
                this.cityError = (event.fieldValue.length < 2 || event.fieldValue == i18n.t('errors.no_records_found').toString() || event.fieldValue == i18n.t('pages.invitation.loading').toString()) ? true : false;
                if (!this.cityError) {
                    this.invitationModel.city = event.fieldValue;
                    const gotCoordinates = await this.geolocationService.getCoordinates(this.mapboxAccessToken, this.invitationModel.city.split(' ')[0] + ", " + this.invitationModel.state + ", " + this.invitationModel.country);
                    if (gotCoordinates.features.length > 0) {
                        this.invitationModel.longitude = parseFloat(gotCoordinates.features[0].center[0]);
                        this.invitationModel.latitude = parseFloat(gotCoordinates.features[0].center[1]);
                    }
                }
                break;
            case "partnerType":
                this.partnerTypeError = event.value === '' || typeof event.value === 'string' || event.value.length > 1 ? true : false;
                if(!this.partnerTypeError){
                    this.selectedPartnerType = event.value;
                }
                break;
            default:
                break;
        }
    }

    @Emit()
    private closeInviteModal(invited: boolean): void {
    }
}
