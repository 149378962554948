import { SupplierClient } from '@/clients/supplierClient';
import { InvitedSupplierModel, LinkSupplierResponeModel } from '@/models/invitedSupplierModel';
import { OrderCompanyModel } from '@/models/orderCompanyModel';
import { SupplierCertificateModal } from '@/models/supplierCertificateModal';
import { SupplierAddressResponseModel, SupplierModel, SupplierContactInfoList } from '@/models/supplierModel';
import { SupplierOverviewModel } from '@/models/supplierOverviewModel';
import { SupplierPartnerModel, SupplierPartnersBrandsRespModel } from '@/models/supplierPartnerModel';
import { SupplierBlockchain } from '@/clients/supplierBlockchain';
import { ProductQualityReportModel } from '@/models/productQualityReportModel';
import store from '@/store';
import { QualityReportResponseModel } from '@/models/qualityReportResponseModel';
import { PublishUnpublishModel, updatePhaseStatusRequestModel, updatePhaseStatusResponseModel } from '@/models/publishUnpublishOrderModel';
import { OrderLineDisplayModel } from '@/models/orderLineDisplayModel';
import { ClientBlockchain } from '@/clients/clientBlockchain';
import { UserModel } from '@/models/userModel';
import { SupplierPartnersBrandsModel } from '@/models/brandWithSupplierModel';
import { CompanyNameModel } from '@/models/companyModel';
import { EsgEnvDocUrlReq, EsgEnvDocUrlResp, EsgEnvModel, EsgEnvRespModel, EsgSocialDocUrlReq, EsgSocialDocUrlResp, EsgSocialRespModel } from "@/models/esgModel";
import { EsgSocialModel } from '@/models/esgModel';
import { ActionTypes } from '@/store/actions/actions';

export class SupplierService {
    private readonly client: SupplierClient = new SupplierClient();
    private readonly supplierBlockchain: SupplierBlockchain = new SupplierBlockchain();
    private readonly clientBlockchain: ClientBlockchain = new ClientBlockchain();

    public async getSuppliersAsync(): Promise<OrderCompanyModel[]> {
        const user = store.getters.user as UserModel;
        const result = await this.clientBlockchain.getSuppliersListAsync(user.companyId);
        store.commit("setSuppliers", result.supplier);
        return result.supplier;
    }

    public async getSupplierOverviewAsync(supplierChanged: boolean): Promise<SupplierOverviewModel[]> {
        let suppliers: OrderCompanyModel[] = [];
        if (store.getters.suppliers != null && store.getters.suppliers.length > 0 && !supplierChanged) {
            suppliers = store.getters.suppliers;
        }
        else{
            const user = store.getters.user as UserModel;
            const result = await this.clientBlockchain.getSuppliersListAsync(user.companyId);
            suppliers = result.supplier;
            store.commit("setSuppliers", result.supplier);
        }
        const result = suppliers.map(s => new SupplierOverviewModel(s.id, s.companyName, s.country, s.isCompliant, s.isTier1, s.onBoarded, s.isAgent));
        return result;
    }

    public async linkSupplierAsync(invitedSupplierModel: InvitedSupplierModel): Promise<LinkSupplierResponeModel> {
        return await this.client.linkSupplierAsync(invitedSupplierModel);
    }

    public async getSupplierAsync(supplierId: string): Promise<SupplierModel> {
        const result = await this.client.getSupplierAsync(supplierId);
        return result;
    }

    public async getSustainableCertsAsync(supplierId: string): Promise<any> {
        const result = await this.supplierBlockchain.getSustainableCertsAsync(supplierId);
        return result;
    }

    public async getSocialCertsAsync(supplierId: string): Promise<any> {
        const result = await this.supplierBlockchain.getSocialCertsAsync(supplierId);
        return result;
    }

    public async getSupplierDetailsAsync(supplierId: string): Promise<any> {
        const result = await this.client.getSupplierDetailsAsync(supplierId);
        return result;
    }

    public async getSupplierPartnersAsync(supplierId: string): Promise<SupplierPartnerModel[]> {
        const result = await this.client.getSupplierPartnersAsync(supplierId);
        return result;
    }

    public async getQualityReportsAsync(clientId: string, supplierId: string): Promise<ProductQualityReportModel[]>{
        const result = await this.supplierBlockchain.getQualityReportsAsync(clientId, supplierId);
        return result.productQualityReport;
    }

    public async uploadQualityReportAsync(uploadData: FormData, clientId: string): Promise<QualityReportResponseModel>{
        const result = await this.supplierBlockchain.uploadQualityReportAsync(uploadData, clientId);
        return result;
    }

    public async linkScpQualityReportAsync(orderId: string, lineId: string, data: ProductQualityReportModel[]): Promise<QualityReportResponseModel>{
        const result = await this.supplierBlockchain.linkScpQualityReportAsync(orderId, lineId, data);
        return result;
    }

    public async deleteQualityReportScpAsync(clientId: string, reportId: string): Promise<any>{
        const result = await this.supplierBlockchain.deleteQualityReportScpAsync(clientId, reportId);
        return result;
    }

    // Api of phase and status for publish and unpublish orders
    public async updatePublishOrderlinePhaseStatus(orderId: string, data: PublishUnpublishModel): Promise<updatePhaseStatusResponseModel>{
        const result = await this.supplierBlockchain.updatePublishOrderlinePhaseStatus(orderId, data);
        if(result.success){
            const orders = store.getters.orders as OrderLineDisplayModel[];
            const index = orders.findIndex(o => o.orderId === orderId);
            if(index !== -1){
                orders[index].orderLinePhase = data.orderLinePhase;
                orders[index].orderLines[0].orderLinePhase = data.orderLinePhase;
                orders[index].orderLineStatus = data.orderLineStatus;
                orders[index].orderLines[0].orderLineStatus = data.orderLineStatus;
            }
            store.commit("setAllOrders", orders);
        }
        return result;
    }

    // Api of phase and status for orderline
    public async updateOrderlinePhaseStatus(orderIds: updatePhaseStatusRequestModel, phase: number, status: number): Promise<updatePhaseStatusResponseModel>{
        const result = await this.supplierBlockchain.updateOrderlinePhaseStatus(orderIds, phase, status);
        if(result.success){
            const orders = store.getters.orders as OrderLineDisplayModel[];
            const responseOrders: OrderLineDisplayModel[] = result.orders;
            orderIds.orderIdList.forEach(id => {
                const index = orders.findIndex(o => o.orderId === id);
                if(index !== -1){
                    orders[index].orderLinePhase = phase;
                    orders[index].orderLines[0].orderLinePhase = phase;
                    orders[index].orderLineStatus = status;
                    orders[index].orderLines[0].orderLineStatus = status;
                    if(responseOrders.findIndex(ro => ro.orderId === id) !== -1){
                        orders[index].orderRefId = responseOrders.filter(ro => ro.orderId === id)[0].orderRefId;
                    }
                }
            })
            store.commit("setAllOrders", orders);
        }
        return result;
    }

    // TTD-3941 supplier company address detail
    public async getSupplierAddressAsync(supplierId: string): Promise<SupplierAddressResponseModel>{
        const result = await this.clientBlockchain.getSupplierAddressAsync(supplierId);
        return result;
    }

    // TTD-3691 get company name
    public async getCompanyNameAsync(): Promise<CompanyNameModel[]>{
        const result = await this.clientBlockchain.getCompanyNameAsync();
        if(result.success){
            const companies = JSON.parse(decodeURIComponent(escape(window.atob(result.companies))));
            return companies;
        }
        return [];
    }
    //Sprint-26 TTD-3608 profile data
    public async getSupplierContactInfoAsync(supplierCompId:string, invitedById:string): Promise<SupplierContactInfoList[]>{
        const result = await this.supplierBlockchain.getSupplierContacInfoList(supplierCompId, invitedById);
        return result;
    }

    public async getSocialDataByAddressIdAsync(addressId:string): Promise<EsgSocialRespModel>{
        const result = await this.supplierBlockchain.getSocialDataByAddressId(addressId);
        return result;
    }

    public async getSocialDataByCompanyIdAsync(companyId: string): Promise<EsgSocialRespModel>{
        const result = await this.supplierBlockchain.getSocialDataByCompanyId(companyId);
        return result;
    }

    // TTD-3068 get doc url
    public async getDocUrlAsync(doc: EsgSocialDocUrlReq): Promise<EsgSocialDocUrlResp> {
        const result = await this.supplierBlockchain.getDocUrlAsync(doc);
        return result;
    }

    // TTD-3068 get social esg data on address
    public async getSocialEsgDataOnFacilityAsync(
        addressID: string
    ): Promise<EsgSocialRespModel> {
        const result =  await this.supplierBlockchain.getSocialEsgDataOnFacilityAsync(addressID);
        if(result.success){
        const socialData = store.getters.esgSocial as EsgSocialModel[];
            if(result.socialData.length > 0){
                const socialIndex = socialData.findIndex(s => s.ID === result.socialData[0].ID);
                if(socialIndex !== -1){
                socialData.splice(socialIndex, 1, result.socialData[0]);
                } else {
                socialData.push(result.socialData[0]);
                }
                store.commit(ActionTypes.SET_ESG_SOCIAL_DATA, socialData);
            }
        }
        return result;
    }

    // TTD-4759 get env data by company id
    public async getEnvDataByCompanyIdAsync(companyId: string): Promise<EsgEnvRespModel>{
        const result = await this.supplierBlockchain.getEnvDataByCompanyId(companyId);
        return result;
    }

    // TTD-4759 get env doc url
    public async getEnvDocUrlAsync(doc: EsgEnvDocUrlReq): Promise<EsgEnvDocUrlResp> {
        const result = await this.supplierBlockchain.getEnvDocUrlAsync(doc);
        return result;
    }

    // TTD-4759 get env esg data on address
    public async getEnvEsgDataOnFacilityAsync(
        addressID: string
    ): Promise<EsgEnvRespModel> {
        const result =  await this.supplierBlockchain.getEnvEsgDataOnFacilityAsync(addressID);
        if(result.success){
        const envData = store.getters.esgEnv as EsgEnvModel[];
            if(result.envData.length > 0){
                const socialIndex = envData.findIndex(s => s.ID === result.envData[0].ID);
                if(socialIndex !== -1){
                    envData.splice(socialIndex, 1, result.envData[0]);
                } else {
                    envData.push(result.envData[0]);
                }
                store.commit(ActionTypes.SET_ESG_ENV_DATA, envData);
            }
        }
        return result;
    }
}
