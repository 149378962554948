import { Vue, Component, Ref, Watch } from "vue-property-decorator";
import moment from "moment";
import { mapState } from 'vuex';
import { v4 as uuidv4 } from 'uuid';
import _ from 'underscore';
import lodash, { uniqBy } from 'lodash';
import ClickOutside from 'vue-click-outside';

// Services
import { OrderService } from "@/services/orderService";
import { SupplierService } from "@/services/supplierService";
import { ClientService } from "@/services/clientService";

// Components
import OrderLine from '@/views/orderLines/components/orderLine.vue';
import OrderLineLoader from '@/views/orderLines/components/orderLineLoader.vue';
import PendingOrderLinesGroup from '@/views/orderLines/components/pendingOrderLinesGroup.vue';
import EditOrder from "@/views/orderLines/components/editOrder.vue";
import i18n from "@/i18n";
import store from "@/store";

// Modals
import { ProductGroup } from "@/models/productGroup";
import { DropdownModel } from "@/models/dropdownModel";
import { OrderCompanyModel } from "@/models/orderCompanyModel";
import { OrderLineDisplayModel } from "@/models/orderLineDisplayModel";
import { OrderLineStepDisplayModel } from "@/models/orderLineStepDisplayModel";
import { SupplierOverviewModel } from '@/models/supplierOverviewModel';
import { UserModel } from "@/models/userModel";

import { OrderModel } from '@/models/orderModel';
import { ConfirmOrderCompanyModel } from '@/models/confirmOrderCompanyModel';
import { FromCompanyUserModel } from "@/models/fromCompanyUserModel";
import { ConfirmOrderLineModel } from "@/models/confirmOrderLineModel";
import { AgentCompanyUserModel } from "@/models/agentCompanyUserModel";
import { CombinedOrderLineModel, RemoveCombinedConfirmedOrders } from "@/models/combinedOrderLineModel";
import { ConfirmOrderModel } from "@/models/confirmOrderModel";
import { SocialEffortModal } from "@/models/socialEffortModal";
import { TransparencyEffortModal } from "@/models/transparencyEffortModal";
import { ActionTypes } from "@/store/actions/actions";
import { CompanyModel, CompanyNameModel } from "@/models/companyModel";
import { ContactModel } from "@/models/contactModel";
import { Guid } from "@/models/guid";
import { DeleteOrderRequestModel } from "@/models/deleteOrderRequestModel";
import { GroupStepEvidenceHelper } from "@/helpers/groupStepEvidenceHelper";
import { OrdersWithStepsModal } from "@/models/ordersWithStepsModel";
import { updatePhaseStatusRequestModel } from "@/models/publishUnpublishOrderModel";
import { TcMailRequestModel } from "@/models/transactionCertsModel";

@Component({
  components: { PendingOrderLinesGroup, OrderLine, OrderLineLoader, EditOrder },
  computed: mapState(['orderLoading', 'orders', 'suppliers', 'user', 'userRole', 'company', 'orderLineStepsLoading', 'companyType', 'companyNameList']),
  directives: {
    ClickOutside
  }
})
export default class OrderLines extends Vue {

  private orderService: OrderService;
  private supplierService: SupplierService;
  private clientService: ClientService;

	private suppliers!: SupplierOverviewModel[];

  private pendingOrders: OrderModel[] = [];

  private orders!: OrderLineDisplayModel[];

  private filteredOrders: OrderLineDisplayModel[] = [];

  private steps: OrderLineStepDisplayModel[] = [];

  private orderLoading!: boolean;

  private searchFilter: string = "";

  private selectedBrand: string = "";

  private selectedProductGroup: ProductGroup = ProductGroup.None;

  private selectedUsername: string = "";

  private selectedSeason: string = "";

  private selectedPartnerID: string = "";

  private dateFrom: string | Date = "";

  private dateTo: string | Date = "";

  private today: Date = new Date();

  private pendingOrderConfirm: boolean = false;

  private tabIndex: number = 0;

  private totalPending: number = 0;

  private tabCountPending: number = 0;

  private tabCountWaiting: number = 0;

  private tabCountInprogress: number = 0;

  private tabCountCompleted: number = 0;

  private selectedTabName: string = "";

  private showClearFilter: boolean = false;

  private totalQrCodeLive!: number;

  private totalStyles!: number;

  private socialEfforts!: SocialEffortModal[];

  private transparencyEfforts!: TransparencyEffortModal[];

  private user!: UserModel;

  private userRole!: string;

  private company!: CompanyModel;

  private orderConfirming: boolean = false;

  private orderLineStepsLoading!: boolean;

  private chunckOrders: OrderLineDisplayModel[] = [];

  private confirmedOrderIds: RemoveCombinedConfirmedOrders[] = [];

  private companyType!: number;

  private scrollPosition: number = 0;

  private showAllUsers: boolean = true;

  private showAllSeasons: boolean = true;

  private showAllPartners: boolean = true;

  private showAllProducts: boolean = true;

  private showFilters: boolean = false;

  private selectedFilters: number = 0;

  private isAllUsersSelected: boolean = false;

  private isAllSeasonsSelected: boolean = false;

  private isAllPartnerSelected: boolean = false;

  private isAllProductsSelected: boolean = false;

  private isDeleted: boolean = false;

  private orderLines: OrderLineDisplayModel[] = [];

  private pageSize:number = 50;

  private currentPage: number = 0;

  private selectedOrders: OrderLineDisplayModel[] = [];

  private latestOrders: OrderLineDisplayModel[] = [];

  private isAllOrdersSelected: boolean = false;

  private isDeleting: boolean = false;

  private isOpen: boolean = false;

  private selectedSortOption: string = '';

  private isSortAtoZ: boolean = false;

  private isSortZtoA: boolean = false;

  private isDeletingBulk: boolean = false;

  private isShowLoadMore: boolean = true;

  private sortType: string = 'asc';

  private showEditOrderModal: boolean = false;

  private isPageReload: boolean = false;

  private isOrdersConfirmed: boolean = false;

  // TTD-3961
  private companyNameList!: CompanyNameModel[];

  @Ref('ordersContainer') ordersContainer! : HTMLDivElement;

  public constructor() {
    super();
    this.orderService = new OrderService();
    this.supplierService = new SupplierService();
    this.clientService = new ClientService();
  }

  private async created(): Promise<void> {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    if (this.$route.params.selectTab=='waiting') {
      this.selectATab(1);
    } else {
      this.selectATab(0);
    }
    if (this.suppliers == null || this.suppliers.length === 0) {
      await this.supplierService.getSupplierOverviewAsync(true);
    }
    //TTD-3795 issue 4
    window.addEventListener('beforeunload', this.pageReload);
  }
  //TTD-3795 issue 4
  private beforeDestroy(): void {
    window.removeEventListener('beforeunload', this.pageReload);
  }

  private loadMore(value:string) {
    this.orderLinesData(value);
    this.sortedList(this.sortType,this.selectedSortOption);
  }

  private async mounted(): Promise<void> {
    if (window.innerWidth <= 1140) {
      this.showAllUsers = false;
      this.showAllSeasons = false;
    } else if (window.innerWidth < 1280 && window.innerWidth > 1140) {
      this.showAllUsers = true;
      this.showAllSeasons = false;
    } else {
      this.showAllUsers = true;
      this.showAllSeasons = true;
    }
    window.onresize = () => {
      if (window.innerWidth <= 1140) {
        this.showAllUsers = false;
        this.showAllSeasons = false;
        this.showAllProducts = false;
      } else if (window.innerWidth <= 1280 && window.innerWidth > 1140) {
        this.showAllUsers = false;
        this.showAllSeasons = false;
        this.showAllPartners = false;
        this.showFilters = true;
      }else{
        this.showAllUsers = true;
        this.showAllSeasons = true;
        this.showAllPartners = true;
        this.showAllProducts = true;
      }
    };
  }

  private clickedOutsideFilters(): void {
    this.showFilters = false;
  }

  private onScroll(event: any): void {
    const element = this.ordersContainer;
    this.scrollPosition = event.currentTarget.scrollTop;
    const dropdownMenu = document.querySelector('.dropdown-menu.dropdown-menu-right.show');
    if (dropdownMenu) {
      element.click();
    }
  }

  private filtersClicked(): void {
    if (this.showFilters) {
      this.showFilters = false;
    } else {
      this.showFilters = true;
      setTimeout(()=>{
        this.displayFiltersList();
      },0);
    }
  }

  private selectATab(index: number): void {
      this.tabIndex = index;
    switch(index){
      case 1:
        this.selectedTabName = "waiting";
        this.showAllUsers = false;
        this.showAllSeasons = false;
        break;
      case 2:
        this.selectedTabName = "inprogress";
        break;
      case 3:
        this.selectedTabName = "completed";
        break;
      default:
        this.selectedTabName = "pending";
        this.showAllUsers = true;
        this.showAllSeasons = true;
        this.showFilters = false;
        break;
    }
    this.scrollPosition = 0;
    this.scrollPosition = 0;
    this.getOrderLinesAsync();
    this.resetLoadMoreOrders();
  }

  private async getOrderLinesAsync(): Promise<void> {

    if(this.orderLoading === false && this.orders.length === 0){
      await store.dispatch(ActionTypes.SET_ORDER_DETAIL);
    }

    this.tabCountPending = this.orders.filter(o => (o.orderLinePhase === 0 && o.orderLineStatus === 0)).length;
    this.totalPending = this.tabCountPending;

    this.tabCountWaiting = this.orders.filter(o => (o.orderLinePhase === 1 && o.orderLineStatus === 0)).length;
    this.tabCountInprogress = this.orders.filter(o => (o.orderLinePhase === 1 && (o.orderLineStatus >= 1 && o.orderLineStatus <= 6))).length;
    this.tabCountCompleted = this.orders.filter(o => (o.orderLinePhase === 2 && o.orderLineStatus !== 7)).length;

    switch (this.selectedTabName){
      case 'waiting':
        this.filteredOrders = this.orders.filter(o => (o.orderLinePhase === 1 && o.orderLineStatus === 0));
        break;
      case 'inprogress':
        this.filteredOrders = this.orders.filter(o => (o.orderLinePhase === 1 && (o.orderLineStatus >= 1 && o.orderLineStatus <= 6)));
        break;
      case 'completed':
        this.filteredOrders = this.orders.filter(o => (o.orderLinePhase === 2 && o.orderLineStatus !== 7));
        break;
      default: // pending
        this.filteredOrders = this.orders.filter(o => (o.orderLinePhase === 0 && o.orderLineStatus === 0));
        break;
    }
  }

  private getSupplierDropdownText(supplierModel: OrderCompanyModel): string {
    if (supplierModel.isCompliant) {
      return `${supplierModel.companyName} (${supplierModel.country})`;
    }

    return `${supplierModel.companyName} (${this.$t('pages.home.pending_invite')})`;
  }

  private get openDate(): Date {
    return this.today;
  }

  private get showNoFilterResults(): boolean {
    return !this.orderLoading && this.orders.length > 0 && this.orders.every(o => !o.show);
  }

  private get locale(): string {
    if (i18n.availableLocales.some(l => l === i18n.locale)) {
      return i18n.locale;
    }
    return i18n.fallbackLocale as string;
  }

  private get dateFromFilter(): moment.Moment {
    return moment(this.dateFrom).startOf('day');
  }

  private get dateToFilter(): moment.Moment {
    return moment(this.dateTo).endOf('day');
  }

  private get supplyChainPartner(): DropdownModel[] {
    let options: DropdownModel[] = [];
    // options = this.orders.filter(o => (o.orderLinePhase !== 0 && o.orderLineStatus !== 0) || ( o.orderLinePhase !== 0 && o.orderLineStatus == 0)).map(o => new DropdownModel(o.toCompanyId, this.companyNameList.filter(c => c.ID === o.toCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === o.toCompanyId)[0].name : o.toCompanyName));
    options = this.orders.filter(o => (o.orderLinePhase !== 0 && o.orderLineStatus !== 0) || ( o.orderLinePhase !== 0 && o.orderLineStatus == 0)).map(o => new DropdownModel(o.toCompanyId, o.toCompanyName));
    options =  lodash.uniqBy(options, 'value');
    options.splice(0, 0, { value: "", text: "All partners" });
    return options;
  }

  private get brands(): DropdownModel[] {
    const options: DropdownModel[] = [{ value: "", text: this.$t("pages.order_lines.all_brands").toString() }];
    let brands: string[] = [];
    if (this.user && this.user.topBrands && this.user.topBrands.length > 0) {
      brands = _.pluck(this.user.topBrands, 'brandName');
    }
    else{
      if(this.userRole === "Admin" && this.user && this.user.userType === "COMPANY_ADMIN" && this.company !== null){
        brands = _.pluck(this.company.topBrands, 'brandName');
      }
    }
    options.push(...brands.map(brand => new DropdownModel(brand,brand)));

    return options;
  }

  private get productGroups(): DropdownModel[] {
    const options: DropdownModel[] = [{ value: 0, text: this.$t("pages.order_lines.all_productgroups").toString() }];
    this.orders.forEach(line => {
      if (options.findIndex(option => option.value === line.productGroup) === -1) {
        const productGroupName = this.$t(`enums.product_group.${line.productGroup}`).toString().split(/(?=[A-Z])/).join(" ");
        options.push(new DropdownModel(line.productGroup, productGroupName.charAt(0).toUpperCase() + productGroupName.slice(1).toLowerCase()));
      }
    });

    return options;
  }

  private get username(): DropdownModel[] {
    const options: DropdownModel[] = [{ value: "", text: this.$t("pages.order_lines.all_usernames").toString() }];
    const distinctUsers : any[]= [];
    this.orders.filter((item) => {
      const i = distinctUsers.findIndex(x => (x.fromCompanyUser.userId == item.fromCompanyUser.userId));
      if(i <= -1){
        distinctUsers.push(item);
      }
      return null;
    });
    distinctUsers.forEach(line => {
        let fullname = line.fromCompanyUser.firstName + ' ' + line.fromCompanyUser.lastName;
        if (fullname.length>20) {
          fullname = fullname.slice(0, 14).concat('...');
        }
        options.push(new DropdownModel(line.fromCompanyUser.userId, fullname));
    });
    return options;
  }

  private get seasons(): DropdownModel[] {
    const options: DropdownModel[] = [{ value: "", text: this.$t("pages.order_lines.all_seasons").toString() }];
    const distinctSeasons : any[]= [];
    this.orders.filter((item) => {
      const i = distinctSeasons.findIndex(x => (x.orderLines[0].season == item.orderLines[0].season));
      if(i <= -1 && item.orderLines[0].season!==null && item.orderLines[0].season!==""){
        distinctSeasons.push(item);
      }
      return null;
    });
    distinctSeasons.forEach(line => {
      if (line.orderLines[0].season !== null) {
        options.push(new DropdownModel(line.orderLines[0].season, line.orderLines[0].season));
      }
    });

    return options;
  }

  private reloadOrderLines(): void {
    this.getOrderLinesAsync();
    this.orderLinesData('');
  }

  private orderLinesData(val:string): void {
    this.showClearFilter = false;
    this.orders.forEach(o => {
      o.show = true
      // o.fromCompanyName = this.companyNameList.filter(c => c.ID === o.fromCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === o.fromCompanyId)[0].name : o.fromCompanyName;
      // o.fromCompanyUser.name = this.companyNameList.filter(c => c.ID === o.fromCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === o.fromCompanyId)[0].name : o.fromCompanyUser.name;
      // o.toCompanyName = this.companyNameList.filter(c => c.ID === o.toCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === o.toCompanyId)[0].name : o.toCompanyName;
      // o.agentCompanyUser.name = this.companyNameList.filter(c => c.ID === o.agentCompanyID).length > 0 ? this.companyNameList.filter(c => c.ID === o.agentCompanyID)[0].name : o.agentCompanyUser.name;
      // o.delegateCompanyUser.companyName = this.companyNameList.filter(c => c.ID === o.delegateCompanyID).length > 0 ? this.companyNameList.filter(c => c.ID === o.delegateCompanyID)[0].name : o.delegateCompanyUser.companyName;
    });
    //TTD-3795 issue-6
    this.tabCountPending = this.orders.filter(o => (o.orderLinePhase === 0 && o.orderLineStatus === 0)).length;
    this.tabCountWaiting = this.orders.filter(o => (o.orderLinePhase === 1 && o.orderLineStatus === 0)).length;
    this.tabCountInprogress = this.orders.filter(o => (o.orderLinePhase === 1 && (o.orderLineStatus >= 1 && o.orderLineStatus <= 6))).length;
    this.tabCountCompleted = this.orders.filter(o => (o.orderLinePhase === 2 && o.orderLineStatus !== 7)).length;
    let orders: OrderLineDisplayModel[] = [];
    switch (this.selectedTabName){
      case 'waiting':
        orders = this.orders.filter(o => (o.orderLinePhase === 1 && o.orderLineStatus === 0));
	// this.tabCountWaiting = orders.length;
        break;
      case 'inprogress':
        orders = this.orders.filter(o => (o.orderLinePhase === 1 && (o.orderLineStatus >= 1 && o.orderLineStatus <= 6)));
	// this.tabCountInprogress = orders.length;
        break;
      case 'completed':
        orders = this.orders.filter(o => (o.orderLinePhase === 2 && o.orderLineStatus !== 7));
	// this.tabCountCompleted = orders.length;
        break;
      default: // pending
        orders = this.orders.filter(o => (o.orderLinePhase === 0 && o.orderLineStatus === 0));
	// this.tabCountPending = orders.length;
        break;
    }
    if (this.searchFilter !== "") {
      this.showClearFilter = true;
      const lowerCaseFilter = this.searchFilter.toLowerCase();
      orders = orders.filter(o => o.orderNumber.toLowerCase().includes(lowerCaseFilter)
        || (o.styleName != null && o.styleName.toLowerCase().includes(lowerCaseFilter))
        || (o.styleNumber != null && o.styleNumber.toLowerCase().includes(lowerCaseFilter))
        || (o.styleNumber != null && o.styleNumber.toLowerCase().includes(lowerCaseFilter))
        || (o.brandName != null && o.brandName.toLowerCase().includes(lowerCaseFilter))
        || (o.toCompanyName != null && o.toCompanyName.toLowerCase().includes(lowerCaseFilter)));
    }
    if (this.selectedBrand !== "") {
      this.showClearFilter = true;
      orders = orders.filter(o => o.brandName === this.selectedBrand);
    }
    if (this.selectedProductGroup !== ProductGroup.None) {
      this.showClearFilter = true;
      orders = orders.filter(o => o.productGroup === this.selectedProductGroup);
    }
    if (this.selectedUsername !== "") {
      this.showClearFilter = true;
      orders = orders.filter(o => o.fromCompanyUser.userId === this.selectedUsername);
    }
    if (this.selectedSeason !== "") {
      this.showClearFilter = true;
      orders = orders.filter(o => o.orderLines[0].season === this.selectedSeason);
    }
    if (this.selectedPartnerID !== "") {
      this.showClearFilter = true;
      orders = orders.filter(o => o.toCompanyId === this.selectedPartnerID);
    }
    if (this.dateFrom !== "") {
      this.showClearFilter = true;
      orders = orders.filter(o => moment(o.expectedTimeOfDelivery).isSameOrAfter(this.dateFromFilter));
    }
    if (this.dateTo !== "") {
      this.showClearFilter = true;
      orders = orders.filter(o => moment(o.expectedTimeOfDelivery).isSameOrBefore(this.dateToFilter));
    }

    const finalOrders: OrderLineDisplayModel[] = [];
    orders.forEach(o => {
      // o.fromCompanyName = this.companyNameList.filter(c => c.ID === o.fromCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === o.fromCompanyId)[0].name : o.fromCompanyName;
      // o.fromCompanyUser.name = this.companyNameList.filter(c => c.ID === o.fromCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === o.fromCompanyId)[0].name : o.fromCompanyUser.name;
      // o.toCompanyName = this.companyNameList.filter(c => c.ID === o.toCompanyId).length > 0 ? this.companyNameList.filter(c => c.ID === o.toCompanyId)[0].name : o.toCompanyName;
      // o.agentCompanyUser.name = this.companyNameList.filter(c => c.ID === o.agentCompanyID).length > 0 ? this.companyNameList.filter(c => c.ID === o.agentCompanyID)[0].name : o.agentCompanyUser.name;
      // o.delegateCompanyUser.companyName = this.companyNameList.filter(c => c.ID === o.delegateCompanyID).length > 0 ? this.companyNameList.filter(c => c.ID === o.delegateCompanyID)[0].name : o.delegateCompanyUser.companyName;
      finalOrders.push(o);
    });

    this.latestOrders = [];
    this.latestOrders = finalOrders.filter(o => o.orderLineStatus !== 7);
    switch (this.selectedTabName){
      case 'waiting':
	this.tabCountWaiting = this.latestOrders.length;
        break;
      case 'inprogress':
	this.tabCountInprogress = this.latestOrders.length;
        break;
      case 'completed':
	this.tabCountCompleted = this.latestOrders.length;
        break;
      default: // pending
	this.tabCountPending = this.latestOrders.length;
        break;
    }
    if(this.selectedTabName !=='pending'){
      this.latestOrders = this.latestOrders.sort((a,b) => this.compareCreatedDateStrings(a.createdAt, b.createdAt));
    }
    if(this.selectedTabName ==='pending'){
      this.orderLines = this.latestOrders;
    }else if(val !== 'loadMore' && this.selectedTabName !=='pending'){
      this.orderLines =  this.latestOrders.slice(0, this.pageSize);
    }else{
      const nextPageStart = (this.currentPage + 1) * this.pageSize;
      const nextPageEnd = nextPageStart + this.pageSize;
      this.orderLines = this.latestOrders.slice(0, nextPageEnd);
      this.currentPage++;
    }
    if(this.tabCountCompleted === this.orderLines.length || this.tabCountInprogress === this.orderLines.length ||
      this.tabCountWaiting === this.orderLines.length || this.orderLines.length < this.pageSize || (this.showClearFilter && this.orderLines.length === this.latestOrders.length)){
      this.isShowLoadMore = false;
    }else{
      this.isShowLoadMore = true;
    }
    if(this.showClearFilter){
      this.isOpen = false;
      this.selectedSortOption = '';
      this.isSortAtoZ = false;
      this.isSortZtoA = false;
      this.isAllOrdersSelected = false;
      this.orders.forEach((order) => {order.isOrderSeleted = false;});
      this.selectedOrders = [];
    }
  }
  private compareCreatedDateStrings(dateString1:string, dateString2: string): number {
    const date1 = moment(dateString1);
    const date2 = moment(dateString2);
    return date1.isAfter(date2) ? -1 : 1;
  }

  private async deleteOrdersHandler(orderId: string[]): Promise<void> {
    this.resetFilters();
    this.isDeleted = false;
    const data: DeleteOrderRequestModel = {orderList:[]};
    orderId.forEach(o => {
      data.orderList.push({'ID': o});
    });
    const result = await this.orderService.deleteOrderAsync(data);
    if (result.success) {
      this.isDeleted = true;
      this.isDeletingBulk = false;
      this.isAllOrdersSelected = false;
      if(this.selectedTabName === 'pending'){
        await store.dispatch(ActionTypes.REMOVE_PENDING_ORDERS, orderId);
      }
      else{
        store.commit('setDeletedOrderIds', orderId);
        await store.dispatch(ActionTypes.REMOVE_NON_PENDING_ORDRES, orderId);
        this.selectedOrders = [];
      }
      this.getOrderLinesAsync();
    }
  }

  private async clearAllFilters(): Promise<void> {

    this.resetFilters();

    await this.getOrderLinesAsync();
    this.orderLinesData('');
    this.isAllOrdersSelected = false;
    this.orders.forEach((order) => {order.isOrderSeleted = false;});
    this.selectedOrders = [];
  }

  private async ordersConfirmedHandler(bulkOrder: CombinedOrderLineModel[], confirmOrderLineStep: OrderLineStepDisplayModel[], isBulk: boolean,
    agentDetails:OrderCompanyModel): Promise<void> {

    const addedByAgentFlag = this.$store.getters.getAgentStepsUpdated as boolean;
    this.orderConfirming = true;
    this.isOrdersConfirmed = false;
    this.confirmedOrderIds = [];
    const confirmOrders: ConfirmOrderModel[] = [];
    const uuid = uuidv4();

    bulkOrder.forEach(order => {
      for (const id of order.orderIds) {
        const confirmOrder: ConfirmOrderModel = new ConfirmOrderModel();
        const confirm = this.orders.filter(order => order.orderId === id)[0];
        confirmOrder.createdAt = confirm.createdAt; // removed moment this due to point highlighted in TTD-4738 point-3
        confirmOrder.createdBy = this.user.userId;
        confirmOrder.updatedAt = moment(confirmOrder.updatedAt).toISOString();
        confirmOrder.fromCompanyID = confirm.fromCompanyUser.ID;
        const fromCompanyContact: ContactModel = {
          firstName: confirm.fromCompanyUser.firstName,
          lastName: confirm.fromCompanyUser.lastName,
          name: confirm.fromCompanyUser.name,
          email: confirm.fromCompanyUser.email,
          phone: "",
          isAdmin: false,
        };
        const fromCompanyUser = new FromCompanyUserModel(confirm.fromCompanyUser.ID, fromCompanyContact, confirm.fromCompanyUser.userId);
        confirmOrder.fromCompanyUser = fromCompanyUser;
        const agentCompanyUser = new AgentCompanyUserModel(agentDetails.id == '' ? Guid.Empty: agentDetails.id, Guid.Empty);
        if(agentDetails.id !=='' && agentDetails.id !== Guid.Empty){
          agentCompanyUser.ID = agentDetails.id;
          agentCompanyUser.email = agentDetails.companyEmail;
          agentCompanyUser.firstName = agentDetails.firstName;
          agentCompanyUser.lastName = agentDetails.lastName;
          agentCompanyUser.name = agentDetails.companyName;
        }
        if(order.orderDetails.length > 0){
          if(order.orderDetails[0].agentCompanyID && order.orderDetails[0].agentCompanyID !== Guid.Empty){
            confirmOrder.agentCompanyID = this.companyType === 5 ? order.orderDetails[0].agentCompanyID : order.orderDetails[0].agentCompanyUser.ID;
            confirmOrder.agentCompanyID = confirmOrder.agentCompanyID === '' ? Guid.Empty : confirmOrder.agentCompanyID;
            agentCompanyUser.ID = order.orderDetails[0].agentCompanyUser.ID
            agentCompanyUser.email = order.orderDetails[0].agentCompanyUser.email
            agentCompanyUser.firstName = order.orderDetails[0].agentCompanyUser.firstName;
            agentCompanyUser.lastName = order.orderDetails[0].agentCompanyUser.lastName;
            agentCompanyUser.name = order.orderDetails[0].agentCompanyUser.name;
          }
        }
        confirmOrder.agentCompanyUser = agentCompanyUser;
        if (isBulk) {
          confirmOrder.toCompanyID = confirm.toCompanyId === Guid.Empty ? order.supplierId : confirm.toCompanyId;
          confirm.toCompanyName = confirm.toCompanyName === "" ? order.supplierName : confirm.toCompanyName;
          const toCompanyCountry = this.suppliers.filter(s => s.id === confirmOrder.toCompanyID)[0] !== undefined ? this.suppliers.filter(s => s.id === confirmOrder.toCompanyID)[0].country : '';
          confirmOrder.toCompany = new ConfirmOrderCompanyModel(confirmOrder.toCompanyID, toCompanyCountry, confirm.toCompanyName);
        }
        else {
          confirmOrder.toCompanyID = confirm.toCompanyId === Guid.Empty ? order.supplierId : confirm.toCompanyId;
          const supplier1 = new ConfirmOrderCompanyModel(order.supplierId, order.supplierCountry, order.supplierName);
          confirmOrder.toCompany = supplier1;
        }
        const orderLines: ConfirmOrderLineModel[] = [];
        confirm.orderLines.forEach(line => {
          const orderLine: ConfirmOrderLineModel = new ConfirmOrderLineModel();
          orderLine.brandName = line.brandName;
          orderLine.certificate = line.certificate;
          orderLine.colourway = line.colourway === undefined ? '' : line.colourway;
          orderLine.compositionMainFabric = line.compositionMainFabric;
          orderLine.compositionSecondFabric = line.compositionSecondFabric;
          orderLine.etd = line.etd;
          orderLine.lineID = line.lineID;
          if(agentDetails.id !=="" && agentDetails.id !== Guid.Empty){
            orderLine.orderLinePhase = 0;
          }else{
            orderLine.orderLinePhase = 1;
          }
          orderLine.orderLineStatus = 0;
          orderLine.productGroup = line.productGroup;
          orderLine.productPictureUrls = line.productPictureUrls;
          orderLine.qrCodeRef = '';
          orderLine.showNewsLetter = false;
          orderLine.styleName = line.styleName;
          orderLine.styleNumber = line.styleNumber;
          orderLine.totalQuantityPerCw = line.totalQuantityPerCw;
          orderLine.season = line.season;
          orderLine.repeat = line.repeat;

          orderLines.push(orderLine);
        });
        confirmOrder.orderLines = orderLines;
        confirmOrder.ID = confirm.orderId;
        confirmOrder.orderNumber = confirm.orderNumber;
        confirmOrder.orderRefID = addedByAgentFlag ? uuid : confirm.orderRefId;
        // Sprint 24, TTD-4471
        confirmOrder.tranCertificate1 = confirm.tranCertificate1;
        confirmOrder.tranCertificate2 = confirm.tranCertificate2;
        confirmOrder.tc1Status = confirm.tc1Status;
        confirmOrder.tc2Status = confirm.tc2Status;
        confirmOrder.license = confirm.license;
        confirmOrder.levelTC1 = confirm.levelTC1;
        confirmOrder.levelTC2 = confirm.levelTC2;
        confirmOrder.tranCertificate1RefId = confirm.tranCertificate1RefId;
        confirmOrder.tranCertificate2RefId = confirm.tranCertificate2RefId;
        confirmOrder.customer = confirm.customer;
        confirmOrder.deliveryTerm = confirm.deliveryTerm;
        if (confirmOrder.orderRefID === "") {
          confirmOrder.orderRefID = uuid;
        }
        if(agentDetails.id !=='' && agentDetails.id !== Guid.Empty){
          confirmOrder.agentCompanyID = agentDetails.id;
        }
        confirmOrder.agentCompanyID = confirmOrder.agentCompanyID === '' ? Guid.Empty : confirmOrder.agentCompanyID;
        confirmOrders.push(confirmOrder);
      }
    });

    if(this.user.companyType === 5){
      const orderList: updatePhaseStatusRequestModel = {
        orderIdList: confirmOrders.map(o => o.ID)
      }
      const orderResponse = await this.supplierService.updateOrderlinePhaseStatus(orderList, 1, 0);
      if(orderResponse.success && orderResponse.orders !== undefined && orderResponse.orders.length > 0){
        const orders = this.orders;
        orderResponse.orders.forEach(orderline => {
          const index = orders.findIndex(o => o.orderId === orderline.orderId);
          const order: OrderLineDisplayModel = orderline;
          order.isOrderSeleted = false;
          orders.splice(index, 1, order); // adding updated response on same index
        });
        store.commit("setAllOrders", orders);
        bulkOrder.forEach(order => {
          this.confirmedOrderIds.push(new RemoveCombinedConfirmedOrders(order.groupId, order.orderIds));
        });
        bulkOrder[0].isOrdersConfirmed = false;
      }
    } else {
      const orderResponse = await this.orderService.confirmOrderAsync(confirmOrders);
      if(orderResponse && orderResponse.orders.length > 0){
        const orders = this.orders;
        const supplierIDs: string[] = uniqBy(orderResponse.orders, 'toCompanyId').map(o => o.toCompanyId);
        supplierIDs.forEach(supplierID => {
          const tcOrders: OrderLineDisplayModel[] = [];
          const tcType: string[] = [];
          let orderNumbers: string[] = [];
          const responseFilteredOrders = orderResponse.orders.filter(o => o.toCompanyId === supplierID);
          responseFilteredOrders.forEach(orderline => {
            const index = orders.findIndex(o => o.orderId === orderline.orderId);
            const order: OrderLineDisplayModel = orderline;
            order.isOrderSeleted = false;
            orders.splice(index, 1, order); // adding updated response on same index
            if(order.tranCertificate1 !== undefined && order.tranCertificate1 !== null && order.tranCertificate1 !== ''){
              tcType.push(order.tranCertificate1);
              tcOrders.push(order);
            }
            if(order.tranCertificate2 !== undefined && order.tranCertificate2 !== null && order.tranCertificate2 !== ''){
              tcType.push(order.tranCertificate2);
              tcOrders.push(order);
            }
          });
          orderNumbers = uniqBy(tcOrders, "orderId").map(o => o.orderNumber);
            if(tcType.length > 0){
              const mailData: TcMailRequestModel = {
                clientCompanyName: this.user.companyName,
                clientUserFirstName: this.user.firstName,
                clientUserLastName: this.user.lastName,
                orderNumbers: orderNumbers.join(', '),
                tcType: lodash.uniq(tcType).join(', '),
                supplierIDs: [supplierID]
              }
              this.clientService.sendTCRequestMail(mailData);
            }
        });
        this.isOrdersConfirmed = true;
        store.commit("setAllOrders", orders);
        bulkOrder.forEach(order => {
          this.confirmedOrderIds.push(new RemoveCombinedConfirmedOrders(order.groupId, order.orderIds));
        });
        bulkOrder[0].isOrdersConfirmed = false;
      }
    }
    if (confirmOrderLineStep) {
      const stepIndex = confirmOrderLineStep.findIndex(step => step.toCompanyId !== Guid.Empty);
      let parallelstep = false;
      if(stepIndex === -1){
        confirmOrderLineStep.forEach(step => {
          if(!parallelstep){
            if((step.parallelSupplyChain0.findIndex(s => s.toCompanyId !== Guid.Empty) !== -1)
            || (step.parallelSupplyChain1.findIndex(s => s.toCompanyId !== Guid.Empty) !== -1)
            || (step.parallelSupplyChain2.findIndex(s => s.toCompanyId !== Guid.Empty) !== -1)
            || (step.parallelSupplyChain3.findIndex(s => s.toCompanyId !== Guid.Empty) !== -1)
            || (step.parallelSupplyChain4.findIndex(s => s.toCompanyId !== Guid.Empty) !== -1)){
              parallelstep = true;
            }
          }
        })
      }
      if(stepIndex !== -1 || parallelstep){
        const steps = GroupStepEvidenceHelper.updateApproveStepEvidece(confirmOrderLineStep);
        const stepsResponse = await this.orderService.createSupplyChainStepsAsync(steps);
        if(stepsResponse.success){
          if (this.companyType === 5) {
            const steps: OrderLineStepDisplayModel[] = this.$store.getters.orderLineSteps;
            stepsResponse.orderLineSteps.forEach(s => {
              const index = steps.findIndex(o => o.id === s.id);
              const step: OrderLineStepDisplayModel = s;
              steps.splice(index, 1); // removing updated response on same index
            });
          }
          store.commit("setSteps", stepsResponse.orderLineSteps);
        }
      }
    }
    this.getOrderLinesAsync();
    this.orderConfirming = false;
  }

  private getSelectedFiltersCount(type:string, value:string|number){
    const element = this.ordersContainer;
    if(type=='user' && value!==''){
      this.isAllUsersSelected = false;
      this.selectedFilters += 1;
    } else if(!this.isAllUsersSelected && type=='user' && value=='') {
      this.isAllUsersSelected = true;
      this.selectedFilters > 0 ? this.selectedFilters = --this.selectedFilters : '';
    }
    if(type=='season' && value!==''){
      this.selectedFilters += 1;
      this.isAllSeasonsSelected = false;
    } else if(!this.isAllSeasonsSelected && type=='season' && value=='') {
      this.isAllSeasonsSelected = true;
      this.selectedFilters > 0 ? this.selectedFilters = --this.selectedFilters : '';
    }
    if(type=='partner' && value!==''){
      this.selectedFilters += 1;
      this.isAllPartnerSelected = false;
    } else if(!this.isAllPartnerSelected && type=='partner' && value=='') {
      this.isAllPartnerSelected = true;
      this.selectedFilters > 0 ? this.selectedFilters = --this.selectedFilters : '';
    }
    if(type=='product' && value!==0){
      this.selectedFilters += 1;
      this.isAllProductsSelected = false;
    } else if(!this.isAllProductsSelected && type=='product' && value=='') {
      this.isAllProductsSelected = true;
      this.selectedFilters > 0 ? this.selectedFilters = --this.selectedFilters : '';
    }
    this.orderLinesData('');
    element.click();
  }

  private displayFiltersList(){
    const dropDown = document.querySelector('.dropdown-menu') as HTMLInputElement;
    if(dropDown !=null){
      dropDown.style.display = 'block';
    }
  }

  private selectOrder(orderLine: OrderLineDisplayModel){
      if(!orderLine.isOrderSeleted){
        const oIndex = this.orderLines.findIndex(el => el.orderId === orderLine.orderId);
        this.orderLines[oIndex].isOrderSeleted = true;
        this.selectedOrders.push(orderLine);
        if(this.orderLines.every((val) => val.isOrderSeleted === true)){
          this.isAllOrdersSelected = true;
        }else{
          this.isAllOrdersSelected = false;
        }
      }else{
        this.isAllOrdersSelected = false;
        orderLine.isOrderSeleted = false;
        const index = this.selectedOrders.findIndex(el => el.orderId === orderLine.orderId);
        this.selectedOrders.splice(index, 1);
      }
  }

  private selectAllOrders(val:string){
    if((!this.isAllOrdersSelected || val==='selectAll') && val !=="deSelectAll"){
      this.isAllOrdersSelected = true;
      this.orderLines.forEach(order => {
        order.isOrderSeleted = true;
    });
      this.selectedOrders = [...this.orderLines];
    }else{
      this.isAllOrdersSelected = false;
      this.orderLines.forEach(order => {
        order.isOrderSeleted = false;
    });
      this.selectedOrders = [];
    }
  }

  private deleteBulkOrders(){
    const confirmationText = this.$t('pages.home.delete_confirmation').toString();
    this.$confirm(confirmationText, undefined, undefined, {allowOutsideClick: false}).then(() => {
      this.isDeletingBulk = true;
      const orderIdList:string[] = [];
      this.selectedOrders.forEach(o => {
        orderIdList.push(o.orderId);
      });
      this.deleteOrdersHandler(orderIdList);
  }).catch(onrejected => {
      return "";
  });
  }

  private sortedList(sort:string, val: string) {
    if(val!==''){
        this.selectedSortOption = val;
        this.sortType = sort;
        if(this.selectedSortOption === 'date'){
          this.sortByDate();
        }else if(this.selectedSortOption === 'colourWay'){
          if(sort === 'asc'){
            this.isSortAtoZ = true;
            this.isSortZtoA = false;
            this.latestOrders = this.latestOrders.slice().sort(this.sortByColour);
          }else{
            this.isSortZtoA = true;
            this.isSortAtoZ = false;
            this.latestOrders = this.latestOrders.slice().sort((a, b) => this.sortByColour(b, a));
          }
      }else{
            this.latestOrders = this.latestOrders.slice().sort((a, b) => {
            if(this.selectedSortOption){
                if(sort === 'asc'){
                  this.isSortAtoZ = true;
                  this.isSortZtoA = false;
                  if(a[this.selectedSortOption] === null || a[this.selectedSortOption].trim() === "" || a[this.selectedSortOption] === undefined) return -1;
                  if(b[this.selectedSortOption] === null || b[this.selectedSortOption].trim() === "" || b[this.selectedSortOption] === undefined) return 1;
                  if (!isNaN(+a[this.selectedSortOption].trim()) && !isNaN(+b[this.selectedSortOption].trim())) {
                    return +a[this.selectedSortOption].trim() - +b[this.selectedSortOption].trim();
                  }
                  return a[this.selectedSortOption].trim().localeCompare(b[this.selectedSortOption].trim());
                }else{
                  this.isSortZtoA = true;
                  this.isSortAtoZ = false;
                  if(a[this.selectedSortOption] === null || a[this.selectedSortOption].trim() === "" || a[this.selectedSortOption] === undefined) return 1;
                  if(b[this.selectedSortOption] === null || b[this.selectedSortOption].trim() === "" || b[this.selectedSortOption] === undefined) return -1;
                  if (!isNaN(+a[this.selectedSortOption].trim()) && !isNaN(+b[this.selectedSortOption].trim())) {
                    return +b[this.selectedSortOption].trim() - +a[this.selectedSortOption].trim();
                  }
                  return b[this.selectedSortOption].trim().localeCompare(a[this.selectedSortOption].trim());
                }
            }
        });
      }
      this.orderLines =  this.latestOrders.slice(0, this.orderLines.length);
    }
  }

  private sortByColour(a: any, b: any) {
    const colorA = a.orderLines[0].colourway;
    const colorB = b.orderLines[0].colourway;
    if (colorA == null || colorA === "") return -1;
    if (colorB == null || colorB === "") return 1;
    if (colorA === undefined) return -1;
    if (colorB === undefined) return 1;
    if (typeof colorA === "number" && typeof colorB === "number") {
      return colorA - colorB;
    }
    return colorA.localeCompare(colorB);
  }

  private sortByDate() {
    if(this.sortType === 'asc'){
        this.isSortAtoZ = true;
        this.isSortZtoA = false;
        this.latestOrders = this.latestOrders.sort((a: OrderLineDisplayModel, b: OrderLineDisplayModel) => {
        if (moment(a.expectedTimeOfDelivery).isBefore(b.expectedTimeOfDelivery)) {
          return -1;
        } else if (moment(a.expectedTimeOfDelivery).isAfter(b.expectedTimeOfDelivery)) {
          return 1;
        }
        return 0;
      });
    }else{
        this.isSortZtoA = true;
        this.isSortAtoZ = false;
        this.latestOrders = this.latestOrders.sort((a: OrderLineDisplayModel, b: OrderLineDisplayModel) => {
        if (moment(b.expectedTimeOfDelivery).isBefore(a.expectedTimeOfDelivery)) {
          return -1;
        } else if (moment(b.expectedTimeOfDelivery).isAfter(a.expectedTimeOfDelivery)) {
          return 1;
        }
        return 0;
      });
    }
  }

  private toggleDropdown() {
    this.isOpen = !this.isOpen;
  }

  private editOrders(): void {
    this.showEditOrderModal = true;
  }

  private closeEditOrderHandler(edited: boolean): void {
    this.showEditOrderModal = false;
    if(edited){
      this.selectAllOrders('deSelectAll');
    }
  }

  private resetFilters(): void{
    this.searchFilter = "";
    this.selectedBrand = "";
    this.selectedProductGroup = ProductGroup.None;
    this.selectedUsername = "";
    this.selectedSeason = "";
    this.dateFrom = "";
    this.dateTo = "";
    this.selectedPartnerID = "";
    this.showClearFilter = false;
    this.selectedFilters = 0;
    this.isAllUsersSelected = false;
    this.isAllSeasonsSelected = false;
    this.isAllPartnerSelected = false;
    this.isAllProductsSelected = false;
  }

  private resetLoadMoreOrders(){
    this.orderLinesData('');
    this.selectedOrders = [];
    this.orderLines.forEach(order => {
      order.isOrderSeleted = false;
    });
    this.isAllOrdersSelected = false;
    this.isSortAtoZ = false;
    this.isSortZtoA = false;
    this.isOpen = false;
    this.pageSize = 50;
    this.currentPage = 0;
    this.selectedSortOption = '';
  }

  //TTD-3795 issue 4
  private pageReload(){
    this.isPageReload =  !this.isPageReload;
  }

  @Watch('orders')
    private callOrderLines(){
        this.orderLinesData('');
    }
  @Watch('showClearFilter')
  private defaultSortOrders(){
    if(this.showClearFilter){
      this.selectedSortOption = '';
    }
  }

}
